import SettingsIcon from "@material-ui/icons/Settings";
import AssessmentIcon from "@material-ui/icons/Assessment";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { ReactComponent as CreateTimeSheet } from "../icons/createTimesheet.svg";
import { ReactComponent as ViewTimeSheet } from "../icons/timeSheet.svg";
import {ReactComponent as Settings} from "../icons/settings.svg";
import {ReactComponent as Users} from "../icons/users.svg";
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import EventIcon from '@material-ui/icons/Event';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {ReactComponent as PayrollIcon} from '../icons/payroll-icon.svg'

const adminSidepannelOptions = [
 
  {
    icon: VisibilityIcon,
    text: "View Timesheets",
    link: `/timesheets`,
  },
  {
    icon: Users,
    text: "View Users",
    link: `/viewUsers`,

  },

  {
    icon: PersonAddIcon,
    text: "Create User",
    link: `/createuser`,

  },

  {
    icon: ViewTimeSheet,
    text: "Invoice Reports",
    link: `/invoicereports`,

  },
  {
    icon: PayrollIcon,
    text: "Payroll Reports",
    link: `/payrollreports`,
  },
  {
    icon: MailOutlineIcon,
    text: "Reminder",
    link: `/reminderemail`,
  },
  {
    icon: Settings,
    text: "Settings",
    link: `/settings`,
  },
  {
    icon: EventIcon,
    text: "Holidays",
    link: `/holidays`,
  },
];

const approverSidepannelOptions = [
  {
    icon: VisibilityIcon,
    text: "View Timesheets",
    link: `/timesheets`,
  },

  {
    icon: SettingsIcon,
    text: "Settings",
    link: `/settings`,
  }
];
const userSidepannelOptions = [
  {
    icon: VisibilityIcon,
    text: "View Timesheets",
    link: `/viewtimesheets`,
  },
  {
    icon: CreateTimeSheet,
    text: "Enter Timesheets",
    link: `/createtimesheet`,
  },
  {
    icon: SettingsIcon,
    text: "Settings",
    link: `/settings`,
  }
];


export { adminSidepannelOptions, approverSidepannelOptions, userSidepannelOptions }