import React,{useState} from "react";
//import "date-fns";
//import DateFnsUtils from "@date-io/date-fns";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
const theme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        background:
          "linear-gradient(to left, rgba(21,176,73,1) 0%, rgba(0,120,64,1) 100%);",
      },
    },
    MuiPickersDay: {
      daySelected: {
        background:
          "linear-gradient(to left, rgba(21,176,73,1) 0%, rgba(0,120,64,1) 100%);",
      },
      day: {
        "&:focus": {
          outline: "none"
        },
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        "&:focus": {
          outline: "none"
        },
      },
    },
    MuiIconButton: {
      root: {
        "&:focus": {
          outline: "none"
        },
        transition: {
          background:
            "linear-gradient(to left, rgba(21,176,73,1) 0%, rgba(0,120,64,1) 100%);",
        },
      },
    },
    MuiButton: {
      root: {
        "&:focus": {
          outline: "none",
        },
      },
    },
    MuiFormControl: {
      root: {
        minWidth: 263,
      },
      marginNormal: {
        marginTop: "0",
        marginBotom: "0",
      },
    },
    MuiInputBase: {
      input: {
        color: '#000000'
      }
    }
  },
});

export default function MaterialUIPickers(props) {

  const [isClicked,setIsClicked] = useState(false);

  return (
    <ThemeProvider theme={theme}>
      {/* <MuiPickersUtilsProvider utils={DateFnsUtils}> */}
      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
        <KeyboardDatePicker
          margin="normal"
          id="date-picker-dialog"
          open={isClicked}
          onClose={()=>setIsClicked(false)}
          onClick={() => setIsClicked(true)}
          label={props.label}
          disableFuture={props.disableFuture}
          format="MM-DD-YYYY"
          shouldDisableDate={props.shouldDisableDate}
          value={props.value}
          onChange={props.handleDateChange}
          maxDate={props.minDate}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          disablePast={props?.disablePast}
          minDate={props.minimumdate}
          autoComplete="off"
          InputProps={{ readOnly: true }}
          disabled={props.readDate} />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
}
