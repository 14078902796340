const globalValues = {

  FILE_API_BASE_URL_DEV:'http://devtmsapi.mindwavetech.com/home/devtms/devtimesheet_api/timesheetfiles/time_attachments/',
  FILE_API_BASE_URL_CASTUS:'http://testtmsapi.mindwavetech.com/home/testtms/api/timesheetfiles/time_attachments/',
  FILE_API_BASE_URL: 'https://uattmsapi.mindwavetech.com/home/uattms/uattms_api/timesheetfiles/time_attachments/',
  // FILE_API_BASE_URL:'http://preprodtmsapi.mindwavetech.com/timesheetfiles/time_attachments/',
  FILE_API_BASE_URL_TEST:'http://testtmsapis.mindwavetech.com/timesheetfiles/time_attachments/',
  FILE_API_BASE_URL_MINDWAVE:'https://tmsapi.mindwavetech.com/home/tmsproduser/tmsproduserapi/timesheetfiles/time_attachments/',

  // FILE_API_BASE_URL_TEST: 'http://testtmsapi.mindwavetech.com/home/testtms/testtmsapis/timesheetfiles/time_attachments/',



  // DEV_API_BASE_URL:'https://13.235.239.119:9008/api/',
  DEV_API_BASE_URL: 'https://devtmsapi.mindwavetech.com/api/',
  TEST_QA_BASE_URL: 'http://testtmsapis.mindwavetech.com:9003/api/',
  UATTMS_API_BASE_URL: 'https://uattmsapi.mindwavetech.com/api/',
  CASTUS_API_BASE_URL: 'https://testtmsapi.mindwavetech.com/api/',
  SECURITY_SERVER: 'https://api.mindwavetech.com:9005/api/',
  MINDWAVE_API_BASE_URL : 'https://tmsapi.mindwavetech.com/api/',

}

export default globalValues