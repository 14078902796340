import React from 'react';
import EnhancedInvoiceTable from '../uiComponents/EnhancedInvoiceTable';
import BackgroundCard from "../uiComponents/BackgroundCard";

 const InvoiceReportsPage = () => {
    return (
        <BackgroundCard title="Invoice Reports" iconName="invoiceReport">
            <EnhancedInvoiceTable />
        </BackgroundCard>
    )
}
export default InvoiceReportsPage;