import React, { useState } from "react";
import {
  Container,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import { VscMail } from "react-icons/vsc";
import { BiLockAlt } from "react-icons/bi";
import Button from "@material-ui/core/Button";
import "../../assets/css/LoginPage.css";
import Link from "@material-ui/core/Link";
import styles from "../../assets/jss/LoginPageStyles.js";
import { ReactComponent as LoginPageImage } from "../../assets/icons/LoginFullImage.svg";
import { useHistory, BrowserRouter, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toastOptions } from "../../ToastOptions";

import TimeSheetServices from "../../services/TimeSheetServices";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Loader from "../uiComponents/Loader";
const useStyles = makeStyles(styles);
function LoginPage() {
  let history = useHistory();
  const location = useLocation();


  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loader, setLoader] = React.useState(false);
  //const [showError, setShowError] = React.useState(false);
  let emailCheck = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9._]+\.[a-zA-Z]{2,4}$/;
  const forgotPassword = () => {
    history.push("/ForgotPassword");
  };
  React.useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);//For browser back button disabled
    window.addEventListener('popstate', function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });
    localStorage.clear()
  });

  // React.useEffect(() => {
  //   setTimeout(() => {
  //     if (location.state?.loggedOut === true) {
  //       toast.warn("You have been loggged out");
  //     }
  //   }, 1000);
  // }, [location])


  //Submit login details
  const login_submit = () => {

    if (!email.trim() || !emailCheck.test(email)) {
      return;
    }
    setLoader(!loader);

    const data = {
      email: email,
      password: password
    }

    TimeSheetServices.login_api(data).then((loginResponce) => {
      if (loginResponce.data.responseCode === 200) {
        setLoader(false);
        let arr = [];
        arr.push(setInterval(function () {
          refreshFun()
        }, 1000 * 60 * 9))
        localStorage.setItem("interval", JSON.stringify(arr))
        localStorage.setItem(
          "login_responce_data",
          JSON.stringify(loginResponce.data)
        );
        if (loginResponce.data.userDetails.firstLogin === true) {
          history.push("/changepassword");
        } else {
          if (loginResponce.data.userDetails.userType === 1) {
            toast.success("Login Successfully",toastOptions);
            history.push("/Timesheeets/timesheets");
          } else if (loginResponce.data.userDetails.userType === 2 || loginResponce.data.userDetails.userType === 5) {
            toast.success("Login Successfully",toastOptions);
            history.push("/Timesheeets/viewtimesheets");
          } else if (loginResponce.data.userDetails.userType === 3) {
            toast.success("Login Successfully",toastOptions);
            history.push("/Timesheeets/timesheets");
          }
          else if (loginResponce.data.userDetails.userType === 4) {
            toast.success("Login Successfully", toastOptions);
            history.push("/Timesheeets/timesheets");
          }

        }
      } else {
        setLoader(false);
        toast.error(loginResponce.data.details,toastOptions);
      }
    }).catch((error) => {
      setLoader(false);
      toast.error(error,toastOptions);
    });
  };
  const refreshFun = () => {
    let localdata = JSON.parse(localStorage.getItem("login_responce_data"))
    let obj = {
      "refresh": localdata.refreshToken
    }
    TimeSheetServices.refresh_token(obj).then((tokenResponce) => {
      localdata.accessToken = tokenResponce.data.access
      localStorage.setItem(
        "login_responce_data",
        JSON.stringify(localdata)
      );
    }).catch((error) => {
      toast.error(error,toastOptions)
    })
  }

  const classes = useStyles();
  return (
    <BrowserRouter>
      <ValidatorForm onSubmit={login_submit}>
        <div className={classes.rootDiv}>
          <Container
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Card className={classes.card}>
              <Grid
                container
                justify="center"
                alignItems="center"
                className={classes.root}
              >
                <Grid
                  item
                  justify="center"
                  alignItems="center"
                  xs={0}
                  sm={7}
                  md={7}
                  lg={7}
                  xl={7}
                  className={classes.root}
                >
                  <LoginPageImage />
                </Grid>
                <Grid
                  item
                  container
                  direction="column"
                  xs={12}
                  sm={4}
                  md={4}
                  lg={4}
                  xl={4}
                  className={classes.root}
                  justify="center"
                  alignItems="center"
                >
                  <Card className={classes.smallCard}>
                    <Grid container className={classes.filedscontainer}>
                      <Grid item xs={0} sm={1} className={classes.mailIcon}>
                        <VscMail style={{ fontSize: " 20px", transform: "translateY(6px)" }} />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={11}
                        className={[classes.textInput]}
                      >
                        {/* <TextField
                          id="email"
                          name="email"
                          label=" Email Address"
                          value={email}
                          autoFocus={true}
                          onChange={(e) => {
                            return setEmail(e.target.value);
                          }}
                          onFocus={() => {
                            setShowError(false);
                          }}
                          onBlur={() => {
                            if (!emailCheck.test(email)) {
                              setShowError(true);
                            }
                          }}
                          error={showError}
                          helperText={
                            !showError ? null : "Enter a valid email address"
                          }
                          autoComplete="off"
                          style={{ width: "100%", paddingLeft: 3 }}
                        /> */}
                        <TextValidator
                          id="standard-basic"
                          className="textwidth"
                          label=" Email Address"
                          name="email"
                          type="email"
                          data-testid="login-email-id"
                          value={email}
                          onChange={(e) => {
                            return setEmail(e.target.value);
                          }}
                          validators={["required", "isEmail"]}
                          errorMessages={[
                            "Email address is required",
                            "Enter a valid email address",
                          ]}
                          autoComplete="new-password"

                        />
                      </Grid>
                    </Grid>
                    <Grid container className={classes.filedscontainer}>
                      <Grid item xs={0} sm={1} className={classes.mailIcon}>
                        <BiLockAlt style={{ fontSize: " 20px", transform: "translateY(6px)" }} />
                      </Grid>
                      <Grid item xs={12} sm={11} className={classes.textInput}>
                        <TextValidator
                          value={password}
                          id="password"
                          name="password"
                          label="Password"
                          data-testid="login-password"
                          type="password"
                          onChange={(e) => setPassword(e.target.value.replace(/\s/g, ""))}
                          validators={["required"]}
                          errorMessages={["Password is required"]}
                          autoComplete="off"
                        // style={{ width: "100%", paddingLeft: 3 }}
                        />
                      </Grid>
                    </Grid>
                  </Card>
                  <Grid container className={classes.link} justify="flex-end">
                    <Grid item container xs={12} justify="flex-end">
                      <Link onClick={forgotPassword} href="#">
                        Forgot Password ?
                      </Link>
                    </Grid>
                  </Grid>
                  <Grid container justify="center">
                    <Grid item container xs={12} justify="center">
                      <Button data-testid="login-button" className={classes.button} type="submit">
                        Login
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid container justify="flex-end">
                    <Grid item container xs={12} justify="flex-end">
                      <Typography>V 1.5.0</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Container>
        </div>
      </ValidatorForm>
      <Loader open={loader} />
    </BrowserRouter>
  );
}
export default LoginPage;
