import React from 'react';
import EnhancedTable from '../uiComponents/EnhancedTable.js';
import BackgroundCard from "../uiComponents/BackgroundCard";
const PayrollReportsPage = () => {
    return (
        <BackgroundCard title="Payroll Reports" iconName="payrollReport">
            <EnhancedTable />
        </BackgroundCard>
    )
}
export default PayrollReportsPage;