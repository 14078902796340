import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import SettingsBackupRestoreIcon from "@material-ui/icons/SettingsBackupRestore";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TablePagination from "@material-ui/core/TablePagination";
import Edit from "@material-ui/icons/Edit";
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import CustomButton from "./Button";
import { useHistory } from "react-router-dom";
import styles from "../../assets/jss/tasksStyle";
import TimeSheetServices from "../../services/TimeSheetServices";
import { toast } from "react-toastify";
import { CircularProgress, TableContainer, Typography } from "@material-ui/core";
import AlertDialog from "./AlertDialog";
import CircleIcon from '@mui/icons-material/Circle';
import { toastOptions } from "../../ToastOptions";
import HeaderStrip from "./HeaderStrip";
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Loader from "../uiComponents/Loader";
import { DialogContent } from "@mui/material";
const taskStyles = makeStyles(styles);

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      padding: "10px",
    },
    "& :MuiTableCell": {
      padding: "10px",
    },
  },
  tableHead: {
    backgroundColor: "#e9e9e9",
  },
  saveBtn: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
  },
  cancelBtn: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.common.white,
  },
  ActiveStatusButton: {
    width: 77,
    textAlign: "center",
    justifyContent: "center",
    backgroundColor: "Green",
    borderRadius: "20px",
    color: "white",
  },
  deActiveStatusButton: {
    width: 77,
    textAlign: "center",
    justifyContent: "center",
    backgroundColor: "red",
    borderRadius: "20px",
    color: "white",
  },
  openStatusButton: {
    width: 77,
    textAlign: "center",
    justifyContent: "center",
    backgroundColor: "#4179f8",
    borderRadius: "20px",
    color: "white",
  },
  statusButtonText: {
    fontSize: 12,
    marginTop: 0,
    padding: 5,
    marginBottom: 0,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  tableHeadeCell: {
    padding: "14px 5px 14px 13px",
  },
  iconscss: {
    paddingLeft: 5,
    fontSize: 24,
    color: "grey",
    outline: "none",
    "&:focus": {
      outline: "none",
    },
  },
  enhancedTableHeadStyle: {
    padding: "12px 32px 12px 32px", fontSize: "14px", zIndex: 0 
  },
  noData: {
    display: "flex",
    justifyContent: "center",
  },
  tableRow: {
    cursor: "pointer"
  }
}));

function descendingComparator(a, b, orderBy) {
  if (orderBy === 'firstName' || orderBy === 'lastName') {
    if ((a[orderBy]).toLowerCase() > (b[orderBy]).toLowerCase()) {
      return -1;
    }
    if ((a[orderBy]).toLowerCase() < (b[orderBy]).toLowerCase()) {
      return 1;
    }
    return 0;
  }

  if (orderBy !== 'firstName' || orderBy !== 'lastName') {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const MainTable = (props) => {
  let history = useHistory();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [showLoader, setShowLoader] = React.useState(false);
  const [loaderHelp, setLoaderHelp] = React.useState(0);
  const [selectUser, setSelectUser] = React.useState(0);
  const [loader, setLoader] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const taskStyleclasses = taskStyles();
  const { tableHead, tableData } = props;
  const classes = useStyles();
  const [disableUserPopup, setDisableUserPopup] = useState(false);
  const [disableReason, setDisableReason] = useState('')
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);

  const [currentUserDetails, setCurrentUserDetails] = useState(null)

  useEffect(() => {
    setPage(0);
  }, [props.tableData]);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleDisableUser = (e, obj) => {
    e.preventDefault()
    setIsSubmitClicked(true);

    if (disableReason !== '') {
      if (obj.status === 'I' || obj.status === 'A') {
        TimeSheetServices.user_activation(obj.userId, 'F', disableReason).then((response) => {
          if (response.data.responseCode === 200) {
            props.callApi()
            setDisableUserPopup(false)
            setIsSubmitClicked(false)
            toast.success(response.data.details, toastOptions);
          } else {
            toast.error(response.data.details, toastOptions);
          }
        }).catch((error) => {
          toast.error(error, toastOptions);
        });
      }
    }

  };

  const view_user_edit_Click = (index, obj) => {
    // console.log(obj);
    obj.isFrom = "editUser";
    localStorage.setItem("edit_user_data", JSON.stringify(obj));
    history.push("/Timesheeets/edituser");
  };
  const view_user_delete_Click = (obj) => {
    setSelectUser(obj)
    setOpen(true)
  }
  const confirm = () => {
    deleteUserFun()
    setOpen(false);
  }
  const handleClose = () => {
    setOpen(false);
  }
  const deleteUserFun = () => {
    setLoader(!loader);
    let userid = selectUser.userId
    TimeSheetServices.delete_user(userid).then((responce) => {
      if (responce.data.responseCode === 200) {
        setLoader(false);
        toast.success(responce.data.details, toastOptions);
        props.callApi()
      } else {
        setLoader(false);
        toast.error(responce.data.details, toastOptions);
      }
    }).catch((error) => {
      setLoader(false);
      toast.error(error, toastOptions);
    });
  }

  const pwdReset = (index, obj) => {
    setLoaderHelp(index);
    let EMAILPATTERN = /^(?!.*@.*@)[a-zA-Z0-9.-_]{1,}@{1,}[a-zA-Z.-]{1,}[.]{1}[a-zA-Z]{2,}/;
    let pattern = EMAILPATTERN.test(obj.email);
    if (pattern) {
      setShowLoader(true);
      TimeSheetServices.forget_password(obj.email).then((responce) => {
        if (responce.data.responseCode === 200) {
          setShowLoader(false);
          toast.success(responce.data.details, toastOptions);
        } else {
          setShowLoader(false);
          toast.error(responce.data.details, toastOptions);
        }
      }).catch((error) => {
        setShowLoader(false);
        toast.error(error, toastOptions);
      });
    } else {
      toast.error("Email Address is not valid", toastOptions);
    }
  };

  const view_users = (ndex, obj) => {
    obj.isFrom = "viewUser";
    localStorage.setItem("edit_user_data", JSON.stringify(obj));
    history.push("/Timesheeets/edituser");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const activateUser = (index, obj) => {

    if (obj.status === 'I' || obj.status === 'A') {
      setDisableUserPopup(true);
      setCurrentUserDetails(obj)
    }
    else {
      TimeSheetServices.user_activation(obj.userId, 'A').then((response) => {
        if (response.data.responseCode === 200) {
          props.callApi()
          toast.success(response.data.details, toastOptions);
        } else {
          toast.error(response.data.details, toastOptions);
        }
      }).catch((error) => {
        toast.error(error, toastOptions);
      });
    }


  }

  function EnhancedTableHead(props) {
    const {
      order,
      orderBy,
      onRequestSort,
    } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
    return (
      <TableHead style={{ zIndex: 1 }}>
        <TableRow style={{ backgroundColor: "#e9e9e9" }}>
          {tableHead.map((headCell) => (
            <TableCell
              key={headCell.id}
              align="left"
              className={classes.enhancedTableHeadStyle}
              sortDirection={orderBy === headCell.id ? order : false} 
              style={{zIndex : headCell.label === 'Actions' && 1}}
              >
              {/* <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)} >
              </TableSortLabel> */}
              <Typography style={{ color: "black" }}>
                {headCell.label}
              </Typography>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    classes: PropTypes.object,
    numSelected: PropTypes.number,
    onRequestSort: PropTypes.func,
    onSelectAllClick: PropTypes.func,
    order: PropTypes.oneOf(["asc", "desc"]),
    orderBy: PropTypes.string.isRequired,
  };
  return (
    <div>
      {tableData.length !== 0 ? (
        <TableContainer
          style={{ width: "100%", maxHeight: 440 }}>
          <Table className={classes.table} aria-label="customized table" stickyHeader>
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {stableSort(tableData, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((prop, index, key) => {
                  return (
                    <TableRow
                      key={index}
                      className={classes.tableRow}
                      onDoubleClick={() => view_users(index, prop)} >
                      <TableCell
                        className={taskStyleclasses.tableCell}
                        align="left"
                        style={{ textTransform: 'capitalize' }}
                      >
                        {prop.firstName}
                      </TableCell>
                      <TableCell
                        className={taskStyleclasses.tableCell}
                        align="left"
                        style={{ textTransform: 'capitalize' }}
                      >
                        {prop.lastName}
                      </TableCell>
                      <TableCell
                        className={taskStyleclasses.tableCell}
                        align="left" >
                        {prop.vendorName}
                      </TableCell>
                      <TableCell
                        className={taskStyleclasses.tableCell}
                        align="left" >
                        {prop.roleDesc}
                      </TableCell>
                      <TableCell
                        style={{ position: 'relative',display:'flex',alignItems :'center' }}
                        className={taskStyleclasses.tableCell}
                        align="left" >
                        <Tooltip
                          id="tooltip-top"
                          title={
                            <span style={{ fontSize: "11px", color: "white" }}>
                              Edit User
                            </span>
                          }
                          placement="right">
                          <Edit
                            onClick={() => view_user_edit_Click(index, prop)}
                            className={classes.iconscss} />
                        </Tooltip>
                        {/* <Tooltip
                          id="tooltip-top"
                          title={
                            <span style={{ fontSize: "11px", color: "white" }}>
                              Delete User
                            </span>
                          }
                          placement="right">
                          <DeleteIcon
                            onClick={() => view_user_delete_Click(prop)}
                            className={classes.iconscss} />
                        </Tooltip> */}
                        <Tooltip
                          id="tooltip-top"
                          title={
                            <span style={{ fontSize: "11px", color: "white" }}>
                              Reset Password
                            </span>
                          }
                          placement="right">
                          {showLoader && loaderHelp === index ? (
                            <CircularProgress color="inherit" size={15} />
                          ) : (<SettingsBackupRestoreIcon
                            onClick={() => pwdReset(index, prop)}
                            className={classes.iconscss}
                          ></SettingsBackupRestoreIcon>)}
                        </Tooltip>

                        {prop.userType !== 1 && (
                          <Tooltip
                            id="tooltip-top"
                            title={
                              <span style={{ fontSize: "11px", color: "white" }}>
                                {prop.status === 'A' || prop.status === 'I' ? 'Disable User' : 'Activate User'}
                              </span>
                            }
                            placement="right">

                            {/* <CircleIcon
                              
                              className={classes.iconscss}
                              style={{ color: (prop.status === 'A' || prop.status === 'I') ? '#00B863' : '#f50057' }}
                            ></CircleIcon> */}
                            {prop.status === 'A' || prop.status === 'I' ? (
                              <div onClick={() => activateUser(index, prop)} className="switch-toggler">
                                Active
                                <span></span>
                              </div>
                            ) : (
                              <div onClick={() => activateUser(index, prop)} className="switch-toggler inactive">
                                Inactive
                                <span></span>
                              </div>
                            )}
                          </Tooltip>

                        )}


                      </TableCell>
                    </TableRow>
                  );
                })}

            </TableBody>
          </Table>
        </TableContainer>
      ) : tableData.length === 0 || tableData.length === undefined || tableData.length === null ? <div>
        <TableContainer style={{ width: "100%", maxHeight: 440 }}>
          <Table className={classes.table} aria-label="customized table">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
          </Table>
          <p className={classes.noData} >No data to display</p>
        </TableContainer>
      </div> : null}
      {tableData.length !== 0 ? (
        <TablePagination
          rowsPerPageOptions={[5, 10, 100]}
          component="div"
          count={tableData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      ) : (
        null
      )}
      <AlertDialog
        alertTitle="Delete"
        alerContent="Are you sure, you want to delete?"
        saveButtonText="Yes"
        cancelButtonText="No"
        open={open}
        onSubmit={confirm}
        onCancel={handleClose} />

      <AlertDialog
        alertTitle="Disable User"
        width='32vw'
        alerContent={
          <>
            <div className="dialog-disable-content">
              <form action="">
                <label htmlFor="reason">Reason <span style={{ color: '#ff1744' }}>*</span></label>
                <p className="disable-reason-input" style={{ position: 'relative' }}>
                  <textarea maxLength='50' onChange={(e) => { setDisableReason(e.target.value) }} value={disableReason} required style={{ width: '100%' }} type="text" name="reason" />
                  {isSubmitClicked && disableReason.trim() === '' && <span className="dialog-disable-error">Please enter a reason</span>}
                </p>
              </form>
            </div>
          </>}
        textAlign="left"
        saveButtonText="Yes"
        cancelButtonText="No"
        open={disableUserPopup}
        onSubmit={(e) => { handleDisableUser(e, currentUserDetails) }}
        onCancel={() => { setDisableUserPopup(false) }} />

      <Loader open={loader} />
    </div>
  );
};
export default MainTable;
