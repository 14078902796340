import { createMuiTheme } from "@material-ui/core/styles";
const theme = createMuiTheme({
  overrides: {
    MuiTableCell: {
      root: {
        align: "center",
        padding: "0px 5px 0px 13px",
        textAlign: "center",
       
       
      },
    },
    MuiTable: {
      root: {
        width: "100%",
        minWidth: 700,
        borderSpacing: "1px 11px",
        borderCollapse: "separate",
        display: "table",
      },
    },
    MuiTableRow: {
      root: {
        border: "1px solid black",
        width: "100px",
        overflow: "hidden",
        outline: "1px solid #cacaca",
        boxShadow: "-1px 2px 5px 0px rgba(204,204,204,1)",
      },
    },
    
    MuiButtonBase: {
      root: {
       outline:"none",
       "&:focus": {
        outline: "none",
      },
      },
      
    },

    MuiFormControl:{
      root: {
       width: 262
       },
    },
   MuiSelect: {
      filled: {
        backgroundColor: "white",
      },
      "& focus": {
        backgroundColor: "white",
      },
    },

    MuiFilledInput: {
     
    },   
    MuiInputBase:{
       input: {
        color: '#000000',
      },
    }
  
  },
 
  typography: {
    fontSize: "28px",
    fontFamily: "Lato, sans-serif",
  },
  customHoverFocus: {
    "&:focus": {
        outline: "none",
      },   
  },
});

export default theme;
