import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Select from "react-select";
import Button from "@material-ui/core/Button";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import Toolbar from "@material-ui/core/Toolbar";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import Spinner from "./Spinner";
import exportToExcel from "../../constant/exportToExcel.js";
import TimeSheetServices from "../../services/TimeSheetServices";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { Typography } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import MainTable from "../uiComponents/PopTable";
import { viewPopupHeading } from "../../assets/data/viewTimesheetData";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import EventIcon from '@mui/icons-material/Event';

function createData(
  firstName,
  lastName,
  category,
  fromDate,
  toDate,
  reqularHours,
  otHours,
  totalHours,
  basicPay,
  otRate,
  comments,
  vendorName,
  clientName,
  orgId,
  userId,
  employeeId
) {
  return {
    firstName,
    lastName,
    category,
    fromDate,
    toDate,
    reqularHours,
    otHours,
    totalHours,
    basicPay,
    otRate,
    comments,
    vendorName,
    clientName,
    orgId,
    userId,
    employeeId
  };
}

function descendingComparator(a, b, orderBy) {


  if(orderBy === 'firstName' || orderBy === 'lastName' ){
    if ((a[orderBy]).toLowerCase() > (b[orderBy]).toLowerCase()) {
      return -1;
    }
    if ((a[orderBy]).toLowerCase() < (b[orderBy]).toLowerCase()) {
      return 1;
    }
    return 0;    
  }

  if(orderBy !== 'firstName' || orderBy !== 'lastName'){
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: "firstName", numeric: false,disablePadding: false,label: "First Name"},
  { id: "lastName", numeric: false, disablePadding: true, label: "Last Name" },
  { id: "category", numeric: true, disablePadding: true, label: "Category" },
  { id: "fromDate", numeric: true, disablePadding: true, label: "From Date" },
  { id: "toDate", numeric: true, disablePadding: true, label: "To Date" },
  { id: "reqularHours",numeric: true,disablePadding: true,label: "Regular Hours"},
  { id: "otHours", numeric: true, disablePadding: true, label: "OT Hours" },
  { id: "totalHours",numeric: true, disablePadding: true, label: "Total Hours"},
  { id: "basicPay", numeric: true, disablePadding: true, label: "Pay Rate" },
  { id: "otRate", numeric: true, disablePadding: true, label: "OT Rate " },
  { id: "comments", numeric: true, disablePadding: true, label: "EMP Special Notes"},
  // { id: "vendorName", numeric: true, disablePadding: true,label: "Vendor Name"},
  { id: "clientName",numeric: true,disablePadding: true,label: "Client Name"},
];

const useHeaderStyles = makeStyles((theme) => ({
  tableHeaderLabel: {
    fontSize: 14,
  },
  tableHeader: {
    background: "#F7F7F7",
    paddingLeft: 10,
    height: 44,
  },
  tableHeaderCell: {
    color: "#000",
    fontSize: 14,
    fontWeight: 500,
    padding: 0,
    paddingTop: 6,
  },
}));

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const classes = useHeaderStyles();
    return (
    <TableHead>
      <TableRow className={classes.tableHeader}>
        {headCells.map((headCell) => {
          const x =
            headCell.label === "First Name"
              ? {
                  position: "sticky",
                  left: 0,
                  zIndex: 2,
                }
              : { zIndex: 0 };
           return (
            <TableCell
              key={headCell.id}
              className={classes.tableHeaderCell}
              style={{
                ...x,
                textAlign:
                  headCell.label === "First Name" ||
                  headCell.label === "Last Name" ||
                  headCell.label === "Vendor Name" ||
                  headCell.label === "From Date" ||
                  headCell.label === "Client Name" ||
                  headCell.label === "To Date" ||
                  headCell.label === "EMP Special Notes" ||
                  headCell.label === "Category"
                    ? "left"
                    : "center",
                paddingLeft: 15,
              }}
            >
               {/* <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
              </TableSortLabel> */}

              <Typography noWrap style={{ color: "black" }}>
                  {headCell.label}
                </Typography>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: 0,
    paddingRight: theme.spacing(1),
    marginBottom: 0,
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
  Select: {
    width: 150,
    marginLeft: 20,
  },
  searchName: {
    width: 215,
    height: 30,
    paddingLeft: 30,
    border: "1px solid #DCDCDD",
    borderRadius: 4,
    fontSize: 15,
  },
  searchIcon: {
    width: 16,
    height: 20,
    position: "absolute",
    left: 7,
  },
  calendarIcon: {
    width: 16,
    height: 20,
    position: "absolute",
    left: 243,
  },
  resetBtn: {
    marginLeft: 20,
    background: "#54586D",
    height: 30,
  },
}));

const customStyles = {
  control: (base) => ({
    ...base,
    height: 30,
    minHeight: 30,
  }),
  placeholder: (state) => ({
    position: "absolute",
    color: "grey",
    top: 4,
  }),
  indicatorsContainer: (state) => ({
    position: "absolute",
    bottom: -4,
    right: 0,
  }),
  singleValue: (state) => ({
    position: "absolute",
    top: 4,
  }),
};

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const [headerData, setHeaderData] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedReq, setSelectedReq] = useState(null);
  const [vendorData, setVendorData] = useState([]);
  const [clientData, setClientData] = useState([]);
  const [reqularData, setReqularData] = useState([]);
  const [nameVal, setNameVal] = useState("");
  const isMounted = useRef(false);
  const [defaultValue,setDefaultValue]=useState("");
  const includeDateCombin = props.includeDateCombin;
  useEffect(() => {
    if (isMounted.current) {
      props.sortFilter(nameVal, selectedVendor, selectedClient, selectedReq);
    } else {
      isMounted.current = true;
    }
  }, [nameVal, selectedVendor, selectedClient, selectedReq,includeDateCombin]);

  const handleChange = (selectedOption) => {
    if (selectedOption.id === "vendor") {
      setSelectedVendor(selectedOption);
    } else if (selectedOption.id === "client") {
      setSelectedClient(selectedOption);
    } else if (selectedOption.id === "reqular") {
      setSelectedReq(selectedOption);
    }
  };

  const getName = (e) => {
    setNameVal(e.target.value.replace(/[^a-zA-Z]/g, ''));
  };
//reset button function for reset the data
  const resetOptions = () => {
    setSelectedVendor(null);
    setSelectedClient(null);
    setSelectedReq(null);
    setNameVal("");
    setDefaultValue("");
    document.getElementById("clearDateRanges").value = "";
    props.reset();
  };
   const getDatePicker = (event, picker) => {
    // picker.element.val(
    //   picker.startDate.format("MM-DD-YYYY") +
    //     " - " +
    //     picker.endDate.format("MM-DD-YYYY")
    // );
    let startDate = picker.startDate.format("YYYY-MM-DD"); //sending date in this format to backend for api call
    let endDate = picker.endDate.format("YYYY-MM-DD");
    setDefaultValue(`${picker.startDate.format("MM-DD-YYYY")} - ${picker.endDate.format("MM-DD-YYYY")}`);
     return props.filterByDate(startDate, endDate);
  };
  const handleCancel = (event, picker) => {
    picker.setStartDate(new Date());
    picker.setEndDate(new Date());
    setDefaultValue("");
    return resetOptions();
  };

  useEffect(() => {
    let logi_data = JSON.parse(localStorage.getItem("login_responce_data"));
    let orgId = logi_data.userDetails.orgId;
    let userId = logi_data.userDetails.userId;
    TimeSheetServices.payroll_reports(orgId, userId)
      .then((res) => {
        let vendorDetails = [{ label: "All", value: "All", id: "vendor" }],
          clientDetails = [{ label: "All", value: "All", id: "client" }],
          reqDetails = [];
        let dataDetails = res.data.payrollDetails;
        setHeaderData(res.data.payrollDetails);
        dataDetails.map((data) => {
          let obj = {},
            clientObj = {},
            reqObj = {};
          if (data.vendorName && data.vendorName !== "") {
            obj["label"] = data.vendorName;
            obj["value"] = data.vendorName;
            obj["id"] = "vendor";
            vendorDetails.push(obj);
          }
          if (data.clientName && data.clientName !== "") {
            clientObj["label"] = data.clientName;
            clientObj["value"] = data.clientName;
            clientObj["id"] = "client";
            clientDetails.push(clientObj);
          }
          if (data.reqularHours && data.reqularHours !== "") {
            reqObj["label"] = data.reqularHours;
            reqObj["value"] = data.reqularHours;
            reqObj["id"] = "reqular";
            reqDetails.push(reqObj);
          }
        });
        setVendorData(
          [...new Set(vendorDetails.map(({ label }) => label))].map((e) =>
            vendorDetails.find(({ label }) => label == e)
          )
        );
        setClientData(
          [...new Set(clientDetails.map(({ label }) => label))].map((e) =>
            clientDetails.find(({ label }) => label == e)
          )
        );
        setReqularData([
          { label: "All", value: "All", id: "reqular" },
          { label: "Regular", value: "R", id: "reqular" },
          { label: "Special", value: "S", id: "reqular" },
          { label: "Monthly", value: "M", id: "reqular" },
          { label: "0822", value: "N", id: "reqular" },
        ]);
      })
      .catch((err) => {});
  }, []);

  return (
    <Toolbar className={clsx(classes.root)}>
      <input
        type="text"
        placeholder="First Name/Last Name"
        value={nameVal}
        onChange={getName}
        className={classes.searchName}
      />
      <img
        src="/assets/icons/search.svg"
        alt="Search"
        className={classes.searchIcon}
      />
      <DateRangePicker
        id="dtPicker"
        initialSettings={{
          autoUpdateInput: false,
          locale: {
            cancelLabel: "Clear",
            firstDay:6
          },
      }}
        onApply={getDatePicker}
        onCancel={handleCancel}
      >
        <div style={{display:"flex",flexDirection:"row" ,justifyContent:"flex-start", alignItems:"center",position:"relative",width:"max-content"}}>
        <EventIcon style={{
            position: "absolute",
            left: "26px",
            bottom: "6px",
            fontSize: "17px",
            color : "#797876"
          }} />
        <input
          id="clearDateRanges" 
          type="text"
          placeholder="Start Date - End Date"
         // defaultValue=""
          style={{ width: "198px", marginLeft: 20 }}
          className={classes.searchName}
          readOnly={true}
          value={defaultValue}
        />
        </div>
      </DateRangePicker>
    
      {/* <Select
        value={selectedVendor}
        onChange={handleChange}
        options={vendorData}
        placeholder="Vendor Name"
        className={classes.Select}
        styles={customStyles}
      /> */}
      <Select
        value={selectedClient}
        onChange={handleChange}
        options={clientData}
        placeholder="Client Name"
        className={classes.Select}
        styles={customStyles}
      />
      <Select
        value={selectedReq}
        onChange={handleChange}
        options={reqularData}
        placeholder="Regular/Special"
        className={classes.Select}
        styles={customStyles}
      />
      {!nameVal &&!selectedVendor && !selectedClient &&  !selectedReq ? "":
      <Button
        variant="contained"
        color="primary"
        onClick={resetOptions}
        className={classes.resetBtn}
      >
        Reset
      </Button>
}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
 const useStyles = makeStyles((theme) => ({
  customTooltip: {
    backgroundColor: "#FFFFFF",
  },
  rootPaper: {
    padding: 30,
    background: "#FDFDFF",
    boxShadow: "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
  },
  root: {
    width: "100%",
    padding: 0,
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  tableHeaderCls: {
    border: "1px solid #e3e3e3",
    cursor:"pointer"
  },
  tableCellCls: {
    border: "none",
    textAlign: "center",
    padding: 2,
    height: 52,
    paddingTop: 6,
  },
  tableCellClss: {
    border: "none",
    textAlign: "right",
    padding: 2,
    height: 52,
    paddingTop: 6,
  },
  tablePaginationCls: {
    marginTop: 20,
  },
  avatarCls: {
    width: 20,
    height: 20,
  },
  labelAvatar: {
    fontSize: 14,
    paddingTop: 5,
  },
  filterSection: {
    marginBottom: 0,
    marginTop: 22,
  },
  exportBtnCls: {
    textAlign: "center",
    marginTop: 20,
  },
  exportBtn: {
    margin: 11,
    fontSize: 16,
    borderRadius: 30,
    textTransform: "capitalize",
    backgroundColor: "#00B863",
    color: theme.palette.common.white,
    border: "none",
    padding: 5,
    width: 112,
    "&:focus": {
      outline: "none",
      WebkitBoxShadow: "-12px 2px 26px -11px rgba(52,191,54,1)",
      MozBoxShadow: "-12px 2px 26px -11px rgba(52,191,54,1)",
      boxShadow: "-12px 2px 26px -11px rgba(52,191,54,1)",
    },
    "&:hover": {
      backgroundColor: "#00B863",
      WebkitBoxShadow: "-12px 2px 26px -11px rgba(52,191,54,1)",
      MozBoxShadow: "-12px 2px 26px -11px rgba(52,191,54,1)",
      boxShadow: "-12px 2px 26px -11px rgba(52,191,54,1)",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    marginTop: "10vh",
    borderRadius: 10,
    backgroundColor: "#FFF",
    border: "1px solid #FFF",
    boxShadow: theme.shadows[5],
    padding: "18px 48px",
  },
}));

export default function EnhancedTable() {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = useState([]);
  const [defaultData, setDefaultData] = useState([]);
  const [currentData, setCurrentData] = React.useState([]);
  const [toggleNull, setToggleNull] = useState(false);
  const [PopUpState, setPopUpState] = useState(false);
  const [employeeData, setemployeeData] = useState([]);
  const [firstname, setFirstName] = useState("");
  const [loginData] = React.useState(JSON.parse(localStorage.getItem("login_responce_data")));
  const [includeDateCombin, setIncludeDateCombin] = React.useState(false);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleClose = () => {
    setPopUpState(false);
  };
   useEffect(() => {
    let orgId = loginData.userDetails.orgId;
    let userId = loginData.userDetails.userId;
     TimeSheetServices.payroll_reports(orgId, userId).then((res) => {
        const getCreateData = res.data.payrollDetails.map((data, id) => {
          return createData(
            data.firstName,
            data.lastName,
            data.category,
            data.fromDate,
            data.toDate,
            data.reqularHours,
            data.otHours,
            data.totalHours,
            data.basicPay,
            data.otRate,
            data.comments,
            data.vendorName,
            data.clientName,
            data.orgId,
            data.userId,
            data.employeeId
          );
        });
        res.data.payrollDetails.length
          ? setToggleNull(false)
          : setToggleNull(true);
        setRows(getCreateData);
        setDefaultData(getCreateData);
        setCurrentData(getCreateData);
      }).catch((err) => {});
  }, []);
    const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
     if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
//export payroll records
  const exportHandler = async ()=> {
    const sortedExcelData = await rows.map(
      ({
        employeeId,
        userId,
        firstName,
        lastName,
        vendorName,
        fromDate,
        toDate,
        reqularHours,
        otHours,
        totalHours,
        basicPay,
        otRate,
        comments,
      }) => {
        return {
          "Emp ID": employeeId,
          "First Name": firstName,
          "Last Name": lastName,
          // "Vendor Name": vendorName,
          "From Date": moment(fromDate).format("MM-DD-YYYY"),
          "To Date": moment(toDate).format("MM-DD-YYYY"),
          "Reg Hours": reqularHours,
          "Total OT Hours": otHours,
          "Total Hours": reqularHours+otHours,
          "Reg.Pay Rate": basicPay,
          "OT %": otRate,
          "Emp Special Notes": comments,
          "Bonus Amount": null,
          Comments: null,
        };
      }
    );
    exportToExcel(sortedExcelData,"payroll");
  }
    const getFilterByName = (name) => {
    setPage(0);
    if (name.length > 0) {
      setRows([]);
      const getSortByName = currentData.filter((item) => {
        return (
          item.firstName
            .toUpperCase()
            .startsWith(name.toString().toUpperCase()) ||
          item.lastName.toUpperCase().startsWith(name.toString().toUpperCase())
        );
      });
      getSortByName.length ? setToggleNull(false) : setToggleNull(true);
      setRows(getSortByName);
    } else if (name.length === 0) {
      setRows(currentData);
    }
  };

  const sortByDate = (start, end) => {
    setPage(0);
    setRows([]);
    setToggleNull(false);
    let orgId = loginData.userDetails.orgId;
    let userId = loginData.userDetails.userId;
     TimeSheetServices.getPayroll_by_date(orgId, userId, start, end).then((res) => {
        res.data.payrollDetails.length
          ? setToggleNull(false)
          : setToggleNull(true);
        setCurrentData(res.data.payrollDetails);
        setIncludeDateCombin((prevState) => !prevState);
      }
    ).catch((err) => {});
  };
    const getFilter = (nameVal, selectedVendor, selectedClient, selectedReq) => {
    setPage(0);
    setRows([]);
    newGetFilter(nameVal, selectedVendor, selectedClient, selectedReq);
  };
  const newGetFilter = (
    nameVal,
    selectedVendor,
    selectedClient,
    selectedReq
  ) => {
    const helpFilter = [];
    let sa = selectedVendor ? helpFilter.push(selectedVendor) : null;
    sa = selectedClient ? helpFilter.push(selectedClient) : null;
    sa = selectedReq ? helpFilter.push(selectedReq) : null;
     const sortedResult = currentData.filter((item) => {
      const nameRes =
        item.firstName
          .toUpperCase()
          .startsWith(nameVal.toString().toUpperCase()) ||
        item.lastName
          .toUpperCase()
          .startsWith(nameVal.toString().toUpperCase());
        const storeFilterRes = helpFilter.map((dropDown) => {
        switch (dropDown.id) {
          case "vendor":
            if (dropDown.label === "All") {
              return true;
            } else {
              return dropDown.value === item.vendorName;
            }
              case "client":
            if (dropDown.label === "All") {
              return true;
            } else {
              return dropDown.value === item.clientName;
            }
             case "reqular":
            if (dropDown.label === "All") {
              return true;
            } else {
              return dropDown.value === item.category;
            }
          default:
            break;
        }
      });
      const filterResult = storeFilterRes.every((filter) => {
        return filter === true;
      });
        if (nameVal && helpFilter.length) {
        return nameRes && filterResult;
      } else if (!nameVal && helpFilter.length) {
        return filterResult;
      } else {
        return nameRes;
      }
    });
    if (sortedResult.length === 0) {
      setToggleNull(true);
    } else {
      setRows(sortedResult);
    }
  };
   const setReset = () => {
    setPage(0);
    setRows([]);
    setToggleNull(false);
    setRows(defaultData);
    setCurrentData(defaultData);
  };
   const handleDoubleClick = (row) => {
   getUserPayrollDetails(row);
  };
   const getUserPayrollDetails = (row) => {
    let orgId = row.orgId;
    let userId = row.userId;
    let fromDate = row.fromDate;
    let toDate = row.toDate;
    setFirstName(row.firstName);
    TimeSheetServices.payroll_details(orgId, userId, fromDate, toDate).then((res) => {
        if (res.data.responseCode === 200) {
          const payrollDetails = res.data.payrollDetails;
          payrollDetails.isFrom = "viewUsers";
          setemployeeData(payrollDetails);
          setPopUpState(true);
       } else {
          // this.setState({ showLoader: false,showImage:true });
        }
      }).catch((error) => {
      
      });
  };
   const isSelected = (name) => selected.indexOf(name) !== -1;
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
    return (
    <div style={{ maxWidth: "80vw" }}>
      <div>
        <EnhancedTableToolbar
          numSelected={selected.length}
          sortFilter={getFilter}
          sortName={getFilterByName}
          filterByDate={sortByDate}
          reset={setReset}
          includeDateCombin={includeDateCombin}
        />
        {rows.length > 0 ? (
          <div>
            <TableContainer style={{ width: "100%", maxHeight: 440 }}>
              <Table
                stickyHeader
                className={classes.table}
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.firstName);
                      const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                        <TableRow
                          onClick={(event) => handleClick(event, row.firstName)}
                          key={index}
                          className={classes.tableHeaderCls}
                          onDoubleClick={(event) => handleDoubleClick(row)}
                        >
                          <TableCell
                          //  className={classes.tableCellCls}
                            style={{
                              textAlign: "left",
                              paddingLeft: 15,
                              position: "sticky",
                              left: 0,
                              zIndex: 1,
                              backgroundColor: "#ffffff",
                            }}
                            id={labelId}
                            scope="row">
                           <Typography
                            noWrap
                            style={{ color: "black",textTransform :'capitalize' }}
                           >
                            {row.firstName}
                            </Typography>
                          </TableCell>
                          <TableCell align="left"
                           // className={classes.tableCellCls}
                           style={{ textTransform:'capitalize' }}

                            >
                            {row.lastName ? row.lastName : "--"}
                          </TableCell>
                          <TableCell
                            className={classes.tableCellCls}
                           >
                            {row.category === "S"
                              ? "Special"
                              : row.category === "R"
                              ? "Regular":row.category === "M"? "Monthly"
                              : "---"}
                          </TableCell>
                          <TableCell
                            className={classes.tableCellCls}
                            >
                            {row.fromDate
                              ? moment(row.fromDate).format("MM-DD-YYYY")
                              : "--"}
                          </TableCell>
                          <TableCell
                            className={classes.tableCellCls}
                          >
                            <Typography noWrap style={{ color: "black" }}>
                              {row.toDate
                                ? moment(row.toDate).format("MM-DD-YYYY")
                                : "--"}
                            </Typography>
                          </TableCell>
                          <TableCell
                            className={classes.tableCellClss}
                          
                            >
                            {row.reqularHours ? row.reqularHours : "--"}
                          </TableCell>
                          <TableCell
                            className={classes.tableCellClss}
                         
                            >
                            {row.otHours ? row.otHours : "--"}
                          </TableCell>
                          <TableCell
                            className={classes.tableCellClss}
                          
                          >
                         {row.otHours || row.reqularHours ? Number(row.otHours + row.reqularHours).toFixed(2):"--"}
                          </TableCell>
                          <TableCell
                            className={classes.tableCellClss}
                          
                           >
                            {row.basicPay ? row.basicPay : "--"}
                          </TableCell>
                          <TableCell
                            className={classes.tableCellClss}
                         
                          >
                            {row.otRate ? row.otRate : "--"}
                          </TableCell>
                          <TableCell
                            className={classes.tableCellCls}
                            style={{ textAlign: "left", paddingLeft: 15 }}>
                            <Tooltip
                              classes={{
                                tooltip: classes.customTooltip,
                              }}
                              id="tooltip-top"
                              title={
                                <span
                                  style={{ fontSize: "15px", color: "#202034" }}
                                >
                                  {row.comments}
                                </span>
                              }
                              placement="top-end">
                              <Typography
                                noWrap
                                style={{ color: "black", width: 100 }} >
                                {row.comments ? row.comments : "--"}
                              </Typography>
                            </Tooltip>
                          </TableCell>
                          {/* <TableCell
                            className={classes.tableCellCls}
                            style={{ textAlign: "left", paddingLeft: 15 }} >
                            {row.vendorName ? row.vendorName : "--"}
                          </TableCell> */}
                          <TableCell
                            className={classes.tableCellCls}
                            style={{ textAlign: "left", paddingLeft: 15 }} >
                            {row.clientName ? row.clientName : "--"}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              className={classes.tablePaginationCls}
            />
          </div>
        ) : (
          <div style={{ textAlign: "center" }}>
            {toggleNull ? (
              <div>
              <TableContainer style={{ width: "100%", maxHeight: 440 }}>
              <Table
                stickyHeader
                className={classes.table}
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                </Table>
          <p >No data to display</p>
          </TableContainer>
           </div>
             ) : (
              <Spinner />
            )}
          </div>
        )}
      </div>
        <div className={classes.exportBtnCls}>
        <Button
          variant="contained"
          onClick={exportHandler}
          className={classes.exportBtn}
        >
          <img
            src="/assets/icons/newfile.svg"
            alt="Export"
            width="30px"
            height="30px"
            style={{ marginLeft: 0 }}
          />
          Export
        </Button>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={PopUpState}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={PopUpState}>
          <div className={classes.paper}>
            <div>
              <IconButton
                aria-label="close"
                style={{
                  float: "right",
                  fontSize: "30px",
                  marginRight: "-45px",
                  marginTop: "-19px",
                }}
                onClick={handleClose}>
                <CloseIcon />
              </IconButton>
              <h3>{firstname}</h3>
            </div>
            <MainTable tableHead={viewPopupHeading} tableData={employeeData} />
          </div>
        </Fade>
      </Modal>
      <div></div>
    </div>
  );
}
