import React, { Component } from "react";
import TimeSheetServices from "../../services/TimeSheetServices";
import BackgroundCard from "../uiComponents/BackgroundCard";
import Grid from "@material-ui/core/Grid";
import "bootstrap/dist/css/bootstrap.min.css";
import MainTable from "../uiComponents/ViewTimeSheetTable";
import moment from "moment";
import AddIcon from "@material-ui/icons/Add";
import "../../assets/css/ViewTimeSheetStyles.css";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import {
  NewViewTimeSheetDropDown,
  NewViewTimeSheetDropDown_Approver,
} from "../../assets/data/dropDownOptions";
import {
  viewTimesheetRowHaddings,
  approverViewTimesheetRowHaddings,
} from "../../assets/data/viewTimesheetData";
import Spinner from "../uiComponents/Spinner";
import Toolbar from "@material-ui/core/Toolbar";
import clsx from "clsx";
import { lighten, withStyles } from "@material-ui/core/styles";
import Select from "react-select";
import Button from "@material-ui/core/Button";
import theme from '../../assets/jss/theme';
import EventIcon from '@mui/icons-material/Event';

const useStyles = (theme) => ({
  root: {
    paddingLeft: 0,
    paddingRight: theme.spacing(1),
    // marginBottom: 10,
  },
  highlight:
    theme.palette.type === "light"
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: "1 1 100%",
  },
  Select: {
    width: 156,
    marginLeft: 20,
    fontSize: 15,
  },
  searchName: {
    width: 215,
    height: 30,
    paddingLeft: 30,
    border: "1px solid #DCDCDD",
    borderRadius: 4,
    fontSize: 15,
  },
  searchIcon: {
    width: 16,
    height: 20,
    position: "absolute",
    left: 7,
  },
  calendarIcon: {
    width: 16,
    height: 20,
    position: "absolute",
    left: 243,
  },
  resetBtn: {
    marginLeft: 20,
    background: "#54586D",
    height: 30,
  },
  createTimeSheetBtn: {
    marginLeft: "auto",
    background: "#00B863",
    height: 30,
    fontSize: 15,
    textTransform: "none",
    float: "right",
    borderRadius: 29,
    padding: "7px 20px",
    outline: "none",
    cursor: "pointer",
    color: theme.palette.common.white,
    "&:focus": {
      outline: "none",
      WebkitBoxShadow: "-12px 2px 26px -11px rgba(52,191,54,1)",
      MozBoxShadow: "-12px 2px 26px -11px rgba(52,191,54,1)",
      boxShadow: "-12px 2px 26px -11px rgba(52,191,54,1)",
    },
    "&:hover": {
      backgroundColor: "#00B863",
      WebkitBoxShadow: "-12px 2px 26px -11px rgba(52,191,54,1)",
      MozBoxShadow: "-12px 2px 26px -11px rgba(52,191,54,1)",
      boxShadow: "-12px 2px 26px -11px rgba(52,191,54,1)",
    },
  },
  addIcon: {
    height: 23,
    width: 23,
    marginLeft: -20,
    paddingLeft: "3px",
  }
});
const customStyles = {
  control: (base) => ({
    ...base,
    height: 30,
    minHeight: 30,
  }),
  placeholder: (state) => ({
    color: "grey",
    position: "absolute",
    top: 4,
  }),
  indicatorsContainer: (state) => ({
    position: "absolute",
    bottom: -4,
    right: 0,
  }),
  singleValue: (state) => ({
    position: "absolute",
    top: 4,
  }),
};
class ViewTimeSheet extends Component {
  constructor(props) {
    super(props);
    this.clearDateElement = React.createRef();
    this.storeEmpDat = [];
    this.searchValue = "";
    this.typeValue = "";
    this.state = {
      employeeData: [],
      dataForFiltering: [],
      searchName: "",
      defaultValue: "",
      show: false,
      showImage: false,
      status: null,
      startDate: null,
      endDate: null,
      noData: false,
      defaultDateInputs: false,
      loginDetails: JSON.parse(localStorage.getItem("login_responce_data")),
    };
    theme.overrides.MuiTable.root.borderSpacing = '1px 11px';
  }
  dateDefault = () => {
    const store = sessionStorage.getItem("filterDates");
    const { startDate, endDate } = JSON.parse(store);
    return {
      autoUpdateInput: true,
      startDate: moment(startDate).format("MM-DD-YYYY"),
      endDate: moment(endDate).format("MM-DD-YYYY"),
      locale: {
        cancelLabel: "Clear",
        firstDay: 6,
        format: "MM-DD-YYYY"
      },
    }
  }
  resetOptions = () => {
    sessionStorage.clear();
    document.getElementById("clearDateRanges").value = "";
    if (this.state.loginDetails.userDetails.userType !== 2) {
      this.setState({ status: { label: "Submitted" } });
      this.setState({ status: { label: "Submitted",value : 'S' } });
    }
    this.setState({ defaultValue: "" })
    this.setState({ searchName: "" });

    this.getTimesheetDetails();
  };



  componentDidMount() {
    window.onunload = function () {
      sessionStorage.removeItem('searchFilteredValue');
    }
    const pathData = this.props.location.state;
    if (this.state.loginDetails.userDetails.userType !== 2) {
      this.setState({
        status: { id: "Status", label: "Submitted", value: "S" },
      });
    }
    if (pathData?.keepDates && sessionStorage.getItem("filterDates")) {
      return this.handleSessionStorage();
    } else {
      sessionStorage.removeItem('filterDates')
      this.getTimesheetDetails();
    }
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });
  }
  getTimesheetDetails = async () => {
    let empDetails = [];
    let orgId = this.state.loginDetails.userDetails.orgId;
    let userId = this.state.loginDetails.userDetails.userId;
    this.setState({ show: true, noData: false });
    await TimeSheetServices.viewEmployeeTimeSheet(orgId, userId).then((response) => {
      if (response.data["responseCode"] === 200) {
        empDetails = response.data.viewTimesheetList;
        if (response.data.viewTimesheetList.length !== 0) {
          let timesheestData;
          if (this.state.loginDetails.userDetails.userType !== 2) {
            timesheestData = response.data.viewTimesheetList.filter(
              (item) => item.reviewerStatus === "S"
            );
          } else {
            timesheestData = response.data.viewTimesheetList;
          }
          timesheestData.isFrom = "viewTimesheets";
       

          this.setState({
            employeeData: timesheestData,
            dataForFiltering: response.data.viewTimesheetList,
            startDate: null,
            endDate: null,
            show: false,
            noData: false,
          });

          if (sessionStorage?.getItem('searchFilteredValue') !== null) {
            
            this.getRowsBasedonName(
              sessionStorage?.getItem('searchFilteredValue'),
              this.state.status?.value
            );
          }
          

          this.state.employeeData?.length
            ? this.setState({ noData: false })
            : this.setState({ noData: true });
          return this.state.employeeData;
        }
      } else {
        empDetails = [];
        this.setState({ showImage: true, show: false, noData: true });
        this.setState({ employeeData: [] });
      }
      if (
        response.data.viewTimesheetList !== undefined &&
        response.data.viewTimesheetList.length === 0
      ) {
        this.setState({ showImage: true, show: false, noData: true });
      }
    }).catch((error) => { });
  };

  handleSessionStorage = () => {
    const store = sessionStorage.getItem("filterDates");
    const { startDate, endDate } = JSON.parse(store);
    return this.sortByDate(startDate, endDate);
  };

  getDatePicker = (event, picker) => {
    sessionStorage.clear();
    // picker.element.val(
    //   picker.startDate.format("MM-DD-YYYY") +
    //   " - " +
    //   picker.endDate.format("MM-DD-YYYY")
    // );
    let startDate = picker.startDate.format("YYYY-MM-DD"); //sending date in this format to backend for api call
    let endDate = picker.endDate.format("YYYY-MM-DD");
    // setDefaultValue(`${picker.startDate.format("MM-DD-YYYY")} - ${picker.endDate.format("MM-DD-YYYY")}`);
    this.setState({ defaultValue: `${picker.startDate.format("MM-DD-YYYY")} - ${picker.endDate.format("MM-DD-YYYY")}` })
    const store = JSON.stringify({ startDate, endDate });
    sessionStorage.setItem("filterDates", store);
    return this.handleSessionStorage();
  };

  sortByDate = async (start, end) => {
    //*Old code
    let logi_data = JSON.parse(localStorage.getItem("login_responce_data"));
    let orgId = logi_data.userDetails.orgId;
    let userId = logi_data.userDetails.userId;
    this.setState({ show: true, noData: false, status: null, });
    await TimeSheetServices.getTimesheet_by_date(orgId, userId, start, end).then((res) => {
      this.setState({
        employeeData: res.data.viewTimesheetList,
        dataForFiltering: res.data.viewTimesheetList,
        show: false,
      });
      this.getRowsBasedonName(
        this.searchValue,
        this.state.status?.value
      );
    }).catch((err) => {

    });
    this.state.employeeData?.length
      ? this.setState({ noData: false })
      : this.setState({ noData: true });
  };
  getRowsBasedonName = async (searchValue, typeValue) => {
    let filterData;
    this.searchValue = searchValue;
    this.typeValue = typeValue;
    if (this.typeValue) {
      filterData = this.state.dataForFiltering.filter((item) => {
        let x =
          item.firsName
            .toUpperCase()
            .startsWith(searchValue.toString().toUpperCase()) ||
          item.lastName
            .toUpperCase()
            .startsWith(searchValue.toString().toUpperCase());
        return (
          x &&
          (this.typeValue === "All"
            ? true
            : item.reviewerStatus === this.typeValue)
        );
      });
      await this.setState({ employeeData: filterData });
    } else {
      filterData = this.state.dataForFiltering?.filter(
        (item) =>
          item.firsName
            .toUpperCase()
            .startsWith(searchValue.toString().toUpperCase()) ||
          item.lastName
            .toUpperCase()
            .startsWith(searchValue.toString().toUpperCase())
      );
      if (
        searchValue !== "" &&
        searchValue !== undefined &&
        searchValue !== null
      ) {
        this.setState({ employeeData: [] });
        await this.setState({ employeeData: filterData });
      } else {
        await this.setState({ employeeData: this.state.dataForFiltering });
      }
    }
    this.state.employeeData?.length
      ? this.setState({ noData: false })
      : this.setState({ noData: true });
  };
  render() {
    const { classes } = this.props;
    const user = this.state.loginDetails.userDetails.userType;
    return (
      <BackgroundCard title="View Timesheets" iconName="viewTimeSheet">
        <Toolbar className={clsx(classes.root)}>
          {(user === 2 || user === 5) ? null : (
            <>
              <input
                type="text"
                placeholder="First Name/Last Name"
                value={sessionStorage?.getItem('searchFilteredValue') !== null ?sessionStorage?.getItem('searchFilteredValue') : this.state.searchName }
                onChange={(e) => {
                  this.setState({ searchName: e.target.value.replace(/[^a-zA-Z]/g, '') });
                  sessionStorage.setItem('searchFilteredValue', e.target.value.replace(/[^a-zA-Z]/g, ''))
                  this.getRowsBasedonName(
                    sessionStorage?.getItem('searchFilteredValue'),
                    this.state.status?.value
                  );
                }}
                className={classes.searchName}
              />
              <img
                src="/assets/icons/search.svg"
                alt="Search"
                className={classes.searchIcon}
              />
            </>
          )}
          <DateRangePicker
            id="dtPicker"
            initialSettings={this.props.location.state?.keepDates && sessionStorage.getItem("filterDates") ? this.dateDefault() : {
              autoUpdateInput: false,
              locale: {
                cancelLabel: "Clear",
                firstDay: 6,
                format: "MM-DD-YYYY"
              },
            }}
            onApply={this.getDatePicker}
            onCancel={(event, picker) => {
              sessionStorage.clear();
              picker.setStartDate(new Date());
              picker.setEndDate(new Date());
              this.setState({ defaultValue: "" });
              if (this.state.loginDetails.userDetails.userType !== 2) {
                this.setState({ status: { label: "Submitted" } });
              }
              this.setState({ searchName: "" });
              this.getTimesheetDetails();
            }}
          >
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", position: "relative", width: "max-content" }}>
              {/* <img
                src="/assets/icons/calendar.svg"
                alt="Calendar"
                className={user === 2 ? classes.searchIcon : classes.calendarIcon}
              /> */}
              <EventIcon style={{
                position: "absolute",
                left: user === 2 ? "7px" : "26px",
                bottom: "6px",
                fontSize: "17px",
                color: "#797876"
              }} />
              <input
                id="clearDateRanges"
                type="text"
                placeholder="Start Date - End Date"
                // defaultValue=""
                style={
                  user === 2
                    ? { width: "198px" }
                    : { width: "198px", marginLeft: 20 }
                }
                className={classes.searchName}
                readOnly={true}
                value={this.state.defaultValue}
              />
            </div >
          </DateRangePicker>

          <Select
            value={this.state.status}
            onChange={(e) => {
              this.setState({ status: e });
              this.getRowsBasedonName(this.state.searchName, e.value);
            }}
            options={
              (user === 2 || user === 5)
                ? NewViewTimeSheetDropDown
                : NewViewTimeSheetDropDown_Approver
            }
            placeholder="Status"
            className={classes.Select}
            styles={customStyles}
          />
          {!(this.state.searchName) ? "" :
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.resetOptions()}
              className={classes.resetBtn}
            >
              Reset
            </Button>}
          {(user === 2 || user === 5) ?
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                localStorage.removeItem("table_data")
                this.props.history.push("/Timesheeets/createtimesheet");
              }}
              className={classes.createTimeSheetBtn}
            >
              <AddIcon className={classes.addIcon} />
              Enter Timesheets
            </Button> : null}
        </Toolbar>
        {this.state.show === true ? (
          <Spinner show={this.state.show} />
        ) : (
          <Grid item xs={12} sm={12}>
            <MainTable
              tableHead={
                (user === 2 || user === 5)
                  ? viewTimesheetRowHaddings
                  : approverViewTimesheetRowHaddings
              }
              tableData={this.state.employeeData}
            />
          </Grid>
        )}
      </BackgroundCard>
    );
  }
}
export default withStyles(useStyles)(ViewTimeSheet);
