import React, { useState, useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  OutlinedInput,
  TextareaAutosize,
  Grid,
  Typography,
} from "@material-ui/core";
import TimeSheetServices from "../../services/TimeSheetServices";
import { toastOptions } from "../../ToastOptions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../App.css";
import styles from "../../assets/jss/createTableStyles.js";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import BackgroundCard from "../uiComponents/BackgroundCard";
import Spinner from "../uiComponents/Spinner";
import CustomButton from "../uiComponents/Button";
import moment from "moment";
import globalValues from "../../GlobalVariables";
import { useHistory } from "react-router-dom";
import Loader from "../uiComponents/Loader";
import profile from "../../assets/img/profile.png";
import theme from '../../assets/jss/theme';
import html2canvas from 'html2canvas';
import domtoimage from 'dom-to-image';


const USER_API_BASE_URL = globalValues.FILE_API_BASE_URL_CASTUS;
const useStyles = makeStyles(styles);
toast.configure();
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.action.hover,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({}))(TableRow);
export default function ApproverTimesheet(props) {
  let history = useHistory();
  const classes = useStyles();
  const [totalRegularHr, setTotalRegularHr] = useState(0);
  const [totalOtHr, setTotalOtHr] = useState(0);
  const [_totalhours, set_TotalHours] = useState(0);
  const [items, setItems] = useState([]);
  const [attachmentsArray, setAttachmentsArray] = useState([]);
  const [fobject, setFobject] = useState([]);
  const [remarks, setRemarks] = useState("");
  const [approverRemarks, setApproverRemarks] = useState("");
  const [attachmentFile, setAttachmentFile] = useState([]);
  const [disable, setDisable] = useState(false);
  const [disableTextArea, setDisableTextArea] = useState(false);
  const [employee_data, setEmployee_data] = React.useState(
    JSON.parse(localStorage.getItem("table_data"))
  );
  const userLocalData = JSON.parse(localStorage.getItem('login_responce_data'))
  const userType = userLocalData.userDetails.userType
  const [totalfilesss, setTotalFiles] = useState(
    attachmentFile.length + attachmentsArray.length
  );
  const [loader, setLoader] = React.useState(false);
  const twoValueAfterDecimal = /^-?\d*[.,]?\d{0,2}$/;
  const rejectStatus = "R";
  const approveStatus = "A";
  const submitStatus = "S";

  useEffect(() => {
    theme.overrides.MuiTable.root.borderSpacing = '0px 11px';
    if (employee_data !== null) {
      let userid = employee_data.userId;
      let orgid = employee_data.orgId;
      if (employee_data.isFrom === "ApproverTableRows") {
        getTimeSheetData(orgid, userid, employee_data.startDate.split("T")[0]);
        setDisable(true)
      } else if (employee_data.isFrom === "AdminTableRows") {
        getTimeSheetData(orgid, userid, employee_data.startDate.split("T")[0]);
        setDisable(false)
      } else if (employee_data.isFrom === "ViewSheet") {
        getTimeSheetData(orgid, userid, employee_data.startDate.split("T")[0])
        setDisableTextArea(true)
        setDisable(true)
      }
    } else {
    }
  }, []);
  //Get the user week sheet data
  const getTimeSheetData = (orgId, userid, current_date) => {
    TimeSheetServices.get_timesheet(orgId, userid, current_date).then(
      (result) => {
        if (result.data.responseCode === 200) {
          setTotalRegularHr(result.data.timesheetDetails[0].totalRegularHours);
          setTotalOtHr(result.data.timesheetDetails[0].totalOtHours);
          set_TotalHours(result.data.timesheetDetails[0].totalWeekhours);
          let i;
          for (
            i = 0;
            i < result.data.timesheetDetails[1].timeSheetDetails.length;
            i++
          ) {
            result.data.timesheetDetails[1].timeSheetDetails[i].rowHours =
              Number(
                result.data.timesheetDetails[1].timeSheetDetails[i].regularHours
              ) +
              Number(
                result.data.timesheetDetails[1].timeSheetDetails[i].otHours
              );
            result.data.timesheetDetails[1].timeSheetDetails[i].rowHours = Number(result.data.timesheetDetails[1].timeSheetDetails[i].rowHours).toFixed(2);
            if (result.data.timesheetDetails[1].timeSheetDetails[i].description) {
              result.data.timesheetDetails[1].timeSheetDetails[i].description = result.data.timesheetDetails[1].timeSheetDetails[i].description.trim().replace(/\s+/g, ' ');
            }
          }
          if (result.data.timesheetDetails[1].attachments.length !== 0) {
            let attachmentsArray = result.data.timesheetDetails[1].attachments.map(
              (file) => {
                return file;
              }
            );
            setAttachmentsArray(attachmentsArray);
          }
          setItems(result.data.timesheetDetails[1].timeSheetDetails);
          if (result.data.timesheetDetails[0].vendorName !== null &&
            result.data.timesheetDetails[0].vendorName !== undefined
          ) {
            result.data.timesheetDetails[0].vendorName = result.data.timesheetDetails[0].vendorName
          }
          setFobject(result.data.timesheetDetails[0]);
          setRemarks(result.data.timesheetDetails[0].remark);
          setApproverRemarks(result.data.timesheetDetails[0].approverRemark);
        } else {
          toast.error("Something went wrong!", toastOptions);
        }
      }
    );
  };
  //change event for all inputs
  const inputFieldsHandler = (e, inputIndex, name) => {
    let totalHours = 0;
    let total_regular_hours = 0;
    let total_ot_hours = 0;
    const dublicateArray = [...items];
    dublicateArray.forEach((each, index) => {
      if (index === inputIndex) {
        each[name] = e.target.value;
        if (name === "regularHours" || name === "otHours") {
          each.rowHours = Number(each.regularHours) + Number(each.otHours);
          each.rowHours = Number(each.rowHours).toFixed(2).replace(/(\.[0-9]*[1-9])0+$|\.0*$/, '$1');
        }
      }
      total_regular_hours = Number(each.regularHours) + total_regular_hours;
      total_ot_hours = Number(each.otHours) + total_ot_hours;
      totalHours = Number(each.rowHours) + totalHours;
    });
    setItems(dublicateArray);
    const total = dublicateArray.reduce((currentTotal, item) => {
      return (Number(item.regularHours) + Number(currentTotal)).toFixed(2).replace(/(\.[0-9]*[1-9])0+$|\.0*$/, '$1');
    }, 0);
    employee_data.regularHours = total;

    setEmployee_data(employee_data);
    const Ottotal = dublicateArray.reduce((currentTotal, item) => {
      return (Number(item.otHours) + Number(currentTotal)).toFixed(2).replace(/(\.[0-9]*[1-9])0+$|\.0*$/, '$1');
    }, 0);
    employee_data.otHours = Ottotal;
    setTotalRegularHr(total_regular_hours.toFixed(2).replace(/(\.[0-9]*[1-9])0+$|\.0*$/, '$1'))
    setTotalOtHr(total_ot_hours.toFixed(2).replace(/(\.[0-9]*[1-9])0+$|\.0*$/, '$1'))
    setEmployee_data(employee_data);
    set_TotalHours(totalHours.toFixed(2).replace(/(\.[0-9]*[1-9])0+$|\.0*$/, '$1'));
  }
  const rowChangeHandler = (e, inputIndex, name) => {
    if (twoValueAfterDecimal.test(e.target.value) && e.target.value >= 0 && (e.target.value === "" || (e.target.value) <= 24) && name !== "description") {
      inputFieldsHandler(e, inputIndex, name);
    } else if (name === "description") {
      inputFieldsHandler(e, inputIndex, name);
    }
  };
  const getTimeSheetDetails = () => {
    let result = [];
    items.forEach((data) => {
      let inputobject = {};
      inputobject.timesheetDate = data.timesheetDate;
      inputobject.regularHours =
        data.regularHours !== "" ? data.regularHours : null;
      inputobject.otHours = data.otHours !== "" ? data.otHours : null;
      inputobject.description = data.description;
      result.push(inputobject);
    });
    return result;
  };
  // For save employee timesheets
  const saveFun = () => {
    const request = new FormData();
    let timesheetData = {
      orgId: employee_data.orgId,
      userId: employee_data.userId,
      userType: userType,
      startDate: fobject.startDate,
      endDate: fobject.endDate,
      remark: remarks,
      approverRemark: approverRemarks,
      timesheetId: fobject.timesheetId,
      totalWeekhours: _totalhours,
      totalRegularHours: totalRegularHr,
      totalOtHours: totalOtHr,
      reviewerStatus:
        fobject.reviewerStatus === "S"
          ? submitStatus
          : fobject.reviewerStatus === "A"
            ? approveStatus
            : rejectStatus,
      timeSheetDetails: getTimeSheetDetails(),
    };
    request.append("timsheetData", JSON.stringify(timesheetData));
    if (attachmentFile !== null && attachmentFile !== undefined) {
      for (let i = 0; i < attachmentFile.length; i++) {
        request.append("attachments", attachmentFile[i]);
      }
    }

    domtoimage.toBlob(document.querySelector('.create-timesheet-wrapper'))
      .then(function (blob) {
        console.log(blob);
        const file = new File([blob], "png", { type: "image/png" })
        request.append("screen_attachment", file);
      });


    // html2canvas(document.querySelector(".create-timesheet-wrapper"), {
    //   allowTaint: true,
    //   width: 1460,
    //   height: 990,

    // }).then(canvas => {

    //   let base64URL = canvas.toDataURL();

    //   const url = base64URL;
    //   fetch(url)
    //     .then(res => res.blob())
    //     .then(blob => {
    //       const file = new File([blob], "png", { type: "image/png" })
    //       // setTimesheetScreenshot(file)
    //     })
    // });
    // let keys = Object.fromEntries(request);

    setLoader(!loader);
    setTimeout(() => {
      TimeSheetServices.create_timesheet(request)
        .then((res) => {
          if (res.data.responseCode === 201) {
            setLoader(false);
            toast.success("Updated Successfully", toastOptions);
            history.push("/Timesheeets/timesheets");
          } else {
            setLoader(false);
            toast.error(res.data.details, toastOptions);
          }
        })
        .catch((err) => {
          setLoader(false);
          toast.error(err, toastOptions);
        });
    }, 2000);
  };
  let attachedFiles = [];
  const handleChangeStatus = ({ meta, file }, status, files) => {
    if (status === "done") {
      files.map(function (file) {
        setAttachmentFile(file.file);
        attachedFiles.push(file.file);
      });
      setAttachmentFile(attachedFiles);
    } else if (status === "removed") {
      const attachedFiles = [];
      files.map(function (f) {
        if (f.file.name !== file.name) {
          setAttachmentFile(file.file);
          attachedFiles.push(f.file);
        }
      });
      setAttachmentFile(attachedFiles);
    } else if (status === "aborted") {
    }
  };
  const form_validate = () => {
    let totalfiles = attachmentFile.length + attachmentsArray.length;
    if (fobject.isAttchamentRequired === false) {
      if (
        (attachmentFile === null ||
          attachmentFile === undefined ||
          attachmentFile.length === 0) &&
        (attachmentsArray === null ||
          attachmentsArray === undefined ||
          attachmentsArray.length === 0)
      ) {
        toast.error("Select atleast one Attachment file ", toastOptions);
        return false;
      } else {
        return true;
      }
    }
    if (totalfiles > 3) {
      toast.error("You can only upload a maximum of 3 files!", toastOptions);
    } else {
      return true;
    }
  };



  //  Aprove employee timesheets fncs
  const approveFun = () => {
    if (form_validate()) {
      const request = new FormData();
      let timesheetData = {
        orgId: employee_data.orgId,
        userId: employee_data.userId,
        userType: userType,
        startDate: fobject.startDate,
        endDate: fobject.endDate,
        remark: remarks,
        approverRemark: approverRemarks,
        timesheetId: fobject.timesheetId,
        totalWeekhours: _totalhours,
        totalRegularHours: totalRegularHr,
        totalOtHours: totalOtHr,
        reviewerStatus: approveStatus,
        approvedDate: moment(new Date()).format("YYYY-MM-DD"),
        timeSheetDetails: getTimeSheetDetails(),
      };
      request.append("timsheetData", JSON.stringify(timesheetData));
      if (attachmentFile !== null && attachmentFile !== undefined) {
        for (let i = 0; i < attachmentFile.length; i++) {
          request.append("attachments", attachmentFile[i]);
        }
        domtoimage.toBlob(document.querySelector('.create-timesheet-wrapper'))
          .then(function (blob) {
            console.log(blob);
            const file = new File([blob], "png", { type: "image/png" })
            request.append("screen_attachment", file);
          });


        // html2canvas(document.querySelector(".create-timesheet-wrapper"), {
        //   allowTaint: true,
        //   width: document.querySelector(".create-timesheet-wrapper").getBoundingClientRect().width,
        //   height: document.querySelector(".create-timesheet-wrapper").getBoundingClientRect().height
        // }).then(canvas => {

        //   let base64URL = canvas.toDataURL();

        //   const url = base64URL;
        //   fetch(url)
        //     .then(res => res.blob())
        //     .then(blob => {
        //       const file = new File([blob], "png", { type: "image/png" })
        //       // setTimesheetScreenshot(file)
        //       request.append("screen_attachment", file);
        //     })
        // });
        // let keys = Object.fromEntries(request);
      }

      setLoader(!loader);
      setTimeout(() => {
        TimeSheetServices.create_timesheet(request)
          .then((res) => {
            if (res.data.responseCode === 201) {
              setLoader(false);
              toast.success("Approved Successfully", toastOptions);
              history.push("/Timesheeets/timesheets", { keepDates: true });
              document.body.style.zoom = '100%';

            } else {
              setLoader(false);
              toast.error(res.data.details, toastOptions);
              document.body.style.zoom = '100%';

            }
          })
          .catch((err) => {
            setLoader(false);
            toast.error(err, toastOptions);
            document.body.style.zoom = '100%';

          });
      }, 2000);
    }
  };
  const cancelFun = () => {
    history.push("/Timesheeets/timesheets", { keepDates: true });
  };
  const remarkChamgedHandler = (e) => {
    let val = e.target.value;
    setFobject((prevState) => ({
      ...prevState,
      approverRemark: val,
    }));
    setApproverRemarks(val);
  };
  //Reject timesheets
  const reject_fun = () => {
    const request = new FormData();
    let timesheetData = {
      orgId: employee_data.orgId,
      userId: employee_data.userId,
      userType: userType,
      timesheetId: fobject.timesheetId,
      startDate: fobject.startDate,
      endDate: fobject.endDate,
      remark: remarks,
      approverRemark: approverRemarks,
      reviewerStatus: rejectStatus,
      totalWeekhours: _totalhours,
      totalRegularHours: totalRegularHr,
      totalOtHours: totalOtHr,
      timeSheetDetails: getTimeSheetDetails(),
    };
    request.append("timsheetData", JSON.stringify(timesheetData));
    if (attachmentFile !== null && attachmentFile !== undefined) {
      for (let i = 0; i < attachmentFile.length; i++) {
        request.append("attachments", attachmentFile[i]);
      }

      domtoimage.toBlob(document.querySelector('.create-timesheet-wrapper'))
        .then(function (blob) {
          console.log(blob);
          const file = new File([blob], "png", { type: "image/png" })
          request.append("screen_attachment", file);
        });


      // document.body.style.zoom = '80%';

      // html2canvas(document.querySelector(".create-timesheet-wrapper"), {
      //   allowTaint: true,
      //   width: document.querySelector(".create-timesheet-wrapper").getBoundingClientRect().width,
      //   height: document.querySelector(".create-timesheet-wrapper").getBoundingClientRect().height
      // }).then(canvas => {

      //   let base64URL = canvas.toDataURL();

      //   const url = base64URL;
      //   fetch(url)
      //     .then(res => res.blob())
      //     .then(blob => {
      //       const file = new File([blob], "png", { type: "image/png" })
      //       // setTimesheetScreenshot(file)
      //       request.append("screen_attachment", file);
      //     })
      // });
      // let keys = Object.fromEntries(request);

    }

    setLoader(!loader);
    setTimeout(() => {
      TimeSheetServices.create_timesheet(request)
        .then((res) => {
          if (res.data.responseCode === 201) {
            setLoader(false);
            toast.success("Rejected Successfully", toastOptions);
            history.push("/Timesheeets/timesheets", { keepDates: true });
            document.body.style.zoom = '100%';

          } else {
            setLoader(false);
            toast.error(res.data.details, toastOptions);
            document.body.style.zoom = '100%';

          }
        })
        .catch((err) => {
          setLoader(false);
          toast.error(err, toastOptions);
          document.body.style.zoom = '100%';

        });
    }, 2000);
  };
  return (
    <div className="create-timesheet-wrapper">
      <BackgroundCard
        title={`${"Review Timesheet"} ${fobject.startDate !== undefined
          ? moment(fobject.startDate).format("MM-DD-YYYY") + " to"
          : ""
          }      ${fobject.endDate !== undefined
            ? moment(fobject.endDate).format("MM-DD-YYYY")
            : ""
          }`}
        iconName="createTimeSheet"
        closeIcon="closeIcon" closeClick={cancelFun}
      >
        {items.length !== 0 ? (
          <div>
            <div className={classes.five} style={{ paddingBottom: 0 }}>
              <div className="ui segment" className={classes.six}>
                <div className={classes.seven}>
                  <img src={profile} alt="prof pic" className={classes.eight} />
                  <div style={{ display: 'flex' }}>
                    <span style={{ marginRight: '8px' }}>{`${employee_data.firsName}`}</span>
                    <span>{employee_data.lastName}</span>
                  </div>
                </div>
              </div>
              <div
                className="ui segment"
                className={classes.divBoxs}
                style={{ height: 'auto' }}
              >
                <div
                  className={classes.divBox}
                >
                  <span className={classes.spanStyle}>
                    Vendor Name:{fobject.vendorName}
                  </span>
                  <span className={classes.spanStyle}>
                    Status:{" "}
                    {fobject.reviewerStatus === "A" ? (
                      <span className={classes.status}>Approved</span>
                    ) : fobject.reviewerStatus === "O" ? (
                      <span className={classes.status}>Open</span>
                    ) : fobject.reviewerStatus === "R" ? (
                      <span className={classes.status}>Rejected</span>
                    ) : fobject.reviewerStatus === "S" ? (
                      <span className={classes.status}>Submitted</span>
                    ) : (
                      ""
                    )}
                  </span>
                  <span className={classes.spanStyle}>
                    Submitted Date:{" "}
                    {moment(employee_data.submittedDate?.split("T")[0]).format(
                      "MM-DD-YYYY"
                    )}
                  </span>
                </div>
                <div className={classes.nine}>
                  <span className={classes.spanStyle}>
                    Regular Hours:{employee_data.regularHours}{" "}
                  </span>
                  <span className={classes.spanStyle}>
                    OT Hours:{employee_data.otHours}{" "}
                  </span>
                  <span className={classes.spanStyle}>
                    Total Hours: {_totalhours}{" "}
                  </span>
                </div>
                <div className={classes.ten}>
                  <span className={classes.spanStyleRemark}>
                    Remarks: {fobject.remark}
                  </span>
                </div>
              </div>
            </div>
            <div>
              <Table aria-label="customized table" className="tablerow">
                <TableHead className="tablehead">
                  <TableRow style={{ background: "#fafafa" }}>
                    <TableCell className={classes.tableHeadCells}>
                      Day
                    </TableCell>
                    <TableCell className={classes.tableHeadCells}>
                      Regular Hours
                    </TableCell>
                    {fobject.otEnable === true ? (
                      <TableCell className={classes.tableHeadCells}>
                        OT Hours
                      </TableCell>
                    ) : (
                      ""
                    )}
                    <TableCell className={classes.tableHeadCells}>
                      Description
                    </TableCell>
                    <TableCell className={classes.tableHeadCells}>
                      Total Hours
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="approver-table-body">
                  {items.map((item, index) => {
                    return (
                      <StyledTableRow
                        key={item.id}
                        style={{
                          background:
                            item.weekDay === "Sunday" ||
                              item.weekDay === "Saturday" ||
                              item.holiday === true
                              ? "#B4B6DB"
                              : "",
                        }}
                      >
                        <StyledTableCell>
                          <div>
                            <p className={classes.weekDay}>{item.weekDay}</p>
                            <p className={classes.timesheetDate}>
                              {moment(item.timesheetDate).format("MM-DD-YYYY")}
                            </p>
                          </div>
                        </StyledTableCell>
                        <StyledTableCell>
                          <OutlinedInput
                            className={classes.inputWidth}
                            name="regularHours"
                            value={
                              item.regularHours === null
                                ? ""
                                : item.regularHours
                            }
                            autoComplete="off"
                            type="text"
                            inputProps={{ maxLength: 5 }}
                            disabled={disable}
                            onChange={(e) =>
                              rowChangeHandler(e, index, "regularHours")
                            }
                          />
                        </StyledTableCell>
                        {fobject.otEnable === true ? (
                          <StyledTableCell>
                            <OutlinedInput
                              className={classes.inputWidth}
                              name="otHours"

                              value={item.otHours === null ? "" : item.otHours}
                              type="text"
                              autoComplete="off"
                              pattern="[0-9]*"
                              disabled={disable}
                              inputProps={{ maxLength: 5 }}
                              onChange={(e) =>
                                rowChangeHandler(e, index, "otHours")
                              }
                            />
                          </StyledTableCell>
                        ) : (
                          ""
                        )}
                        <StyledTableCell>
                          <OutlinedInput
                            className={classes.descWidth}
                            name="description"
                            value={
                              item.description === null ? "" : item.description
                            }
                            autoComplete="off"
                            type="text"
                            disabled={disable}
                            inputProps={{ maxLength: 50 }}
                            onChange={(e) =>
                              rowChangeHandler(e, index, "description")
                            }
                          />
                        </StyledTableCell>
                        <StyledTableCell>{item.rowHours}</StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
            <div
              className={classes.tableAttachments}>
              <div className={classes.attachbackgroundColor}>
                <AttachFileIcon
                  className={classes.attachedIcon}
                />
              </div>
              <Typography variant="h6" className={classes.attachedText} >
                Attachments
              </Typography>
            </div>
            {(employee_data.isFrom === "ApproverTableRows")
              && totalfilesss < 3
              ?
              <Dropzone
                maxFiles={3 - totalfilesss}
                onChangeStatus={handleChangeStatus}
                accept="image/*,.pdf,.csv,.xlsx,.docx"
                validate={(file) => {
                  if (file.meta.size > new Number(2 * 1024 * 1000)) {
                    toast.error("File size shouldn't exceed 2MB", toastOptions);
                    file.remove();
                    return true; //file status is validation error
                  } else {
                    return false; //file, status is accepted
                  }
                }}
              />
              : ""}
            <Grid
              container
              direction="row"
              alignitems="center"
              justify="flex-start"
              className={`${classes.root} ${classes.imageContainer}`}
            >
              {attachmentsArray.map((file) => (
                <Grid
                  item
                  xs={4}
                  className={classes.attachmentFileCss}
                >
                  <a
                    href={`${USER_API_BASE_URL}${file.attachments}`}
                    target="_blank"
                  >
                    {file.fileName?.length > 29
                      ? file.fileName.slice(0, 30) + "..."
                      : file.fileName}
                  </a>
                </Grid>
              ))}
            </Grid>
            <Grid container direction="row" className={classes.attach}>
              <Grid
                container
                direction="row"
                xs={12}
                sm={8}
                md={8}
                lg={8}
                xl={8}
              >
                <Grid item>
                  <Typography variant="h6">Comments</Typography>
                  <TextareaAutosize
                    style={{ width: 300, height: 100 }}
                    name="approverRemark"
                    id="approverRemark"
                    value={
                      fobject.approverRemark === null
                        ? ""
                        : fobject.approverRemark
                    }
                    autoComplete="off"
                    type="text"
                    onChange={remarkChamgedHandler}
                    disabled={disableTextArea}
                    maxLength="255"
                  />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                sm={4}
                md={4}
                lg={4}
                xl={4}
                item
                alignitems="flex-end"
                justify="flex-end"
                direction="row"
              ></Grid>
            </Grid>
            <Grid
              container
              alignitems="flex-start"
              justify="flex-end"
              direction="row"
              className={classes.root}
            >
              {employee_data.isFrom === "AdminTableRows" ? (
                <CustomButton
                  btnName="Submit"
                  clicked={saveFun}
                  fucntionality="save"
                />
              ) : (
                ""
              )}
              {employee_data.isFrom === "ApproverTableRows" ? (
                <CustomButton
                  btnName="Reject"
                  clicked={reject_fun}
                  fucntionality="Reject"
                />
              ) : (
                ""
              )}
              {employee_data.isFrom === "ApproverTableRows" ? (
                <CustomButton
                  btnName="Approve"
                  clicked={approveFun}
                  fucntionality="save"
                />
              ) : (
                ""
              )}
            </Grid>
          </div>
        ) : (
          <Spinner />
        )
        }
        <Loader open={loader} />
      </BackgroundCard >
    </div >
  );
}
