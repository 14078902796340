import React, { useEffect } from "react";
import {
  makeStyles,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
const customDropdown = createMuiTheme({
  overrides: {
    MuiFilledInput: {
      root: {
        border: "1px solid #ececec",
        borderRadius: 5,
        width: 150,
        backgroundColor: "none",
        "&:hover": {
          backgroundColor: "none",
        },
        "&:active": {
          backgroundColor: "none",
        },
        "&:aocus": {
          backgroundColor: "none",
        },
        "&:visitedctive": {
          backgroundColor: "none",
        },
      },
    },
  },
  MuiSelect: {
    select: {
      "&:focus": {
        backgroundColor: "none",
      },
      "&:active": {
        backgroundColor: "none",
      },
      "&:hover": {
        backgroundColor: "none",
      },
      "&:visited": {
        backgroundColor: "none",
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    backgroundColor: "white"
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  cssOutlinedInput: {
    position: "relative",
    transition: "background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
    border: "1px solid #ececec",
    borderRadius: 5,
    width: 150,
  },
  cssFocused: {
    border: "1px solid #ececec",
    borderRadius: 5,
    width: 150,
  },
}));
export default function DropDown(props) {
  const classes = useStyles();
  const [status, setStatus] = React.useState("");
  useEffect(() => {
  }, [props.cleareValues]);
  useEffect(() => {
    props.savedUserType ? setStatus(props.savedUserType) : setStatus("");
  }, [props.savedUserType]);
  const handleChange = (event) => {
    setStatus(event.target.value);
  };
  return (
    <ThemeProvider theme={customDropdown}>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel
          id={props.data[0].label}
          style={{ marginTop: -2 }}
          disabled={true}
        >
          {props.data[0].label}
        </InputLabel>
        <Select
          autoFocus
          labelId={props.data[0].label}
          id={props.data[0].label}
          value={status}
          disableUnderline={true}
          disabled={props.disableDropdown}
          onChange={(e) => {
            props.changed(e);
            handleChange(e);
          }}
          label={props.data[0].label}
          style={{
            height: 48,
            borderColor: "lightgrey",
          }}
        >
          {Object.entries(props.data[0].options).map(([key, value], index) => {
            return (
              <MenuItem key={index} value={value}>
                {key}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </ThemeProvider>
  );
}
