import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import BackgroundCard from "../uiComponents/BackgroundCard";
import "../../assets/css/changePassword.css";
import CustomButton from "../uiComponents/Button";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import TimeSheetServices from "../../services/TimeSheetServices";
import { toast } from "react-toastify";
import Loader from "../uiComponents/Loader";
import { withStyles } from "@material-ui/core";
import { toastOptions } from "../../ToastOptions";
// import { useHistory } from "react-router-dom";

// let history = useHistory();


const useStyles = () => ({
  textValidator: {
    width: 281
  },
  labelColor: {
    color: "red"
  },
  buttonsGrid: {
    margin: "48px 0px 29px"
  }
});

class Settings extends Component {

  state = {
    user: {
      oldPassword: "",
      password: "",
      confirmPassword: "",
    },
    loader: false,
    logi_data: JSON.parse(localStorage.getItem("login_responce_data")),
  };
  componentDidMount() {
    // custom rule will have name 'isPasswordMatch'
    ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
      if (value.length > 0 && value !== this.state.user.password) {
        return false;
      }
      return true;
    });



    ValidatorForm.addValidationRule("specialCharacter", (value) => {
      if (/[!@#$%^&*()":-;'/><=+|\\`~?]/g.test(value)) {
        return true;
      }
      return false;
    });
    ValidatorForm.addValidationRule("cannotBeEmpty", (value) => {
      if (value.trim() === '') {
        return true
      }
      return false
    })

    ValidatorForm.addValidationRule("lowerCase", (value) => {
      if (/[a-z]/g.test(value)) {
        return true;
      }
      return false;
    });

    ValidatorForm.addValidationRule("upperCase", (value) => {
      if (/[A-Z]/g.test(value)) {
        return true;
      }
      return false;
    });

    ValidatorForm.addValidationRule("number", (value) => {
      if (/[0-9]/g.test(value)) {
        return true;
      }
      return false;
    });


  }

  componentWillUnmount() {
    // remove rule when it is not needed
    ValidatorForm.removeValidationRule("isPasswordMatch");
  }

  handleChange = (event) => {
    const { user } = this.state;
    user[event.target.name] = event.target.value.replace(/\s/g, "");
    this.setState({ user });
  };

  handleSubmit = () => {
    let email = this.state.logi_data.userDetails.email;
    this.setState({ loader: true });


    const data = {
      email: email,
      currentPassword: this.state.user.oldPassword,
      newPassword: this.state.user.confirmPassword
    }

    TimeSheetServices.change_password(data)
      .then((responce) => {
        if (responce.data.responseCode === 200) {
          this.setState({ loader: false });
          toast.success(responce.data.details, toastOptions);
          // let val = JSON.parse(localStorage.getItem("interval"))
          // window.clearInterval(val[0])
          // sessionStorage.removeItem('searchFilteredValue');
          // localStorage.clear();
          // history.push("/");
          this.log_out()
          toast.success("You have successfully logged out", toastOptions);
        } else {
          this.setState({ loader: false });
          toast.error(responce.data.details, toastOptions);
        }
      }).catch((error) => {
        this.setState({ loader: false });
        toast.error(error, toastOptions);
      });
  };


  handleClose = () => {
    if (this.state.logi_data.userDetails.userType === 2) {
      this.props.history.push("/Timesheeets/viewtimesheets");
    } else {
      this.props.history.push("/Timesheeets/timesheets");
    }
  };

  log_out = () => {
    let val = JSON.parse(localStorage.getItem("interval"))
    window.clearInterval(val[0])
    sessionStorage.removeItem('searchFilteredValue');
    localStorage.clear();
    this.props.history.push("/");
    window.location.reload();
    toast.success("You have successfully logged out", toastOptions);
  };


  render() {
    const { user } = this.state;
    const { classes } = this.props;
    return (
      <div>
        <Grid container xs={12}>
          <BackgroundCard title="Change Password" iconName="forgotPassword" closeIcon="closeIcon" closeClick={this.handleClose}>
            <ValidatorForm onSubmit={this.handleSubmit}>
              <Grid item container justify="center">
                <Grid item xs={4} className="textWidth">
                  <Grid item xs={12} sm={12} className="inputSpace">
                    <TextValidator
                      className={classes.textValidator}
                      label={<span>Old Password<span className={classes.labelColor}>*</span></span>}
                      onChange={this.handleChange}
                      name="oldPassword"
                      type="password"
                      validators={["required"]}
                      errorMessages={["Old password is required"]}
                      value={user.oldPassword}
                    />
                  </Grid>
                  <Grid item xs={12} className="inputSpace">
                    <TextValidator
                      className={classes.textValidator}
                      label={<span>New Password<span className={classes.labelColor}>*</span></span>}
                      onChange={this.handleChange}
                      name="password"
                      type="password"
                      validators={["required", "minStringLength:8", "specialCharacter", "lowerCase", "upperCase", "number"]}
                      errorMessages={[
                        "New password is required",
                        "Password must be at least 8 characters",
                        "Enter atleast one special character",
                        "Enter atleast one lower case character",
                        "Enter atleast one upper case character",
                        "Enter atleast one number",
                      ]}
                      value={user.password}
                    />
                  </Grid>
                  <Grid item xs={12} className="inputSpace">
                    <TextValidator
                      className={classes.textValidator}
                      label={<span>Confirm Password<span className={classes.labelColor}>*</span></span>}
                      onChange={this.handleChange}
                      name="confirmPassword"
                      type="password"
                      validators={["isPasswordMatch"]}
                      errorMessages={[
                        "New Password and confirm password does not match",
                      ]}
                      value={user.confirmPassword}
                    />
                    {user.password !== '' && user.confirmPassword === '' && (
                      <p class="MuiFormHelperText-root Mui-error" style={{ textAlign: 'left', color: 'red' }}>Confirm password is required</p>
                    )}
                  </Grid>
                  <Grid
                    container
                    alignitems="flex-start"
                    justify="center"
                    direction="row"
                    className={classes.buttonsGrid}
                  >
                    <CustomButton
                      btnName="Save"
                      fucntionality="save"
                      type="submit"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </ValidatorForm>
            <Loader open={this.state.loader} />
          </BackgroundCard>
        </Grid>
      </div>
    );
  }
}
export default withStyles(useStyles)(Settings);
