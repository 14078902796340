import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import PropTypes from "prop-types";
import Alert from '@mui/material/Alert';

import Icon from "../../assets/icons/iconsList";
const useStyles = makeStyles({
  root: {
    width: "91%",
    height: "56px",
    margin: "-1% 4.5% 0%",
    padding: "0px",
    backgroundColor: "#53586c",
    position: "relative",
    overflow: "visible",
  },
  cardContent: {
    padding: "0px",
    position: 'relative'
  },
  cardIcon: {
    width: "5rem",
    backgroundColor: "#8ec55f",
    height: "3.5rem",
    textAlign: "center",
    borderRadius: "0px",
    float: "left",
  },

  title: {
    fontSize: "1.5rem",
    color: "#ffffff",
    margin: "11px",
    position: "relative",
    top: "10px",
    left: "10px"
  },
  status: {
    position: 'absolute',
    left: '240px',
    fontSize: '15px',
    fontWeight: '600',
    top: '15px',
    background: '#00B863',
    padding: '4px 11px'
  },
  statusInactive: {
    position: 'absolute',
    left: '240px',
    fontSize: '15px',
    fontWeight: '600',
    top: '15px',
    background: '#ff4411',
    padding: '4px 11px'
  },
  statusReason: {
    position: 'absolute',
    left: '90px',
    background: '#fff',
    padding: '4px 6px',
    top: '0px',
    width: 'max-content'
  }
});

const CustomStatus = (props) => {
  const classes = useStyles();
  return (
    <div className={`alert-wrapper ${props?.status === 'I' || props?.status === 'A' ? 'active':'inactive'}`}>
      {window.location.pathname === '/Timesheeets/edituser' && <Alert severity={(props?.status === 'I' || props?.status === 'A') ? 'success' : 'error'}>
        {(props?.status === 'I' || props?.status === 'A') ? <span className="disabled-status-active">Active</span> : <span className="disabled-status-inactive">Inactive</span>} {(props?.status === 'F') && (props?.statusReason?.length !== undefined || props?.statusReason?.length !== 0) && <span>{props?.statusReason}</span>}
      </Alert>}
    </div>
  )
}

const HeaderStrip = (props) => {
  // console.log(props.statusReason.length);
  const classes = useStyles();
  console.log(props.status);
  return (
    <div>
      <Card className={classes.root} style={props.inlineStyles}>
        {(props.status !== false && props.status !== undefined) && <CustomStatus {...props} />}
        <CardContent className={classes.cardContent}>
          <div className={classes.cardIcon}>
            <div>
              <Icon iconName={props.iconName}>
              </Icon>
            </div>
          </div>
          <p className={classes.title}>{props.title}</p>
        </CardContent>
        <div >
          <Icon iconName={props.closeIcon} closeClick={props.closeClick}>
          </Icon>
        </div>
      </Card>
    </div>
  );
};
HeaderStrip.propTypes = {
  title: PropTypes.string,
};
export default HeaderStrip;
