import React from "react";
import { css } from "@emotion/core";
import PuffLoader from "react-spinners/PuffLoader";
import Grid from "@material-ui/core/Grid";
import Box from '@material-ui/core/Box';
const override = css`
  display: block;
  margin: 50;   
`;
const Spinner = (props) => {
  return (
    <Grid
    container
    xs={12}
    direction="row"
    justify="center"
    alignItems="center">
   <Box m={10}>
   <PuffLoader css={override} size={150} loading={props.show} />
        </Box>
  </Grid>   
  );
};
export default Spinner;
