import React from "react";
import Routes from "./routes/Routes";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function App() {
  return (
    <div>
      <BrowserRouter >
        <ToastContainer autoClose={1500} limit={1} />
        <Routes />
      </BrowserRouter>
    </div>
  );
}

export default App;
