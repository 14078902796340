import React from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Tooltip from "@material-ui/core/Tooltip";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import IdleTimer from 'react-idle-timer'
import { NavLink, Route, BrowserRouter as Router } from "react-router-dom";
import styles from "../assets/jss/sideBarStyles";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import { Switch } from "react-router-dom";
import CreateTimeSheet from "../components/Timesheet/CreateTimeSheet";
import ViewTimeSheet from "../components/Timesheet/ViewTimeSheet";
import Settings from "../components/Timesheet/Settings";
import CreateUser from "../components/Timesheet/CreateUser";
import ViewUsers from "../components/Timesheet/ViewUsers";
import InvoiceReportsPage from "../components/Timesheet/InvoiceReportsPage";
import PayrollReportsPage from "../components/Timesheet/PayrollReportsPage";
import { adminSidepannelOptions, approverSidepannelOptions, userSidepannelOptions } from "../assets/data/sideBarDataList";
import ApproverTimesheet from "../components/Timesheet/ApproverTimesheet";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReminderEmail from "./Timesheet/ReminderEmail";
import { debounce } from 'lodash';
import { toastOptions } from "../ToastOptions";

import Holidays from "./Timesheet/Holidays";
const useStyles = makeStyles(styles);
const SidebarNav = ({ match }) => {
  let history = useHistory();
  const classes = useStyles();
  const [sidebarIndex, setSidebarIndex] = React.useState(localStorage.getItem("sidenavIndex"));
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [loginData] = React.useState(JSON.parse(localStorage.getItem("login_responce_data")));
  const [firstName, setFirstName] = React.useState(loginData.userDetails.firstName);
  const [lastName, setLastName] = React.useState(loginData.userDetails.lastName);

  let isLoggedOut = false;

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const random = () => {
    return Math.random();
  };
  const random2 = () => {
    return Math.random();
  };
  const random3 = () => {
    let obj = {
      isFrom: "createUser",
    };
    localStorage.setItem("edit_user_data", JSON.stringify(obj));
  };
  const loginData_update = (userId, firstName, lastName) => {
    let uid = loginData.userDetails.userId;
    if (uid === userId) {
      let temp_logindata = { ...loginData };
      temp_logindata.firstName = firstName;
      temp_logindata.lastName = lastName;
      setFirstName(firstName);
      setLastName(lastName);
    }
  };
  const handleOnIdle = () => {
    log_out()
  }

  let count = 0;

  function storageEventHandler(evt) {

    count++;
    // setTimeout(() => {
    history.push({
      pathname: "/",
      state: { loggedOut: true }
    });
    // if(evt.oldValue === null){
    //   return
    // }
    //   toast.warn("You have been logged out");
    // }, 1000);
  }


  document.addEventListener("visibilitychange", event => {
    if (document.visibilityState == "visible") {
    } else {
      window.addEventListener("storage", storageEventHandler, {
        once: true,
      });
    }


  }, {
    once: true
  })

  const log_out = () => {
    let val = JSON.parse(localStorage.getItem("interval"))
    window.clearInterval(val[0])
    sessionStorage.removeItem('searchFilteredValue');
    localStorage.clear();
    toast.success("You have successfully logged out", toastOptions);
    history.push("/");
  };



  const handleListItemClick = (event, index) => {
    sessionStorage.removeItem('searchFilteredValue');
    if (index === 1) {
      localStorage.removeItem("table_data");
    }
    setSidebarIndex(index);
    localStorage.setItem("sidenavIndex", index);
  };
  React.useEffect(() => {
    const index = localStorage.getItem("sidenavIndex");
    setSidebarIndex(+index);
  }, []);
  React.useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);//For browser back button disabled
    window.addEventListener('popstate', function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });
  });

  return (
    <React.Fragment>
      <Router>
        <IdleTimer
          timeout={1000 * 60 * 30}
          onIdle={handleOnIdle} />
        <div className={classes.root}>
          <CssBaseline />
          <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
              [classes.appBarShift]: open,
            })}
          >
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, {
                  [classes.hide]: open,
                  [classes.customHoverFocus]: true,
                })}
              >
                <MenuIcon />
              </IconButton>
              <div>
                {loginData.userDetails.userType === 1 ? (
                  <Typography variant="h6" noWrap>
                    Admin - {firstName + " " + lastName}
                  </Typography>
                ) : loginData.userDetails.userType === 2 ? (
                  <Typography variant="h6" noWrap>
                    {loginData.userDetails.firstName +
                      " " +
                      loginData.userDetails.lastName}
                  </Typography>
                ) : loginData.userDetails.userType === 3 ? (
                  <Typography variant="h6" noWrap>
                    Approver -{" "}
                    {loginData.userDetails.firstName +
                      " " +
                      loginData.userDetails.lastName}
                  </Typography>
                ) : loginData.userDetails.userType === 4 ? (
                  <Typography variant="h6" noWrap>
                    External Approver -{" "}
                    {loginData.userDetails.firstName +
                      " " +
                      loginData.userDetails.lastName}
                  </Typography>) :
                  loginData.userDetails.userType === 5 ? (
                    <Typography variant="h6" noWrap>
                      Contractor -{" "}
                      {loginData.userDetails.firstName +
                        " " +
                        loginData.userDetails.lastName}
                    </Typography>) : ( 
                      ""
                    )}
              </div>
              <Tabs className={classes.tabContainer}>
                <PowerSettingsNewIcon
                  className={classes.logotBtn}
                />
                <Tab
                  value="1"
                  className={classes.customHoverFocus}
                  onClick={log_out}
                  label="Logout" />
              </Tabs>
            </Toolbar>
          </AppBar>
          <Drawer
            onMouseEnter={handleDrawerOpen}
            onMouseLeave={handleDrawerClose}
            variant="permanent"
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              }),
            }}
          >
            <div className={classes.toolbar}>
              <img src={"/assets/images/" + loginData.userDetails.orgId + ".png"} width={140} height={40} />
              <IconButton
                onClick={handleDrawerClose}
                className={`${classes.arrowColor} ${classes.customHoverFocus}`}
              >
                {theme.direction === "rtl" ? (
                  <ChevronRightIcon />
                ) : (
                  <ChevronLeftIcon />
                )}
              </IconButton>
            </div>
            <Divider />
            {loginData.userDetails.userType === 1 ? (
              <List>
                {adminSidepannelOptions.map((item, index) => {
                  const Icon = item.icon;
                  return (
                    <ListItem
                      component={NavLink}
                      to={match.url + item.link}
                      onClick={(event) => handleListItemClick(event, index)}
                      button
                      key={index}
                      activeStyle={
                        sidebarIndex === index
                          ? { backgroundColor: "#00CDD3" }
                          : { backgroundColor: "#00CDD3" }
                      }
                    >
                      <ListItemIcon>
                        <Tooltip
                          classes={{
                            tooltip: classes.customTooltip
                          }}
                          title={
                            <span className={classes.tooltipTtl}>
                              {item.text}
                            </span>
                          }
                          placement="right"
                        >
                          <Icon
                            className={classes.iconColor}
                            style={
                              sidebarIndex === index ? { color: "white" } : {}
                            }
                          />
                        </Tooltip>
                      </ListItemIcon>
                      <ListItemText primary={item.text} />
                    </ListItem>
                  );
                })}
              </List>
            ) : (loginData.userDetails.userType === 3 || loginData.userDetails.userType === 4) ? (
              <List>
                {approverSidepannelOptions.map((item, index) => {
                  const Icon = item.icon;
                  return (
                    <ListItem
                      component={NavLink}
                      to={match.url + item.link}
                      onClick={(event) => handleListItemClick(event, index)}
                      button
                      key={index}
                      activeStyle={
                        sidebarIndex === index
                          ? { backgroundColor: "#00CDD3" }
                          : { backgroundColor: "#00CDD3" }
                      }
                    >
                      <ListItemIcon>
                        <Tooltip
                          classes={{
                            tooltip: classes.customTooltip
                          }}
                          title={
                            <span className={classes.tooltipTtl}>
                              {item.text}
                            </span>
                          }
                          placement="right"
                        >
                          <Icon
                            className={classes.iconColor}
                            style={
                              sidebarIndex === index ? { color: "white" } : {}
                            }
                          />
                        </Tooltip>
                      </ListItemIcon>
                      <ListItemText primary={item.text} />
                    </ListItem>
                  );
                })}
              </List>
            ) : (loginData.userDetails.userType === 2 || loginData.userDetails.userType === 5) ? (
              <List>
                {userSidepannelOptions.map((item, index) => {
                  const Icon = item.icon;
                  return (
                    <ListItem
                      component={NavLink}
                      to={match.url + item.link}
                      onClick={(event) => handleListItemClick(event, index)}
                      button
                      key={index}
                      activeStyle={
                        sidebarIndex === index
                          ? { backgroundColor: "#00CDD3" }
                          : { backgroundColor: "#00CDD3" }
                      } >
                      <ListItemIcon>
                        <Tooltip
                          classes={{
                            tooltip: classes.customTooltip
                          }}
                          title={
                            <span className={classes.tooltipTtl}>
                              {item.text}
                            </span>
                          }
                          placement="right">
                          <Icon
                            className={classes.iconColor}
                            style={
                              sidebarIndex === index ? { color: "white" } : {}
                            }
                          />
                        </Tooltip>
                      </ListItemIcon>
                      <ListItemText primary={item.text} />
                    </ListItem>
                  );
                })}
              </List>
            ) : (
              ""
            )}
            <Divider />
          </Drawer>
          <main className={classes.content}>
            <div className={classes.toolbar} />
            <Switch>
              <Route path="/Timesheeets/viewtimesheet" render={() => <CreateTimeSheet key={random} />} />
              <Route path="/Timesheeets/createtimesheet" render={() => <CreateTimeSheet />} />
              <Route path="/Timesheeets/edituser" render={(props) => <CreateUser key={random2} {...props} updateLoginDetails={loginData_update} />} />
              <Route path="/Timesheeets/createuser" render={(props) => (<CreateUser key={random3()} {...props} updateLoginDetails={loginData_update} />)} />
              <Route path="/Timesheeets/viewtimesheets" component={ViewTimeSheet} />
              <Route path="/Timesheeets/settings" component={Settings} />
              <Route path="/Timesheeets/viewUsers" component={ViewUsers} />
              <Route path="/Timesheeets/timesheets" component={ViewTimeSheet} />
              <Route path="/Timesheeets/approvertimesheet" component={ApproverTimesheet} />
              <Route path="/Timesheeets/payrollreports" component={PayrollReportsPage} />
              <Route path="/Timesheeets/invoicereports" component={InvoiceReportsPage} />
              <Route path="/Timesheeets/reminderemail" component={ReminderEmail} />
              <Route path="/Timesheeets/holidays" component={Holidays} />
            </Switch>
          </main>
        </div>
      </Router>
    </React.Fragment>
  );
};
export default SidebarNav;
