import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
const useStyles = makeStyles(() => ({
  employee: {
    position: "absolute",
    marginTop: "-17px",
    marginLeft: 37,
    background: "#5A8C2E",
    color: "#fff",
    paddingLeft: 14,
    paddingRight: 14,
    textAlign: "center",
    borderRadius: 3,
    paddingTop: 9,
  },
  empcard: {
    marginTop: "52px",
    padding: "35px 53px 34px 17px",
    paddingLeft: 93,
    boxShadow: "1px 3px 2px 4px #88888814",
  },
}));

const CreateUserCard = (props) => {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.employee}>
        <h6>{props.title}</h6>
      </div>
      <Card className={classes.empcard}>
        <Grid container spacing={3}>
          {props.children}
        </Grid>
      </Card>
    </div>
  );
};
export default CreateUserCard;