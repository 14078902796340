import React, { useState, useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  OutlinedInput,
  Grid,
  TextareaAutosize,
  Typography,
} from "@material-ui/core";
import TimeSheetServices from "../../services/TimeSheetServices";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../App.css";
import styles from "../../assets/jss/createTableStyles.js";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import BackgroundCard from "../uiComponents/BackgroundCard";
import Spinner from "../uiComponents/Spinner";
import CustomButton from "../uiComponents/Button";
import moment from "moment";
import globalValues from "../../GlobalVariables";
import { useHistory } from "react-router-dom";
import AlertDialog from "../uiComponents/AlertDialog";
import Loader from "../uiComponents/Loader";
import CloseIcon from "@material-ui/icons/Close";
import theme from '../../assets/jss/theme';
import html2canvas from 'html2canvas';
import domtoimage from 'dom-to-image';
import { saveAs } from "file-saver";


import { toastOptions } from "../../ToastOptions";
const USER_API_BASE_URL = globalValues.FILE_API_BASE_URL_CASTUS;
// const USER_API_BASE_URL = globalValues.FILE_API_BASE_URL_TEST;
const useStyles = makeStyles(styles);
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.action.hover,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({}))(TableRow);
export default function CreateTimeSheet(props) {
  let history = useHistory();
  const classes = useStyles();
  const [totalRegularHr, setTotalRegularHr] = useState(0);
  const [totalOtHr, setTotalOtHr] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [dailyHoursErrors, setDailyHoursErrors] = useState([]);
  const [currentRowHours, setCurrentRowHours] = useState();
  const [timesheetScreenshot, setTimesheetScreenshot] = useState(null)
  const [_totalhours, set_TotalHours] = useState(0);
  const [items, setItems] = useState([]);
  const [attachmentsArray, setAttachmentsArray] = useState([]);
  const [fobject, setFobject] = useState([]);
  const [remarks, setRemarks] = useState("");
  const [attachmentFile, setAttachmentFile] = useState([]);
  const [joiningData, setJoiningData] = useState(null);
  const [leastDate, setLeastDate] = useState(null)
  const [readonly, setReadOnly] = useState(false);
  const [isfrom, setIsFrom] = useState("");
  const [loader, setLoader] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [backButtonDisable, setBackButtonDisable] = useState(false);
  const [frontButtonDisable, setFrontButtonDisable] = useState(false);
  const openStatus = "O";
  const submitStatus = "S";
  const [loginData] = React.useState(JSON.parse(localStorage.getItem("login_responce_data")));
  const [totalfilesss, setTotalFiles] = useState(attachmentFile.length + attachmentsArray.length);
  const twoValueAfterDecimal = /^-?\d*[.,]?\d{0,2}$/;
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  let attachedFiles = [];
  const confirm = () => {
    enterTimeSheetService();
    handleClose();
  };
  const handleChangeStatus = ({ meta, file }, status, files) => {
    if (status === "done") {
      files.map(function (file) {
        setAttachmentFile(file.file);
        console.log(file.file);
        attachedFiles.push(file.file);
      });
      setAttachmentFile(attachedFiles);
    } else if (status === "removed") {
      const attachedFiles = [];
      files.map(function (f) {
        if (f.file.name !== file.name) {
          setAttachmentFile(file.file);
          attachedFiles.push(f.file);
        }
      });
      setAttachmentFile(attachedFiles);
    } else if (status === "aborted") {
    }
  };
  useEffect(() => {
    theme.overrides.MuiTable.root.borderSpacing = '0px 11px'; //For css
    let date = new Date();
    let Month_prepended_number;
    let Date_prepended_number;
    (date.getMonth() + 1).toString().length === 1
      ? (Month_prepended_number = String(date.getMonth() + 1).padStart(2, "0"))
      : (Month_prepended_number = date.getMonth() + 1);
    date.getDate().toString().length === 1
      ? (Date_prepended_number = String(date.getDate()).padStart(2, "0"))
      : (Date_prepended_number = date.getDate());
    let current_date =
      date.getFullYear() +
      "-" +
      Month_prepended_number +
      "-" +
      Date_prepended_number;
    const employee_data = JSON.parse(localStorage.getItem("table_data"));
    if (employee_data !== null) {
      let userid = employee_data.userId;
      let orgid = employee_data.orgId;
      setIsFrom(employee_data.isFrom);
      if (employee_data.isFrom === "TableRows") {
        getTimeSheetData(orgid, userid, employee_data.startDate.split("T")[0], 1);
        setBackButtonDisable(true);
        setFrontButtonDisable(true);
        setReadOnly(true);
      } else if (employee_data.isFrom === "EditData") {
        setBackButtonDisable(true);
        setFrontButtonDisable(true);
        getTimeSheetData(orgid, userid, employee_data.startDate.split("T")[0], 1);
      } else {
        getTimeSheetData(
          loginData.userDetails.orgId,
          loginData.userDetails.userId,
          current_date,
          1
        );
      }
    } else {
      getTimeSheetData(
        loginData.userDetails.orgId,
        loginData.userDetails.userId,
        current_date,
        1
      );
    }
    getUsersList();
  }, []);

  const PastWeeksHandler = () => {
    let startDate = fobject.startDate;
    const allowDate = moment(startDate).subtract(7, "day").format("YYYY-MM-DD")
    setLeastDate(allowDate)
    startDate = moment(startDate).subtract(1, "day").format("YYYY-MM-DD");
    getTimeSheetData(
      loginData.userDetails.orgId,
      loginData.userDetails.userId,
      startDate,
      0
    );
  };
  const futureWeeksHandler = () => {
    let endDate = fobject.endDate;
    endDate = moment(endDate).add(1, "day").format("YYYY-MM-DD");
    setLeastDate(endDate)
    getTimeSheetData(
      loginData.userDetails.orgId,
      loginData.userDetails.userId,
      endDate,
      1
    );
  };
  const cancelFun = () => {
    history.push("/Timesheeets/viewtimesheets");
  }
  const defaultDataFunction = () => {
    let totalHours = 0;
    let rh = 0;
    let ot = 0;
    let duplicate = [...items];
    for (let i = 0; i < duplicate.length; i++) {
      if (
        duplicate[i].weekDay !== "Saturday" &&
        duplicate[i].weekDay !== "Sunday" &&
        duplicate[i].holiday !== true
      ) {
        duplicate[i].regularHours = 8;
        duplicate[i].rowHours = Number(duplicate[i].regularHours) + Number(duplicate[i].otHours);
        duplicate[i].rowHours = Number(duplicate[i].rowHours).toFixed(2).replace(/(\.[0-9]*[1-9])0+$|\.0*$/, '$1');
      }
      rh = Number(duplicate[i].regularHours) + rh;
      ot = Number(duplicate[i].otHours) + ot;
      totalHours = Number(duplicate[i].rowHours) + totalHours;
    }
    setItems(duplicate);
    setTotalRegularHr(rh.toFixed(2).replace(/(\.[0-9]*[1-9])0+$|\.0*$/, '$1'));
    setTotalOtHr(ot.toFixed(2).replace(/(\.[0-9]*[1-9])0+$|\.0*$/, '$1'));
    set_TotalHours(totalHours.toFixed(2).replace(/(\.[0-9]*[1-9])0+$|\.0*$/, '$1'));

  };
  const getTimeSheetData = (orgId, userid, current_date) => {
    //setLoader(!loader);
    TimeSheetServices.get_timesheet(orgId, userid, current_date).then(
      (result) => {
        if (result.data.responseCode === 200) {
          // setLoader(loader);
          setTotalRegularHr(result.data.timesheetDetails[0].totalRegularHours);
          setTotalOtHr(result.data.timesheetDetails[0].totalOtHours);
          set_TotalHours(result.data.timesheetDetails[0].totalWeekhours);
          let i;
          for (
            i = 0;
            i < result.data.timesheetDetails[1].timeSheetDetails.length;
            i++
          ) {
            result.data.timesheetDetails[1].timeSheetDetails[i].rowHours =
              Number(result.data.timesheetDetails[1].timeSheetDetails[i].regularHours) +
              Number(result.data.timesheetDetails[1].timeSheetDetails[i].otHours);
            result.data.timesheetDetails[1].timeSheetDetails[i].rowHours = Number(result.data.timesheetDetails[1].timeSheetDetails[i].rowHours).toFixed(2)

            if (result.data.timesheetDetails[1].timeSheetDetails[i].description) {
              result.data.timesheetDetails[1].timeSheetDetails[i].description = result.data.timesheetDetails[1].timeSheetDetails[i].description.trim().replace(/\s+/g, ' ');
            }
          }
          if (result.data.timesheetDetails[1].attachments.length !== 0) {
            let attachmentsArray = result.data.timesheetDetails[1].attachments.map(
              (file) => {
                return file;
              }
            );
            setAttachmentsArray(attachmentsArray);
          } else {
            setAttachmentsArray([]);
          }
          setTotalFiles(result.data.timesheetDetails[1].attachments.length);
          setItems(result.data.timesheetDetails[1].timeSheetDetails);
          //  setAttachmentsArray(attachmentsArray);
          if (
            result.data.timesheetDetails[0].vendorName !== null &&
            result.data.timesheetDetails[0].vendorName !== undefined
          ) {
            result.data.timesheetDetails[0].vendorName = result.data.timesheetDetails[0].vendorName
          }
          setFobject(result.data.timesheetDetails[0]);
          setRemarks(result.data.timesheetDetails[0].remark);
          const employee_data = JSON.parse(localStorage.getItem("table_data"));
          if (employee_data !== null) {
            if (employee_data.isFrom === "TableRows") {
              setReadOnly(true);
            } else if (employee_data.isFrom === "EditData") {
              setReadOnly(false);
            } else {
              if (
                result.data.timesheetDetails[0].reviewerStatus === "S" ||
                result.data.timesheetDetails[0].reviewerStatus === "A"
              ) {
                setReadOnly(true);
              } else {
                setReadOnly(false);
              }
            }
          } else {
            if (
              result.data.timesheetDetails[0].reviewerStatus === "S" ||
              result.data.timesheetDetails[0].reviewerStatus === "A"
            ) {
              setReadOnly(true);
            } else {
              setReadOnly(false);
            }
          }
        } else {
          toast.error("Something went wrong!", toastOptions);
        }
      }
    );
  };

  const inputFieldsHandler = (e, inputIndex, name) => {
    let totalHours = 0;
    let total_regular_hours = 0;
    let total_ot_hours = 0;
    const dublicateArray = [...items];

    dublicateArray.forEach((each, index) => {
      if (index === inputIndex) {
        each[name] = e.target.value;
        if (name === "regularHours" || name === "otHours") {
          each.rowHours = Number(each.regularHours) + Number(each.otHours);
          each.rowHours = Number(each.rowHours).toFixed(2).replace(/(\.[0-9]*[1-9])0+$|\.0*$/, '$1');
        }
      }
      total_regular_hours = Number(each.regularHours) + total_regular_hours;
      total_ot_hours = Number(each.otHours) + total_ot_hours;
      totalHours = Number(each.rowHours) + totalHours;
    });
    setItems(dublicateArray);
    // console.log('current row', currentRowHours);
    // console.log(currentIndex);
    // console.log(items);
    setCurrentIndex(inputIndex)
    if (items[currentIndex] !== undefined) {
      setCurrentRowHours(items[currentIndex].rowHours)
    }
    if (parseInt(items[currentIndex]?.rowHours) > 24) {
      // console.log(currentIndex);
      if (!dailyHoursErrors.includes(currentIndex)) {
        setDailyHoursErrors(data => {
          return [
            ...data,
            currentIndex
          ]
        })
      }
      // toast.error("Regular + OT Hours cannot be more than 24",toastOptions)
    }
    else if (parseInt(items[currentIndex]?.rowHours) <= 24) {
      setDailyHoursErrors(data => {
        return data.filter((el, index) => {
          return el !== currentIndex
        })
      })
    }
    setTotalRegularHr(total_regular_hours.toFixed(2).replace(/(\.[0-9]*[1-9])0+$|\.0*$/, '$1'))
    setTotalOtHr(total_ot_hours.toFixed(2).replace(/(\.[0-9]*[1-9])0+$|\.0*$/, '$1'))
    set_TotalHours(totalHours.toFixed(2).replace(/(\.[0-9]*[1-9])0+$|\.0*$/, '$1'));
  }
  // console.log(dailyHoursErrors);
  const rowChangeHandler = (e, inputIndex, name) => {

    if (e.target.name === 'regularHours' && e.target.name === 'otHours') {

    }

    if (twoValueAfterDecimal.test(e.target.value) && e.target.value >= 0 && (e.target.value === "" || (e.target.value) <= 24) && name !== "description") {
      inputFieldsHandler(e, inputIndex, name);
    } else if (name === "description") {
      inputFieldsHandler(e, inputIndex, name);
    }
  };

  // console.log(currentIndex,dailyHoursErrors);
  const remarkChamgedHandler = (e) => {
    let val = e.target.value;
    setFobject((prevState) => ({
      ...prevState,
      remark: val,
    }));
    setRemarks(val);
  };
  const form_validate = () => {

    let totalfiles = attachmentFile.length + attachmentsArray.length;
    // if (_totalhours === null || _totalhours === 0) {
    //   toast.error("Enter atleast one day data"),toastOptions;
    // }
    // else 
    if (fobject.isAttchamentRequired === true) {
      if (
        (attachmentFile === null ||
          attachmentFile === undefined ||
          attachmentFile.length === 0) &&
        (attachmentsArray === null ||
          attachmentsArray === undefined ||
          attachmentsArray.length === 0)
      ) {
        toast.error("Select atleast one Attachment file ", toastOptions);
        return false;
      }
      else {
        if (totalfiles > 3) {
          toast.error("You can only upload a maximum of 3 files!", toastOptions);
        }
        else if (_totalhours < 40) {
          handleClickOpen();
        } else {
          return true;
        }
      }
    } else if (totalfiles > 3) {
      toast.error("You can only upload a maximum of 3 files!", toastOptions);
    } else if (_totalhours < 40) {
      handleClickOpen();

    } else {
      return true;
    }
  };
  const save_validate = () => {
    let totalfiles = attachmentFile.length + attachmentsArray.length;
    // if (_totalhours === null || _totalhours === 0) {
    //   toast.error("Enter atleast one day data"),toastOptions;
    // } else 
    if (totalfiles > 3) {
      toast.error("You can only upload a maximum of 3 files!", toastOptions);
      return false;
    } else {
      return true;
    }
  };
  const getTimeSheetDetails = () => {
    // console.log(currentRowHours);

    let result = [];
    items.forEach((data) => {
      let inputobject = {};
      inputobject.timesheetDate = data.timesheetDate;
      inputobject.regularHours =
        data.regularHours !== "" ? data.regularHours : null;
      inputobject.otHours = data.otHours !== "" ? data.otHours : null;
      inputobject.description = data.description;
      result.push(inputobject);
    });
    return result;
  };
  const enterTimeSheetService = () => {
    const request = new FormData();
    let timesheetData = {
      orgId: loginData.userDetails.orgId,
      userId: loginData.userDetails.userId,
      startDate: fobject.startDate,
      endDate: fobject.endDate,
      remark: remarks,
      timesheetId: fobject.timesheetId,
      totalWeekhours: _totalhours,
      totalRegularHours: totalRegularHr,
      totalOtHours: totalOtHr,
      reviewerStatus: submitStatus,
      timeSheetDetails: getTimeSheetDetails(),
    };
    request.append("timsheetData", JSON.stringify(timesheetData));
    if (attachmentFile !== null && attachmentFile !== undefined) {
      for (let i = 0; i < attachmentFile.length; i++) {
        request.append("attachments", attachmentFile[i]);
      }
    }

    // if (attachmentFile !== null && attachmentFile !== undefined) {
    //   html2canvas(document.querySelector(".create-timesheet-wrapper"), {
    //     allowTaint: true,
    //     width: document.querySelector(".create-timesheet-wrapper").getBoundingClientRect().width,
    //     height: document.querySelector(".create-timesheet-wrapper").getBoundingClientRect().height
    //   }).then(canvas => {

    //     let base64URL = canvas.toDataURL();

    //     const url = base64URL;
    //     fetch(url)
    //       .then(res => res.blob())
    //       .then(blob => {
    //         const file = new File([blob], "png", { type: "image/png" })
    //         console.log(file);
    //         setTimesheetScreenshot(file)
    //         request.append("screen_attachment", file);
    //       })
    //   });
    //   let keys = Object.fromEntries(request);
    //   console.log(keys);
    // }


    setLoader(!loader);
    setTimeout(() => {
      TimeSheetServices.create_timesheet(request)
        .then((res) => {
          if (res.data.responseCode === 201) {
            setLoader(false);
            toast.success("Submitted Successfully", toastOptions);
            history.push("/Timesheeets/viewtimesheets");
          } else {
            setLoader(false);
            toast.error(res.data.details, toastOptions);
          }
        })
        .catch((err) => {
          toast.error(err, toastOptions);
        });
    }, 2000);

  };
  const deleteImages = (file) => {
    let timesheetId = file.timesheetId;
    let attachmentName = file.attachments;
    TimeSheetServices.delete_image(timesheetId, attachmentName)
      .then((res) => {
        if (res.data.responseCode === 200) {
          toast.success(res.data.details, toastOptions);
          const employee_data = JSON.parse(localStorage.getItem("table_data"));
          let userid = employee_data.userId;
          let orgid = employee_data.orgId;
          getTimeSheetData(
            orgid,
            userid,
            employee_data.startDate.split("T")[0]
          );
        } else {
          toast.error("Failed", toastOptions);
        }
      })
      .catch((err) => {
        toast.error(err, toastOptions);
      });
  };

  const save = () => {
    const request = new FormData();
    let timesheetData = {
      orgId: loginData.userDetails.orgId,
      userId: loginData.userDetails.userId,
      timesheetId: fobject.timesheetId,
      startDate: fobject.startDate,
      endDate: fobject.endDate,
      remark: remarks,
      reviewerStatus: openStatus,
      totalWeekhours: _totalhours,
      totalRegularHours: totalRegularHr,
      totalOtHours: totalOtHr,
      timeSheetDetails: getTimeSheetDetails(),
    };
    request.append("timsheetData", JSON.stringify(timesheetData));
    if (attachmentFile !== null && attachmentFile !== undefined) {
      for (let i = 0; i < attachmentFile.length; i++) {
        request.append("attachments", attachmentFile[i]);
      }
    }

    // if (attachmentFile !== null && attachmentFile !== undefined) {
    //   html2canvas(document.querySelector(".create-timesheet-wrapper"), {
    //     allowTaint: true,
    //     width: document.querySelector(".create-timesheet-wrapper").getBoundingClientRect().width,
    //     height: document.querySelector(".create-timesheet-wrapper").getBoundingClientRect().height
    //   }).then(canvas => {

    //     let base64URL = canvas.toDataURL();

    //     const url = base64URL;
    //     fetch(url)
    //       .then(res => res.blob())
    //       .then(blob => {
    //         const file = new File([blob], "png", { type: "image/png" })
    //         console.log(file);
    //         setTimesheetScreenshot(file)
    //         request.append("screen_attachment", file);
    //       })
    //   });
    //   let keys = Object.fromEntries(request);
    //   console.log(keys);
    // }


    setLoader(!loader);
    setTimeout(() => {
      TimeSheetServices.create_timesheet(request)
        .then((res) => {
          if (res.data.responseCode === 201) {
            setLoader(false);
            toast.success("Saved Successfully", toastOptions);
            history.push("/Timesheeets/viewtimesheets");
          } else {
            setLoader(false);
            toast.error(res.data.details, toastOptions);
          }
        })
        .catch((err) => {
          toast.error(err, toastOptions);
        });
    }, 2000);
  };

  const getUsersList = () => {

    const userId = loginData.userDetails.userId


    TimeSheetServices.get_userApi(userId)
      .then(res => {
        let userDetails = res.data.userDetails[0];
        setJoiningData(userDetails?.joiningDate)
      })
  }



  // save the week timesheet data
  const saveFun = () => {

    if (dailyHoursErrors.length > 0) {
      toast.error("Timesheet can not exceeds 24 hours per day", toastOptions)
    }
    else if (save_validate()) {
      save();
    }
  };
  // submit the week timesheet data
  const submit = () => {

    if (dailyHoursErrors.length > 0) {
      toast.error("Timesheet can not exceeds 24 hours per day", toastOptions)
    }
    else {
      if (form_validate()) {
        enterTimeSheetService();
      }
    }


  };
  return (
    <div className="create-timesheet-wrapper">
      <BackgroundCard
        title={
          isfrom === "TableRows"
            ? "View Timesheet"
            : isfrom === "EditData"
              ? "Edit Timesheet"
              : "Enter Timesheet"
        }
        iconName="createTimeSheet"
        closeIcon={isfrom === "TableRows" || isfrom === "EditData" ? "closeIcon" : ""} closeClick={cancelFun}
      >
        {items.length !== 0 ? (
          <div>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
            >
              <Grid item xs={6} container>
                {isfrom === "TableRows" ||
                  isfrom === "EditData" ? <Grid

                  >
                  <div >
                    <p >
                      <span>
                        {moment(fobject.startDate).format("MM-DD-YYYY")}
                      </span>
                      <span className={classes.spanSpc}> - </span>
                      <span>{moment(fobject.endDate).format("MM-DD-YYYY")}</span>
                    </p>
                  </div>
                </Grid> : <Grid
                  item
                  className={classes.lrgrid}
                >
                  <button
                    disabled={frontButtonDisable || moment(leastDate) <= moment(joiningData)}
                    className={classes.frontBtn}
                    onClick={PastWeeksHandler}
                  >
                    <ChevronLeftIcon />
                  </button>
                  <p className={classes.dateparagraph}>
                    <span>
                      {moment(fobject.startDate).format("MM-DD-YYYY")}
                    </span>
                    <span className={classes.spanSpc}> - </span>
                    <span>{moment(fobject.endDate).format("MM-DD-YYYY")}</span>
                  </p>
                  <button
                    disabled={backButtonDisable}
                    className={classes.backBtn}
                    onClick={futureWeeksHandler}
                  >
                    <ChevronRightIcon />
                  </button>
                </Grid>}
                <div></div>
              </Grid>
              <Grid
                item
                xs={6}
                className={classes.vndrdtGd}
              >
                <p className={classes.dateKey}>
                  Vendor:
                  <span className={classes.dateValues}>
                    {fobject.vendorName}
                  </span>
                </p>
                <p className={classes.dateKey}>
                  Status:{" "}
                  {fobject.reviewerStatus === "A" ? (
                    <span className={classes.status}>Approved</span>
                  ) : fobject.reviewerStatus === "O" ? (
                    <span className={classes.status}>Open</span>
                  ) : fobject.reviewerStatus === "R" ? (
                    <span className={classes.status}>Rejected</span>
                  ) : fobject.reviewerStatus === "S" ? (
                    <span className={classes.status}>Submitted</span>
                  ) : (
                    ""
                  )}
                </p>
              </Grid>
            </Grid>
            <div>
              <Table aria-label="customized table" className="tablerow">
                <TableHead className="tablehead">
                  <TableRow style={{ background: "#fafafa" }}>
                    <TableCell
                      className={classes.tableHeadDayCell}
                    >
                      Day
                    </TableCell>
                    <TableCell className={classes.tableHeadCells}>
                      Regular Hours
                    </TableCell>
                    {fobject.otEnable === true ? (
                      <TableCell className={classes.tableHeadCells}>
                        OT Hours
                      </TableCell>
                    ) : (
                      ""
                    )}
                    <TableCell className={classes.tableHeadCells}>
                      Description
                    </TableCell>
                    <TableCell className={classes.tableHeadCells}>
                      Total Hours
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {items.map((item, index) => {
                    return (
                      <StyledTableRow
                        key={item.id}
                        style={{
                          background:
                            item.weekDay === "Sunday" ||
                              item.weekDay === "Saturday" ||
                              item.holiday === true
                              ? "#B4B6DB"
                              : "",
                        }}
                      >
                        <StyledTableCell>
                          <div className={classes.clOne}>
                            <p className={classes.weekDay}>{item.weekDay}</p>
                            <p className={classes.timesheetDate}>
                              {moment(item.timesheetDate).format("MM-DD-YYYY")}
                            </p>
                          </div>
                        </StyledTableCell>
                        <StyledTableCell>
                          <OutlinedInput
                            className={classes.inputWidth}
                            name="regularHours"
                            error={dailyHoursErrors.includes(index)}
                            value={
                              item.regularHours === null
                                ? ""
                                : item.regularHours
                            }
                            onChange={(e) =>
                              rowChangeHandler(e, index, "regularHours")
                            }
                            autoComplete="off"
                            type="text"
                            inputProps={{ maxlength: 5 }}
                            disabled={readonly} />
                        </StyledTableCell>
                        {fobject.otEnable === true ? (
                          <StyledTableCell>
                            <OutlinedInput
                              error={dailyHoursErrors.includes(index)}
                              className={classes.inputWidth}
                              name="otHours"
                              value={item.otHours === null ? "" : item.otHours}
                              onChange={(e) =>
                                rowChangeHandler(e, index, "otHours")
                              }
                              type="text"
                              autoComplete="off"
                              inputProps={{ maxLength: 5 }}
                              disabled={readonly}
                            />
                          </StyledTableCell>
                        ) : (
                          ""
                        )}
                        <StyledTableCell>
                          <OutlinedInput
                            className={classes.descWidth}
                            name="description"
                            value={
                              item.description === null ? "" : item.description
                            }
                            onChange={(e) =>
                              rowChangeHandler(e, index, "description")
                            }
                            inputProps={{ maxLength: 50 }}
                            autoComplete="off"
                            type="text"
                            disabled={readonly}
                          />
                        </StyledTableCell>
                        <StyledTableCell>{item.rowHours}</StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
            <Grid container className={classes.attach}>
              <Grid
                container
                xs={12}
                sm={8}
                md={8}
                lg={8}
                xl={8}
              >
                <Grid item>
                  <Typography variant="h6">Remarks</Typography>
                </Grid>
                <Grid item>
                  <TextareaAutosize
                    className={classes.textareaStyles}
                    name="remarks"
                    id="remarks"
                    onChange={remarkChamgedHandler}
                    rowsMax={3}
                    rowsMin={3}
                    value={fobject.remark === null ? "" : fobject.remark}
                    aria-label="maximum height"
                    disabled={readonly}
                    maxLength="120"
                  />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                sm={4}
                md={4}
                lg={4}
                xl={4}
                item
                alignitems="flex-end"
                justify="flex-end"
                direction="row"
              >
                <h6 className={classes.totalHourText}>Total Hours {_totalhours !== null ? _totalhours : 0}</h6>
              </Grid>
            </Grid>
            <Grid className={classes.filesPreview}>
              <Grid className={classes.attachGd}>
                <Grid item>
                  <Typography variant="h6" className={classes.attachText}>
                    Attachments
                    {fobject.isAttchamentRequired === true ? <span className={classes.startClr} >*</span> : ""}
                  </Typography>
                </Grid>
              </Grid>
              {isfrom !== "TableRows" &&
                fobject.reviewerStatus !== "S" &&
                fobject.reviewerStatus !== "A" &&
                totalfilesss < 3 ? (
                <Dropzone
                  maxFiles={3 - totalfilesss}
                  onChangeStatus={handleChangeStatus}
                  accept="image/*,.pdf,.csv,.xlsx,.docx"
                  validate={(file) => {
                    if (file.meta.size > new Number(2 * 1024 * 1000)) {
                      toast.error("File size shouldn't exceed 2MB", toastOptions);
                      file.remove();
                      return true; //file status is validation error
                    } else {
                      return false; //file, status is accepted
                    }
                  }}
                />
              ) : (
                ""
              )}
            </Grid>
            {isfrom === "TableRows" ||
              isfrom === "EditData" ||
              fobject.reviewerStatus === "S" ||
              fobject.reviewerStatus === "A" ||
              fobject.reviewerStatus === "O" ||
              fobject.reviewerStatus === "R" ? (
              <Grid
                container
                direction="row"
                alignitems="center"
                justify="flex-start"
                className={`${classes.root} ${classes.imageContainer}`}
              >
                {attachmentsArray.map((file) => {
                  return (
                    <Grid
                      item
                      xs={4}
                      className={classes.attachmentFileCss}
                    >
                      <a
                        href={`${USER_API_BASE_URL}${file.attachments}`}
                        target="_blank"
                      >
                        {file.fileName?.length > 29
                          ? file.fileName.slice(0, 30) + "..."
                          : file.fileName}
                      </a>
                      {isfrom === "EditData" ? (
                        <CloseIcon
                          onClick={() => deleteImages(file)}
                          className={classes.closeIcn}
                        />
                      ) : null}
                    </Grid>
                  );
                })}
              </Grid>
            ) : null}
            {fobject.reviewerStatus !== "S" &&
              fobject.reviewerStatus !== "A" &&
              isfrom !== "TableRows" ? (
              <Grid
                container
                alignitems="flex-start"
                justify="flex-end"
                direction="row"
                className={classes.root}
              >
                <CustomButton
                  btnName="Default"
                  clicked={defaultDataFunction}
                  fucntionality="Cancel"
                />
                <CustomButton
                  btnName="Save"
                  clicked={saveFun}
                  fucntionality="save"
                />
                {/* <CustomButton
                  btnName="Screenshot"
                  clicked={screenshotPage}
                  fucntionality="save"
                /> */}
                <CustomButton
                  btnName="Submit"
                  fucntionality="save"
                  clicked={submit}
                />
              </Grid>
            ) : (

              ""
            )
            }
            <AlertDialog
              alertTitle="Warning"
              alerContent="The timesheet has less than 40 hours on it. Are you sure want to continue?"
              saveButtonText="Yes"
              cancelButtonText="No"
              open={open}
              onSubmit={confirm}
              onCancel={handleClose}
            />
          </div>
        ) : (
          <Spinner />

        )}
        <Loader open={loader} />
      </BackgroundCard>
    </div>
  );
}
