import React from "react";
import { Backdrop, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
const useStyles =makeStyles({
    backdrop: {
        zIndex: 1000,
        color: '#fff',
      },
})
const Loader = (props) => {
  const classes = useStyles();
  return (
    <div>
      <Backdrop className={classes.backdrop} open={props.open}>
		  <CircularProgress color="inherit" />
	  </Backdrop>
    </div>
  );
};
 export default Loader;
