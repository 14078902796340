import React from "react";
import { NewViewUsersDropDown } from "../../assets/data/dropDownOptions";
import "../../assets/css/viewusers.css";
import "react-toastify/dist/ReactToastify.css";
import Button from "@material-ui/core/Button";
import BackgroundCard from "../uiComponents/BackgroundCard";
import MainTable from "../uiComponents/Table";
import Grid from "@material-ui/core/Grid";
import TimeSheetServices from "../../services/TimeSheetServices";
import { viewUsersHeading } from "../../assets/data/viewTimesheetData";
import Spinner from "../uiComponents/Spinner";
import AddIcon from "@material-ui/icons/Add";
import Toolbar from "@material-ui/core/Toolbar";
import clsx from "clsx";
import { lighten, withStyles } from "@material-ui/core/styles";
import Select from "react-select";
const useStyles = (theme) => ({
  root: {
    paddingLeft: 0,
    paddingRight: theme.spacing(1),
    marginBottom: 0,
  },
  highlight:
    theme.palette.type === "light"
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: "1 1 100%",
  },
  Select: {
    width: 156,
    marginLeft: 20,
    fontSize: 15,
  },
  searchName: {
    width: 215,
    height: 30,
    paddingLeft: 30,
    border: "1px solid #DCDCDD",
    borderRadius: 4,
    fontSize: 15,
  },
  searchIcon: {
    width: 16,
    height: 20,
    position: "absolute",
    left: 7,
  },
  calendarIcon: {
    width: 16,
    height: 20,
    position: "absolute",
    left: 243,
  },
  resetBtn: {
    marginLeft: "auto",
    background: "#00B863",
    height: 30,
    fontSize: 15,
    textTransform: "none",
    float: "right",
    borderRadius: 29,
    padding: "7px 20px",
    outline: "none",
    cursor: "pointer",
    color: theme.palette.common.white,
    "&:focus": {
      outline: "none",
      WebkitBoxShadow: "-12px 2px 26px -11px rgba(52,191,54,1)",
      MozBoxShadow: "-12px 2px 26px -11px rgba(52,191,54,1)",
      boxShadow: "-12px 2px 26px -11px rgba(52,191,54,1)",
    },
    "&:hover": {
      backgroundColor: "#00B863",
      WebkitBoxShadow: "-12px 2px 26px -11px rgba(52,191,54,1)",
      MozBoxShadow: "-12px 2px 26px -11px rgba(52,191,54,1)",
      boxShadow: "-12px 2px 26px -11px rgba(52,191,54,1)",
    },
  },
  addIcon: {
    height: 23,
    width: 23,
    marginLeft: -20,
    paddingLeft: "3px",
  }
});
const customStyles = {
  control: (base) => ({
    ...base,
    height: 30,
    minHeight: 30,
  }),
  placeholder: (state) => ({
    color: "grey",
    position: "absolute",
    top: 4,
  }),
  indicatorsContainer: (state) => ({
    position: "absolute",
    bottom: -4,
    right: 0,
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 ,position:'relative'}),
  singleValue: (state) => ({
    position: "absolute",
    top: 4,
    zIndex : 9
  }),
};
class ViewUsers extends React.Component {
  constructor(props) {
    super(props);
    this.searchValue = "";
    this.typeValue = "";
    this.state = {
      employeeData: [],
      employeeDataDuplicate: [],
      showLoader: false,
      showImage: false,
      noData: false,
      status: null,
      loginDetails: JSON.parse(localStorage.getItem("login_responce_data")),
    };
  }
  handleChange = (event) => {
    this.typeValue = event.target.value;
  };
  componentDidMount() {
    window.onunload = function () {
      sessionStorage.removeItem('searchFilteredValue');
    }
    this.getUsersList();
    this.setState({
      status: { id: "User", label: "Employee", value: "A" },
    });
   window.history.pushState(null, document.title, window.location.href);//For browser back button disabled
   window.addEventListener('popstate', function (event) {
     window.history.pushState(null, document.title, window.location.href);
   });
  }
  getUsersList = () => {
    let userList = []
    this.setState({ showLoader: true, noData: false });
    const adminId = {
      orgId: this.state.loginDetails.userDetails.orgId,
      userId: this.state.loginDetails.userDetails.userId,
    };
    TimeSheetServices.getUser(adminId).then((response) => {
      if (response.data.responseCode === 200) {
        userList = response.data.userDetails;
        if (response.data.userDetails.length !== 0) {
          let timesheestData;
          timesheestData = response.data.userDetails.filter(
            (item) => item.roleDesc === "Employee"
          );
          this.setState({
            status: { id: "User", label: "Employee", value: "A" },
          });
          timesheestData.isFrom = "viewUsers";
          this.setState({
            employeeData: timesheestData,
            employeeDataDuplicate: response.data.userDetails,
            showLoader: false,
            noData: false,
          });

          if (sessionStorage?.getItem('searchFilteredValue') !== null) {
            this.getRowsBasedonName(
              sessionStorage?.getItem('searchFilteredValue'),
              this.state.status?.value
            );
          }


          this.state.employeeData?.length
            ? this.setState({ noData: false })
            : this.setState({ noData: true });
          return this.state.employeeData;
        }
      } else {
        userList = [];
        this.setState({ showImage: true, showLoader: false, noData: true });
        this.setState({ employeeData: [] });
      }
    })
      .catch((error) => { });
  };

  getRowsBasedonName = async (searchValue, typeValue) => {
    let filterData;
    this.searchValue = searchValue;
    switch (typeValue) {
      case "A":
        this.typeValue = "Employee";
        break;
        case "C":
          this.typeValue = "Contractor";
          break;
      case "R":
        this.typeValue = "Approver";
        break;
      case "ER":
        this.typeValue = "External Approver";
        break;
      case "X":
        this.typeValue = "Admin";
        break;
      default:
        this.typeValue = typeValue;
        break;
    }
    if (this.typeValue) {
      filterData = this.state.employeeDataDuplicate.filter((item) => {
        let x =
          item.firstName
            .toUpperCase()
            .startsWith(searchValue.toString().toUpperCase()) ||
          item.lastName
            .toUpperCase()
            .startsWith(searchValue.toString().toUpperCase());
        return (
          x &&
          (this.typeValue === "All" ? true : item.roleDesc == this.typeValue)
        );
      });
      filterData.isFrom = "viewUsers";
      await this.setState({ employeeData: filterData });
    } else {
      filterData = this.state.employeeDataDuplicate.filter(
        (item) =>
          item.firstName
            .toUpperCase()
            .startsWith(searchValue.toString().toUpperCase()) ||
          item.lastName
            .toUpperCase()
            .startsWith(searchValue.toString().toUpperCase())
      );
      if (
        searchValue !== "" &&
        searchValue !== undefined &&
        searchValue !== null
      ) {
        this.setState({ employeeData: [] });
        filterData.isFrom = "viewUsers";
        await this.setState({ employeeData: filterData });

      } else {
        await this.setState({ employeeData: this.state.employeeDataDuplicate });
      }
    }
    this.state.employeeData.length
      ? this.setState({ noData: false })
      : this.setState({ noData: "No data to display" });
  };
  render() {
    const { classes } = this.props;
    return (
      <BackgroundCard
        className="backgroundCard"
        title="View Users"
        iconName="viewUser" >
        <Toolbar className={clsx(classes.root)}>
          <>
            <input
              type="text"
              placeholder="First Name/Last Name"
              onChange={(e) => {
                this.getRowsBasedonName(e.target.value.replace(/[^a-zA-Z]/g, ''), this.typeValue);
                sessionStorage.setItem('searchFilteredValue', e.target.value.replace(/[^a-zA-Z]/g, ''))
              }}
              value={sessionStorage?.getItem('searchFilteredValue')}
              className={classes.searchName}
            />
            <img
              src="/assets/icons/search.svg"
              alt="Search"
              className={classes.searchIcon}
            />
          </>
          <Select
            value={this.state.status}
            onChange={(e) => {
              this.setState({ status: e });
              this.getRowsBasedonName(this.searchValue, e.value);
            }}
            options={NewViewUsersDropDown}
            placeholder="User Type"
            className={classes.Select}
            styles={customStyles}
          />
          <Button
            variant="contained"
            onClick={() => {
              this.props.history.push("/Timesheeets/createuser");
            }}
            className={classes.resetBtn}
          >
            <AddIcon
              className={classes.addIcon}
            />
            Add New User
          </Button>
        </Toolbar>

        {this.state.showLoader === true ? (
          <Spinner show={this.state.showLoader} />
        ) : (
          <Grid item xs={12} sm={12}>
            <MainTable
              callApi={() => this.getUsersList()}
              tableHead={viewUsersHeading}
              tableData={this.state.employeeData}
            />
          </Grid>
        )}
      </BackgroundCard>
    );
  }
}

export default withStyles(useStyles)(ViewUsers);
