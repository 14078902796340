import axios from "axios";
import GlobalVariables from "../GlobalVariables";
// const USER_API_BASE_URL = GlobalVariables.MINDWAVE_API_BASE_URL
const USER_API_BASE_URL = GlobalVariables.CASTUS_API_BASE_URL
// const USER_API_BASE_URL = GlobalVariables.DEV_API_BASE_URL
//  const USER_API_BASE_URL = GlobalVariables.UATTMS_API_BASE_URL;
// const USER_API_BASE_URL = GlobalVariables.TEST_QA_BASE_URL;

class TimeSheetServices {
  create_timesheet(user) {
    return axios.post(
      USER_API_BASE_URL + "create-timesheet/",
      user
    );
  }

  get_screenshot(reqData) {
    return axios.post(
      USER_API_BASE_URL + "payroll/get_attachments/", reqData
    );
  }

  user_activation(userId, status, reason) {
    return axios.post(
      USER_API_BASE_URL + "user/user_active_inactive/" + userId + "/?status=" + status + "&statusReason=" + reason
    )
  }

  create_holiday(orgId, userId, holiday_data) {
    return axios.post(
      USER_API_BASE_URL +
      "user/add_holiday/?orgId=" +
      orgId +
      "&userId=" +
      userId,
      holiday_data
    );
  }

  edit_holiday(id, orgId, userId, updatedHolidayData) {
    return axios.post(
      USER_API_BASE_URL +
      "user/edit_holiday/?id=" + id + "&userId=" + userId + "&orgId=" + orgId, updatedHolidayData
    );
  }

  delete_holiday(holidayId, orgId, userId) {
    return axios.delete(
      USER_API_BASE_URL +
      "user/delete/?id=" + holidayId + "&orgId=" + orgId + "&userId=" + userId
    );
  }

  viewHolidays(orgId, userId) {
    return axios.get(
      USER_API_BASE_URL +
      "user/view_holiday/?orgId=" +
      orgId +
      "&userId=" +
      userId
    );
  }

  getWeekFormat() {
    return axios.get(USER_API_BASE_URL + "get-week-details")
  }

  get_timesheet(orgId, userId, inputdate) {
    return axios.get(
      USER_API_BASE_URL +
      "get-timesheet-details/?orgId=" +
      orgId +
      "&userId=" +
      userId +
      "&date=" +
      inputdate
    );
  }

  viewEmployeeTimeSheet(orgId, userId) {
    return axios.get(
      USER_API_BASE_URL +
      "view-emp-timesheet/?orgId=" +
      orgId +
      "&userId=" +
      userId
    );
  }

  getTimesheet_by_date(orgId, userId, start, end) {
    return axios.get(
      USER_API_BASE_URL +
      "view-emp-timesheet/?orgId=" +
      orgId +
      "&userId=" +
      userId +
      `&fromDate=` +
      start +
      `&toDate=` +
      end

    );
  }

  addEmp(user) {
    return axios.post(USER_API_BASE_URL + "user/create-user/", user);
  }
  //delete user Api

  delete_user(userId) {
    return axios.delete(USER_API_BASE_URL + "user/delete-user/" + userId);

  }
  getvendor({ orgId }) {
    return axios.get(
      USER_API_BASE_URL + "user/get-vendor-details/?orgId=" + orgId
    );

  }


  getInvoiceFrequency() {
    return axios.get(
      USER_API_BASE_URL + "user/get_invoice_frequency/"
    );
  }

  getUser({ orgId, userId }) {

    return axios.get(
      USER_API_BASE_URL + "user/get-users/?orgId=" + orgId + "&userId=" + userId
    );
  }
  getapprover({ orgId }) {

    return axios.get(
      USER_API_BASE_URL + "user/get-approver-details/?orgId=" + orgId
    );
  }
  //Login Api
  login_api(data) {

    return axios.post(
      USER_API_BASE_URL + "user/login/", data
    );
  }

  //Refresh token
  refresh_token(token) {

    return axios.post(
      USER_API_BASE_URL + "user/refresh/", token
    );
  }

  forget_password(email) {

    return axios.get(
      USER_API_BASE_URL + "user/forget-password/?email=" + email
    );
  }
  change_password(data) {
    return axios.post(
      USER_API_BASE_URL +
      "user/change-password/", data
    );
  }
  //Invoice Report

  invoice_reports(orgId, userId) {

    return axios.get(
      USER_API_BASE_URL +
      "payroll/invoces/?orgId=" +
      orgId +
      "&userId=" +
      userId
    );
  }

  //date filter invoice get api
  getInvoice_by_date(orgId, userId, start, end, freqDisabled) {

    return axios.get(
      USER_API_BASE_URL +
      "payroll/invoces/?orgId=" +
      orgId +
      "&userId=" +
      userId +
      `&fromDate=` +
      start +
      `&toDate=` +
      end + `&freqDisabled=` + freqDisabled
    );
  }
  //frequency filter invoice get api
  // getInvoice_by_frequency(orgId, userId, start, end,freqDisabled) {

  //   return axios.get(
  //     USER_API_BASE_URL +
  //       "payroll/invoces/?orgId=" +
  //       orgId +
  //       "&userId=" +
  //       userId +
  //       `&fromDate=` +
  //       start +
  //       `&toDate=` +
  //       end +`&freqDisabled=`+ freqDisabled
  //   );
  // }

  //save invoice post api
  save_invoice(setArray) {

    return axios.post(USER_API_BASE_URL + "payroll/save_invoices/", setArray);
  }


  //Payroll Reports

  payroll_reports(orgId, userId) {

    return axios.get(
      USER_API_BASE_URL +
      "payroll/payrolls/?orgId=" +
      orgId +
      "&userId=" +
      userId
    );
  }

  payroll_details(orgId, userId, fromDate, toDate) {

    return axios.get(
      USER_API_BASE_URL +
      "payroll/payroll-details/?orgId=" +
      orgId +
      "&userId=" +
      userId +
      "&fromDate=" +
      fromDate +
      "&toDate=" +
      toDate
    );
  }

  //date filter payroll get api
  getPayroll_by_date(orgId, userId, start, end) {

    return axios.get(
      USER_API_BASE_URL +
      "payroll/payrolls/?orgId=" +
      orgId +
      "&userId=" +
      userId +
      `&fromDate=` +
      start +
      `&toDate=` +
      end
    );
  }

  //email validation

  email_validateApi(email) {

    return axios.get(
      USER_API_BASE_URL + "user/email-validation/?email=" + email
    );
  }
  //email validate for vendor

  vendorEmail_validateApi(vendor_email) {

    return axios.get(
      USER_API_BASE_URL + "user/vendor-email-validation/?email=" + vendor_email
    );
  }
  //delete images

  delete_image(timesheetid, attachmentname) {

    return axios.delete(
      USER_API_BASE_URL +
      "delete-attachment/?timesheetId=" +
      timesheetid +
      "&attachmentName=" +
      attachmentname
    );
  }
  //edit user apis

  get_userApi(userId) {

    return axios.get(USER_API_BASE_URL + "user/get-user/" + userId);
  }
  edit_userApi(userId, userdata) {

    return axios.post(
      USER_API_BASE_URL + "user/edit-user/?userId=" + userId,
      userdata
    );
  }
  create_vendorApi(vendors) {

    return axios.post(USER_API_BASE_URL + "user/create-vendor/", vendors);
  }

  employee_id_validation(empId) {

    return axios.get(
      USER_API_BASE_URL + "user/employee-id-validation/?emp_id=" + empId
    );
  }
  //Reminder emails for employees
  reminder_emails_api(fromDate, toDate, orgId) {

    return axios.get(
      USER_API_BASE_URL +
      "user/send-reminder/?fromDate=" +
      fromDate +
      "&toDate=" +
      toDate +
      `&orgId=` +
      orgId
    );
  }

}

export default new TimeSheetServices();
