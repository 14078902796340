import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import "../../App.css";
import "../../assets/css/createuser.css";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import BackgroundCard from "../uiComponents/BackgroundCard";
import { userTypeDropDown } from "../../assets/data/dropDownOptions";
import DropDown from "../uiComponents/DropDown";
import TimeSheetServices from "../../services/TimeSheetServices";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MaterialUIPickers from "../uiComponents/DatePickerfiled";
import CreateUserCard from "../uiComponents/CreateuserCard";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import CustomButton from "../uiComponents/Button";
import Loader from "../uiComponents/Loader";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import HeaderStrip from "../uiComponents/HeaderStrip";
import moment from "moment";
import { toastOptions } from "../../ToastOptions";

import PersonAddIcon from '@mui/icons-material/PersonAdd';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const customStyles = {
  input: (disabled) => ({
    ...disabled,
    color: "black",
  }),
};
const nameRegex = /^([a-zA-Z]+(\s){0,1})+([a-zA-Z]\s{0,1})*$/;
class Createuser extends Component {
  constructor() {
    super();
    this.state = {
      fields: {
        bonusPay: null,
        newInvoiceDiscount: null,
        invoiceDiscount: null,
        newOtRate: null,
        otRate: null,
        newHrRate: null,
        documentsLink: "",
        clientName: "",
        degree: "",
        comments: "",
      },
      isVendorDateChangeShown: false,
      statusReason : '',
      newVendorChangeDate: null,
      vendorChangeDate: null,
      fName: "",
      lName: "",
      weekFormatDropdownData: [],
      invoiceFrequencyDropdownData: [],
      vendordetails: [],
      apporoverdetails: [],
      vendorEmail: "",
      disable: true,
      disableView: false,
      disableVendor: true,
      empDob: null,
      empJoinDate: null,
      vendorDate: null,
      statusDate: null,
      loader: false,
      isEmailValid: true,
      isEmpIdValid: true,
      hasError: false,
      tmsFormatError: false,
      newVendorDateChangeError: false,
      newVendorWeekFormatError: false,
      attachmentError: false,
      statusError: false,
      approverError: false,
      vendorError: false,
      invoiceError: false,
      dobError: false,
      joinError: false,
      expiryError: false,
      selected: null,
      selectedValue: 2,
      userCategory: null,
      weekFormatId: null,
      newVendorWeekFormatId: null,
      attachements: "",
      workStatus: null,
      approverId: null,
      invoiceFrequency: "W",
      vendorId: null,
      vendorIdActual: null,
      vendorIdEdit: null,
      vendorIdOnNewVendorPopupOpen: null,
      userDetails: [],
      disableDropdownvar: false,
      editVendorId: null,
      testFieldsDropDown: false,
      readOnlyDate: false,
      open: false,
      isVendorIdSame: false,
      disableWeekFormat: false,
      vendorform: {},
      newVendorForm: {},
      initialEditWeekFormatId: null,
      viewUserDetails: JSON.parse(localStorage.getItem("edit_user_data")),
      loginDetails: JSON.parse(localStorage.getItem("login_responce_data")),
    };
  }

  handleClick() {
    this.setState({
      hasError:
        this.state.userCategory !== "" &&
          this.state.userCategory !== null &&
          this.state.userCategory !== undefined
          ? false
          : true,
      statusError:
        this.state.workStatus !== "" &&
          this.state.workStatus !== null &&
          this.state.workStatus !== undefined
          ? false
          : true,
      approverError:
        this.state.approverId !== "" &&
          this.state.approverId !== null &&
          this.state.approverId !== undefined
          ? false
          : true,
      vendorError:
        this.state.vendorId !== "" &&
          this.state.vendorId !== null &&
          this.state.vendorId !== undefined
          ? false
          : true,
      invoiceError:
        this.state.invoiceFrequency !== "" &&
          this.state.invoiceFrequency !== null &&
          this.state.invoiceFrequency !== undefined
          ? false
          : true,
      dobError:
        this.state.empDob !== "" &&
          this.state.empDob !== null &&
          this.state.empDob !== undefined
          ? false
          : true,
      joinError:
        this.state.empJoinDate !== "" &&
          this.state.empJoinDate !== null &&
          this.state.empJoinDate !== undefined
          ? false
          : true,
      expiryError:
        this.state.statusDate !== "" &&
          this.state.statusDate !== null &&
          this.state.statusDate !== undefined
          ? false
          : true,
      tmsFormatError:
        this.state.weekFormatId !== "" &&
          this.state.weekFormatId !== null &&
          this.state.weekFormatId !== undefined
          ? false
          : true,
      newVendorDateChangeError:
        this.state.newVendorChangeDate !== "" &&
          this.state.newVendorChangeDate !== null &&
          this.state.newVendorChangeDate !== undefined
          ? false
          : true,
      newVendorWeekFormatError:
        this.state.newVendorWeekFormatId !== "" &&
          this.state.newVendorWeekFormatId !== null &&
          this.state.newVendorWeekFormatId !== undefined
          ? false
          : true,
      attachmentError:
        this.state.attachements !== "" &&
          this.state.attachements !== null &&
          this.state.attachements !== undefined
          ? false
          : true,
    });
  }
  handleClickOpen = () => {
    this.setState({ vendorIdOnNewVendorPopupOpen: this.state.vendorId });

    this.setState({
      open: true,
    });
  };
  handleClose = () => {
    this.setState({
      open: false,
    });
    this.setState({ newVendorForm: {} })
    this.setState({ vendorId: this.state.vendorIdOnNewVendorPopupOpen });
    this.setState({ vendorError: false });

  };
  onChange1 = (e) => {
    let onlyNums = e.target.value.replace(/[^.%\d]?(\.\%)/g, "");
    e.target.value = isNaN(parseFloat(onlyNums)) ? "" : parseFloat(onlyNums);
    var str = "";
    var isperc = false;
    if (onlyNums.indexOf(".") !== -1) {
      var arr = onlyNums.split(".");
      if (arr[1].length > 2) {
        if (arr[1].indexOf("%") !== -1) isperc = true;
        arr[1] = arr[1].slice(0, 2);
      }
      if (isperc) e.target.value = arr[0] + "." + arr[1] + "%";
      else e.target.value = arr[0] + "." + arr[1];
    }
    if (onlyNums.indexOf("%") !== -1) {
      str = onlyNums.indexOf("%");
      var start = str + 1;
      onlyNums = e.target.value.slice(0, start);
      e.target.value = onlyNums;
    }
  };
  onChange = (e) => {
    let fields = this.state.fields;
    const specialCharacterNotAllowedZipCode = ['^', '%', '&'];
    // console.log(e.target.name);
    if (e.target.name === 'city') {
      fields[e.target.name] = e.target.value.replace(/[^a-zA-Z]/g, '');
    }
    if (e.target.name === 'hrRate') {
      fields[e.target.name] = e.target.value.replace(/[^0-9\.]/g, '');
      if (e.target.value.split('.').length > 2)
        fields[e.target.name] = e.target.value.replace(/\.+$/, "");
    }

    if (e.target.name === 'bonusPay') {
      fields[e.target.name] = e.target.value.replace(/[^0-9\.]/g, '');
      if (e.target.value.split('.').length > 2)
        fields[e.target.name] = e.target.value.replace(/\.+$/, "");
    }

    else if (e.target.name === 'degree') {
      fields[e.target.name] = e.target.value.replace(/[^a-z A-Z.,]/g, '');
    }

    else if (e.target.name === 'address1') {
      fields[e.target.name] = e.target.value.replace(/[!@#$%^&*()"><=+|\\`~?]/g, '');
    }


    else if (e.target.name === 'comments') {
      fields[e.target.name] = e.target.value.replace(/[^a-z A-Z]/g, '');
    }
    else if (e.target.name === 'zipcode' && specialCharacterNotAllowedZipCode.includes(e.target.value)) {
      return
    }
    else if (e.target.name === 'address1' && e.target.value.length > 60) {
      return
    }
    else if (e.target.name === 'employeeId') {
      // console.log(e.target.value);

      if (/^[ A-Za-z0-9_.'#!-]*$/.test(e.target.value)) {
        fields[e.target.name] = e.target.value;
      }
      else {
        return
      }


    }
    else {
      fields[e.target.name] = e.target.value;
    }
    this.setState({
      fields,
    });
  };
  fNameChange = (e) => {
    const value = e.target.value;
    if (value === "" || (nameRegex.test(value) && value.length <= 40)) {
      this.setState({
        fName: value,
      });
    }
  };
  lNameChange = (e) => {
    const value = e.target.value;
    if (value === "" || (nameRegex.test(value) && value.length <= 40)) {
      this.setState({
        lName: value,
      });
    }
  };

  onChangeVendors = (e) => {
    let vendorform = this.state.vendorform;
    vendorform[e.target.name] = e.target.value;
    this.setState({
      vendorform,
    });
  };

  onChangeNewVendor = (e) => {
    let newVendorform = this.state.newVendorForm;
    if (e.target.name === 'newVendorName') {

      if (e.target.value.length > 25) {
        e.target.value = e.target.value.substr(0, 25);
      }
      newVendorform[e.target.name] = e.target.value.replace(/[^a-z A-Z]/g, '')
    }
    else {
      newVendorform[e.target.name] = e.target.value;
    }
    this.setState({
      newVendorform,
    });
  };

  dropDownChangeVendorWeekformat = (e) => {
    let temp = {};
    temp[e.target.name] = e.target.value;
    this.setState(temp);
    if (
      e.target.value !== "" &&
      e.target.value !== undefined &&
      e.target.value !== null
    ) {
      this.setState({ newVendorWeekFormatError: false });
    } else {
      this.setState({ newVendorWeekFormatError: true });
    }
  };

  dropDownChangeVendorName = (e) => {
    this.setState({
      vendorIdEdit: e.target.value
    })

    if (this.state.open) {
      this.setState(
        {
          vendorId: this.state.vendorId,
        });
    }


    else {
      let temp = {};
      temp[e.target.name] = e.target.value;

      if (e.target.value === this.state.vendorIdActual) {

        this.setState({ disableWeekFormat: true })
        this.setState({ tmsFormatError: false });
        this.setState({ weekFormatId: this.state.initialEditWeekFormatId, vendorId: this.state.vendorIdActual })
        this.setState({ isVendorDateChangeShown: false });
        this.setState({ newVendorChangeDate: null })
      }

      else {
        this.setState(temp);
        this.setState({ weekFormatId: null })
        this.setState({ disableWeekFormat: false })
        this.setState({ tmsFormatError: true });
        this.setState({ isVendorDateChangeShown: true });
        this.setState({ newVendorDateChangeError: true });

        if (
          e.target.value !== "" &&
          e.target.value !== undefined &&
          e.target.value !== null
        ) {
          this.setState({ vendorError: false });
        } else {
          this.setState({ vendorError: true });
        }
      }

    }


  };
  dropDownChange = (e) => {
    let temp = {};
    temp[e.target.name] = e.target.value;
    this.setState(temp);
    if (
      e.target.value !== "" &&
      e.target.value !== undefined &&
      e.target.value !== null
    ) {
      this.setState({ hasError: false });
    } else {
      this.setState({ hasError: true });
    }
  };
  dropDownChangeWeekformat = (e) => {
    let temp = {};
    temp[e.target.name] = e.target.value;
    this.setState(temp);

    if (
      e.target.value !== "" &&
      e.target.value !== undefined &&
      e.target.value !== null
    ) {
      this.setState({ tmsFormatError: false });
    } else {
      this.setState({ tmsFormatError: true });
    }
  };

  dropAttachments = (e) => {
    let temp = {};
    temp[e.target.name] = e.target.value;
    this.setState(temp);
    if (
      e.target.value !== "" &&
      e.target.value !== undefined &&
      e.target.value !== null
    ) {
      this.setState({ attachmentError: false });
    } else {
      this.setState({ attachmentError: true });
    }
  };
  approverdropDownChange = (e) => {
    let temp = {};
    temp[e.target.name] = e.target.value;
    this.setState(temp);
    if (
      e.target.value !== "" &&
      e.target.value !== undefined &&
      e.target.value !== null
    ) {
      this.setState({ approverError: false });
    } else {
      this.setState({ approverError: true });
    }
  };
  invoicedropDownChange = (e) => {
    let temp = {};
    temp[e.target.name] = e.target.value;
    this.setState(temp);
    if (
      e.target.value !== "" &&
      e.target.value !== undefined &&
      e.target.value !== null
    ) {
      this.setState({ invoiceError: false });
    } else {
      this.setState({ invoiceError: true });
    }
  };
  statusdropDownChange = (e) => {
    let temp = {};
    temp[e.target.name] = e.target.value;
    this.setState(temp);
    if (
      e.target.value !== "" &&
      e.target.value !== undefined &&
      e.target.value !== null
    ) {
      this.setState({ statusError: false });
    } else {
      this.setState({ statusError: true });
    }
  };
  setMinYear() {
    var currentDate = new Date();
    let changedYear = currentDate.setFullYear(currentDate.getFullYear() - 18);
    let minYearValue = this.formatDate(changedYear);
    return minYearValue;
  }
  formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }
  setVendorEmail = (e) => {
    if (
      e.target.value !== null &&
      e.target.value !== undefined &&
      e.target.value !== ""
    ) {
      this.setState({ vendorError: false });
    } else {
      this.setState({ vendorError: true });
    }
    this.state.vendordetails.map((data) => {
      if (data.vendorId === e.target.value) {
        this.setState({
          vendorEmail: data.vendorEmail,
        });
      }
    });
  };
  handleChange = (event) => {
    this.setState({
      selectedValue: event.target.value,

    });
  };
  createVendorName = () => {
    let vendorsObject = {
      vendorName: this.state.newVendorForm.newVendorName,
      orgId: this.state.loginDetails.userDetails.orgId,
      vendorEmail: this.state.newVendorForm.newVendorEmail,
      weekFormatId: this.state.newVendorWeekFormatId,
    };
    TimeSheetServices.create_vendorApi(vendorsObject)
      .then((vendorResponce) => {
        if (vendorResponce.data.responseCode === 201) {
          this.getvendor("createVendor");
          this.setState({ weekFormatId: this.state.newVendorWeekFormatId });
          this.setState({ vendorIdOnNewVendorPopupOpen: null });
          this.setState({ newVendorForm: {} })
          toast.success("New vendor is created", toastOptions);
          this.handleClose();
        } else {
          toast.success("Failed to Create vendor", toastOptions);
        }
      })
      .catch((error) => { });
  };


  //Get employee data
  getvendor = (type) => {
    const id = {
      orgId: this.state.loginDetails.userDetails.orgId,
    };
    if (this.state.viewUserDetails.isFrom !== "createUser") {
      this.setState({ loader: true });
    }
    TimeSheetServices.getvendor(id)
      .then((response) => {

        let vendordetails = response.data.vendorDetails;
        this.setState({ vendordetails: vendordetails });
        this.setState({
          weekfo: response?.data.weekFormatDetails,
        });

        if (
          this.state.viewUserDetails.isFrom === "editUser" &&
          type !== "createVendor"
        ) {
          this.setState({
            disableDropdownvar: true,
            disableWeekFormat: true,
          });

          let userId = this.state.viewUserDetails.userId;
          TimeSheetServices.get_userApi(userId)
            .then((res) => {
              this.setState({ loader: false });
              let userDetails = res.data.userDetails[0];
              // console.log(res.data.vendorChangeDate);
              let vendorEmail = "";
              let vendorIdActual = "";
              // console.log(res.data.userDetails.vendorChangeDate);

              if (
                userDetails.roleDesc === "Employee" ||
                userDetails.roleDesc === "Contractor"
              ) {
                let reqVendor = this.state.vendordetails.find(
                  (x) => x.vendorName === userDetails.vendorName
                );
                vendorEmail = reqVendor.vendorEmail;
                vendorIdActual = reqVendor.vendorId;
                // editVendorId = reqVendor.vendorId;
              }
              let pageRenderId;
              switch (userDetails.roleDesc) {
                case "Admin":
                  pageRenderId = 1;
                  break;
                case "Employee":
                  pageRenderId = 2;
                  break;
                case "Approver":
                  pageRenderId = 3;
                  break;
                case "External Approver":
                  pageRenderId = 4;
                  break;
                case "Contractor":
                  pageRenderId = 5;
                  break;
                default:
                  break;
              }
              this.setState({
                fields: userDetails,
                fName: userDetails.firstName,
                lName: userDetails.lastName,
                userCategory: userDetails.userCategory,
                weekFormatId: userDetails.empWeekStart,
                initialEditWeekFormatId: userDetails.empWeekStart,
                attachements:
                  userDetails.roleDesc === "Employee" ||
                    userDetails.roleDesc === "Contractor"
                    ? userDetails.isAttchamentRequired.toString()
                    : userDetails.isAttchamentRequired,
                workStatus: userDetails.workStatus,
                approverId: userDetails.approverId,
                invoiceFrequency: userDetails.invoiceFrequency,
                vendorId: vendorIdActual,
                vendorIdActual: vendorIdActual,
                vendorEmail: vendorEmail,
                empDob: userDetails.dob,
                empJoinDate: userDetails.joiningDate,
                vendorDate: userDetails.newRateEffectiveDate,
                statusDate: userDetails.statusExpiryDate,
                userDetails: userDetails,
                selectedValue: pageRenderId,
                vendorChangeDate: moment(res.data.vendorChangeDate).add(1, 'days').format("YYYY-MM-DD")

              });
            })
            .catch((error) => { });

        } else if (
          this.state.viewUserDetails.isFrom === "viewUser" &&
          type !== "createVendor"
        ) {
          this.setState({
            disableDropdownvar: true,
          });
          this.setState({
            disableView: true,
          });
          let userId = this.state.viewUserDetails.userId;
          TimeSheetServices.get_userApi(userId)
            .then((res) => {

              this.setState({ loader: false });
              let userDetails = res.data.userDetails[0];
              this.setState({statusReason : userDetails.statusReason})
              // console.log(this.state.statusReason);

              let vendorEmail = "";
              let vendorIdActual = "";
              if (
                userDetails.roleDesc === "Employee" ||
                userDetails.roleDesc === "Contractor"
              ) {
                let reqVendor = this.state.vendordetails.find(
                  (x) => x.vendorName === userDetails.vendorName
                );
                vendorEmail = reqVendor.vendorEmail;
                vendorIdActual = reqVendor.vendorId;
              }
              let pageRenderId;
              switch (userDetails.roleDesc) {
                case "Admin":
                  pageRenderId = 1;
                  break;
                case "Employee":
                  pageRenderId = 2;
                  break;
                case "Approver":
                  pageRenderId = 3;
                  break;
                case "External Approver":
                  pageRenderId = 4;
                  break;
                case "Contractor":
                  pageRenderId = 5;
                  break;
                default:
                  break;
              }
              this.setState({
                fields: userDetails,
                fName: userDetails.firstName,
                lName: userDetails.lastName,
                userCategory: userDetails.userCategory,
                weekFormatId: userDetails.empWeekStart,
                attachements:
                  userDetails.roleDesc === "Employee" ||
                    userDetails.roleDesc === "Contractor"
                    ? userDetails.isAttchamentRequired.toString()
                    : userDetails.isAttchamentRequired,
                workStatus: userDetails.workStatus,
                approverId: userDetails.approverId,
                invoiceFrequency: userDetails.invoiceFrequency,
                vendorId: vendorIdActual,
                vendorIdActual: vendorIdActual,
                vendorEmail: vendorEmail,
                empDob: userDetails.dob,
                empJoinDate: userDetails.joiningDate,
                vendorDate: userDetails.newRateEffectiveDate,
                statusDate: userDetails.statusExpiryDate,
                userDetails: userDetails,
                selectedValue: pageRenderId,
              });
            })
            .catch((error) => {
              this.setState({ loader: false });
            });
        } else {
          if (type !== "componetDidmount") {
            this.setState({
              vendorId: vendordetails[0].vendorId,
              vendorEmail: vendordetails[0].vendorEmail,
            });
            this.setState({ vendorError: false });
          }
          this.setState({ loader: false });
        }
      })
      .catch((error) => {
        this.setState({ loader: false });
      });
  };


  componentDidMount() {
    const id = {
      orgId: this.state.loginDetails.userDetails.orgId,
    };
    TimeSheetServices.getWeekFormat()
      .then((response) => {
        if (response?.data.responseCode === 200) {
          // this.state.weekFormatDropdownData = response?.data.weekFormatDetails
          this.setState({
            weekFormatDropdownData: response?.data.weekFormatDetails,
          });
        }
      })
      .catch((error) => { });

    if (this.state.isVendorIdSame) {
      this.getvendor("componentDidmount");
    }

    TimeSheetServices.getInvoiceFrequency()
      .then((response) => {
        if (response?.data.responseCode === 200) {
          this.setState({
            invoiceFrequencyDropdownData: response?.data.invoiceFrequency,
          })
        }
      })

    this.getvendor("componetDidmount");
    TimeSheetServices.getapprover(id)
      .then((response) => {
        this.setState({ apporoverdetails: response.data.apporoversDetails });
      })
      .catch((error) => { });
  }
  //Create employee information

  register = (e) => {
    e.preventDefault();
    if (this.state.selectedValue === 2 || this.state.selectedValue === 5) {
      if (
        this.state.hasError === true ||
        this.state.statusError === true ||
        this.state.approverError === true ||
        this.state.invoiceError === true ||
        this.state.vendorError === true ||
        this.state.attachmentError === true
      ) {
        return false;
      }
      if (
        this.state.empDob !== null &&
        this.state.empDob !== undefined &&
        this.state.empDob !== "" &&
        this.state.empDob !== "1970-01-01" &&
        this.state.empJoinDate !== null &&
        this.state.empJoinDate !== undefined &&
        this.state.empJoinDate !== "" &&
        this.state.empJoinDate !== "1970-01-01" &&
        this.state.statusDate !== null &&
        this.state.statusDate !== undefined &&
        this.state.statusDate !== "" &&
        this.state.statusDate !== "1970-01-01"
      ) {
      } else {
        if (
          this.state.empDob !== null &&
          this.state.empDob !== undefined &&
          this.state.empDob !== "" &&
          this.state.empDob !== "1970-01-01"
        ) {
          this.setState({ dobError: false });
        } else {
          this.setState({ dobError: true });
        }
        if (
          this.state.empJoinDate !== null &&
          this.state.empJoinDate !== undefined &&
          this.state.empJoinDate !== "" &&
          this.state.empJoinDate !== "1970-01-01"
        ) {
          this.setState({ joinError: false });
        } else {
          this.setState({ joinError: true });
        }
        if (
          this.state.statusDate !== null &&
          this.state.statusDate !== undefined &&
          this.state.statusDate !== "" &&
          this.state.statusDate !== "1970-01-01"
        ) {
          this.setState({ expiryError: false });
        } else {
          this.setState({ expiryError: true });
        }
        return false;
      }
    }
    // this.setState({
    //   // empDob: this.convert(this.state.empDob),
    //   // empJoinDate: this.convert(this.state.empJoinDate),
    //   // vendorDate: this.convert(this.state.vendorDate),
    //   // statusDate: this.convert(this.state.statusDate),
    // });

    let user = {
      orgId: this.state.loginDetails.userDetails.orgId,
      userType: this.state.selectedValue,
      firstName: this.state.fName,
      lastName: this.state.lName,
      email: this.state.fields.email,
      dob: this.state.empDob === "1970-01-01" ? null : this.state.empDob,
      joiningDate:
        this.state.empJoinDate === "1970-01-01" ? null : this.state.empJoinDate,
      address1: this.state.fields.address1,
      city: this.state.fields.city,
      zipcode: this.state.fields.zipcode,
      state: this.state.fields.state,
      country: this.state.fields.country,
      documentsLink: this.state.fields.documentsLink,
      statusExpiryDate:
        this.state.statusDate === "1970-01-01" ? null : this.state.statusDate,
      degree: this.state.fields.degree,
      vendorId: this.state.vendorId,
      userCategory: this.state.userCategory,
      empWeekStart: this.state.weekFormatId,
      weekFormatId: (this.state.selectedValue === 1 || this.state.selectedValue === 3 || this.state.selectedValue === 4) ? 0 : this.state.weekFormatId,
      isAttchamentRequired: Number(this.state.attachements),
      basicPay: this.state.fields.basicPay,
      employeeId: this.state.fields.employeeId,
      bonusPay:
        this.state.fields.bonusPay === "" ? null : this.state.fields.bonusPay,
      invoiceFrequency: this.state.invoiceFrequency,
      invoiceDiscount:
        this.state.fields.invoiceDiscount === ""
          ? null
          : this.state.fields.invoiceDiscount,
      paymentTerms: this.state.fields.paymentTerms,
      comments: this.state.fields.comments,
      hrRate: this.state.fields.hrRate,
      otRate: this.state.fields.otRate === "" ? null : this.state.fields.otRate,
      newRateEffectiveDate:
        this.state.vendorDate === "1970-01-01" ? null : this.state.vendorDate,
      newHrRate:
        this.state.fields.newHrRate === "" ? null : this.state.fields.newHrRate,
      newOtRate:
        this.state.fields.newOtRate === "" ? null : this.state.fields.newOtRate,
      approverId: this.state.approverId,
      newInvoiceDiscount:
        this.state.fields.newInvoiceDiscount === ""
          ? null
          : this.state.fields.newInvoiceDiscount,
      workStatus: this.state.workStatus,
      clientName: this.state.fields.clientName,
    };
    this.setState({ loader: true });
    TimeSheetServices.addEmp(user)
      .then((res) => {
        if (res.data.responseCode === 201) {
          this.setState({ loader: false });
          toast.success(res.data.details, toastOptions);
          this.props.history.push("/Timesheeets/viewUsers");
        } else if (res.data.responseCode === 302) {
          this.setState({ loader: false });
          toast.error(res.data.details, toastOptions);
        } else {
          toast.error("Unable to save user", toastOptions);
        }
      })
      .catch((error) => {
        this.setState({ loader: false });
        toast.error(error, toastOptions);
      });
  };
  //Update employee information
  updateuser = (e) => {
    e.preventDefault();
    // this.setState({
    //   empDob: this.convert(this.state.empDob),
    //   empJoinDate: this.convert(this.state.empJoinDate),
    //   vendorDate: this.convert(this.state.vendorDate),
    //   statusDate: this.convert(this.state.statusDate),
    // });
    if (this.state.selectedValue === 2 || this.state.selectedValue === 5) {
      let user = {
        orgId: this.state.loginDetails.userDetails.orgId,
        userType: this.state.selectedValue,
        firstName: this.state.fName,
        lastName: this.state.lName,
        email: this.state.fields.email,
        dob: this.state.empDob === "1970-01-01" ? null : this.state.empDob,
        joiningDate:
          this.state.empJoinDate === "1970-01-01"
            ? null
            : this.state.empJoinDate,
        address1: this.state.fields.address1,
        city: this.state.fields.city,
        zipcode: this.state.fields.zipcode,
        state: this.state.fields.state,
        country: this.state.fields.country,
        documentsLink: this.state.fields.documentsLink,
        statusExpiryDate:
          this.state.statusDate === "1970-01-01" ? null : this.state.statusDate,
        degree: this.state.fields.degree,
        vendorId: this.state.vendorId,
        userCategory: this.state.userCategory,
        weekFormatId: this.state.weekFormatId,
        isAttchamentRequired: Number(this.state.attachements),
        basicPay: this.state.fields.basicPay,
        employeeId: this.state.fields.employeeId,
        bonusPay:
          this.state.fields.bonusPay === "" ? null : this.state.fields.bonusPay,
        invoiceFrequency: this.state.invoiceFrequency,
        newInvoiceDiscount:
          this.state.fields.newInvoiceDiscount === ""
            ? null
            : this.state.fields.newInvoiceDiscount,
        invoiceDiscount:
          this.state.fields.invoiceDiscount === ""
            ? null
            : this.state.fields.invoiceDiscount,
        paymentTerms: this.state.fields.paymentTerms,
        comments: this.state.fields.comments,
        hrRate: this.state.fields.hrRate,
        otRate:
          this.state.fields.otRate === "" ? null : this.state.fields.otRate,
        newRateEffectiveDate:
          this.state.vendorDate === "1970-01-01" ? null : this.state.vendorDate,
        newHrRate:
          this.state.fields.newHrRate === ""
            ? null
            : this.state.fields.newHrRate,
        newOtRate:
          this.state.fields.newOtRate === ""
            ? null
            : this.state.fields.newOtRate,
        approverId: this.state.approverId,
        workStatus: this.state.workStatus,
        clientName: this.state.fields.clientName,
        userId: this.state.viewUserDetails.userId,
        newVendorChangeDate: this.state.newVendorChangeDate
      };
      this.setState({ loader: true });
      let userId = this.state.viewUserDetails.userId;

      TimeSheetServices.edit_userApi(userId, user)
        .then((res) => {
          if (res.data.responseCode === 200) {
            this.setState({ loader: false });
            toast.success(res.data.details, toastOptions);
            this.props.history.push("/Timesheeets/viewUsers");
          } else if (res.data.responseCode === 404) {
            this.setState({ loader: false });
            toast.error(res.data.details, toastOptions);
          } else {
            toast.error("Unable to save user", toastOptions);
          }
        })
        .catch((error) => {
          this.setState({ loader: false });
          toast.error(error, toastOptions);
        });
    } else {
      let user = {
        orgId: this.state.loginDetails.userDetails.orgId,
        userType: this.state.selectedValue,
        firstName: this.state.fName,
        lastName: this.state.lName,
        email: this.state.fields.email,
        address1: this.state.fields.address1,
        city: this.state.fields.city,
        zipcode: this.state.fields.zipcode,
        state: this.state.fields.state,
        country: this.state.fields.country,
      };
      this.setState({ loader: true });
      let userId = this.state.viewUserDetails.userId;
      TimeSheetServices.edit_userApi(userId, user)
        .then((res) => {
          if (res.data.responseCode === 200) {
            this.props.updateLoginDetails(
              userId,
              this.state.fName,
              this.state.lName
            );
            this.setState({ loader: false });
            toast.success(res.data.details, toastOptions);
            this.props.history.push("/Timesheeets/viewUsers");
          } else if (res.data.responseCode === 404) {
            this.setState({ loader: false });
            toast.error(res.data.details, toastOptions);
          } else {
            toast.error("Unable to save user", toastOptions);
          }
        })
        .catch((error) => {
          this.setState({ loader: false });
          toast.error(error, toastOptions);
        });
    }
  };

  email_validation_fun = (event) => {
    this.setState({ isEmailValid: true });
    let EMAILPATTERN = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    let pattern = EMAILPATTERN.test(event.target.value);
    if (pattern) {
      TimeSheetServices.email_validateApi(this.state.fields.email)
        .then((responce) => {
          if (responce.data.responseCode === 302) {
            this.setState({
              disable: true,
            });
            this.setState({ isEmailValid: false });
          } else if (responce.data.responseCode === 404) {
            this.setState({
              disable: false,
            });
            this.setState({ isEmailValid: true });
          }
        })
        .catch((error) => { });
    }
  };

  vendoremail_validation_fun = (event) => {
    let EMAILPATTERN = /^(?!.*@.*@)[a-zA-Z0-9.-_]{1,}@{1,}[a-zA-Z.-]{1,}[.]{1}[a-zA-Z]{2,}/;
    let pattern = EMAILPATTERN.test(event.target.value);
    if (pattern) {
      TimeSheetServices.vendorEmail_validateApi(
        this.state.vendorform.vendorEmail
      )
        .then((responce) => {
          if (responce.data.responseCode === 302) {
            this.setState({
              disableVendor: true,
            });
            this.setState({ isEmailValid: false });
          } else if (responce.data.responseCode === 404) {
            this.setState({
              disableVendor: false,
            });
            this.setState({ isEmailValid: true });
          }
        })
        .catch((error) => { });
    }
  };

  empId_validation_fun = (event) => {
    TimeSheetServices.employee_id_validation(this.state.fields.employeeId)
      .then((responce) => {
        if (responce.data.responseCode === 302) {
          this.setState({
            disable: true,
          });
          this.setState({ isEmpIdValid: false });
        } else if (responce.data.responseCode === 404) {
          this.setState({
            disable: false,
          });
          this.setState({ isEmpIdValid: true });
        }
      })
      .catch((error) => { });
  };

  cancelHandler = () => {
    this.props.history.push("/Timesheeets/viewUsers");
  };
  convert = (str) => {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  };
  // convert = (str) => {
  //   // var date = new Date(str),
  //     var date = str;
  //    //   mnth = ("0" + (date.getMonth() + 1)).slice(-2),
  //    //   day = ("0" + date.getDate()).slice(-2);
  //    // return [date.getFullYear(), mnth, day].join("-");
  //    return moment(date).format("YYYY-MM-DD");

  //  };
  allow_numbers_dot = (e) => {
    if (e.key !== "Tab") {
      !/[0-9/.]$/.test(e.key) && e.keyCode !== 8 && e.preventDefault();
    }
  };
  allow_numbers = (e) => {
    if (e.key !== "Tab") {
      !/[0-9]$/.test(e.key) && e.target.value !== '&' && e.target.value !== '^' && e.keyCode !== 8 && e.preventDefault();
    }
  };

  allow_text = (e) => {
    !/[a-z A-Z]$/.test(e.key) && e.keyCode !== 8 && e.preventDefault();
  };

  allow_text_few_special_characters = (e) => {
    !/[a-z A-Z]$/.test(e.key) && e.keyCode !== 8 && e.target.value === '-' && e.preventDefault();
  };


  handleChangeDate = (date) => {
    if (
      date !== null &&
      date !== undefined &&
      date !== "" &&
      date !== "1970-01-01"
    ) {
      this.setState({ dobError: false });
    } else {
      this.setState({ dobError: true });
    }
    const edate = this.convert(date);
    this.setState({ empDob: edate });
  };
  handleChangeJoinDate = (date) => {
    if (
      date !== null &&
      date !== undefined &&
      date !== "" &&
      date !== "1970-01-01"
    ) {
      this.setState({ joinError: false });
    } else {
      this.setState({ joinError: true });
    }
    const edate = this.convert(date);
    this.setState({ empJoinDate: edate });
  };

  handleChangeVendorDate = (vendor) => {
    const vdate = this.convert(vendor);
    this.setState({ vendorDate: vdate });
  };

  handleNewVendorChangeDate = (newVendorChangeDateValue) => {
    const newVendorChangeDate = this.convert(newVendorChangeDateValue);
    this.setState({ vendorDate: newVendorChangeDate });
  }

  handleChangeSatusDate = (status) => {
    if (
      status !== null &&
      status !== undefined &&
      status !== "" &&
      status !== "1970-01-01"
    ) {
      this.setState({ expiryError: false });
    } else {
      this.setState({ expiryError: true });
    }
    const sdate = this.convert(status);
    this.setState({ statusDate: sdate });
  };

  handleVendorDateChange = (dateChange) => {
    const newVendorDate = this.convert(dateChange);
    // this.setState({ newVendorChangeDate: newVendorDate });
    debugger;
    if (
      dateChange !== "" &&
      dateChange !== undefined &&
      dateChange !== null
    ) {
      this.setState({ newVendorChangeDate: newVendorDate });
      this.setState({ newVendorDateChangeError: false });
    } else {
      this.setState({ newVendorDateChangeError: true });
    }

  };



  changeTitle = () => {
    if (this.state.viewUserDetails.isFrom === "editUser") {
      return this.state.selectedValue === 4 ||
        this.state.userDetails.roleDesc === "External Approver"
        ? "Modify External Approver"
        : this.state.selectedValue === 3 ||
          this.state.userDetails.roleDesc === "Approver"
          ? "Modify Approver"
          : this.state.selectedValue === 1 ||
            this.state.userDetails.roleDesc === "Admin"
            ? "Modify Admin"
            : this.state.selectedValue === 5 ||
              this.state.userDetails.roleDesc === "Contractor"
              ? "Modify Contractor"
              : "Modify User ";
    } else if (this.state.viewUserDetails.isFrom === "viewUser") {
      return this.state.selectedValue === 4 ||
        this.state.userDetails.roleDesc === "External Approver"
        ? "View External Approver"
        : this.state.selectedValue === 3 ||
          this.state.userDetails.roleDesc === "Approver"
          ? "View Approver"
          : this.state.selectedValue === 1 ||
            this.state.userDetails.roleDesc === "Admin"
            ? "View Admin"
            : this.state.selectedValue === 5 ||
              this.state.userDetails.roleDesc === "Contractor"
              ? "View Contractor"
              : "View User ";
    } else {
      return this.state.selectedValue === 4 ||
        this.state.userDetails.roleDesc === "External Approver"
        ? " Create External Approver"
        : this.state.selectedValue === 3 ||
          this.state.userDetails.roleDesc === "Approver"
          ? "Create Approver"
          : this.state.selectedValue === 1 ||
            this.state.userDetails.roleDesc === "Admin"
            ? "Create Admin"
            : this.state.selectedValue === 5 ||
              this.state.userDetails.roleDesc === "Contractor"
              ? "Create Contractor"
              : "Create User ";
    }
  };
  render() {
    const {
      hasError,
      statusError,
      approverError,
      vendorError,
      invoiceError,
      dobError,
      joinError,
      expiryError,
      tmsFormatError,
      newVendorWeekFormatError,
      newVendorDateChangeError,
      attachmentError,
    } = this.state;
    return (
      <BackgroundCard
        title={this.changeTitle()}
        iconName="creteUser"
        status={this.state.disableView && this.state.viewUserDetails.status}
        statusReason={this.state.disableView && this.state.statusReason}
        closeIcon={
          this.state.viewUserDetails.isFrom === "editUser" ||
            this.state.viewUserDetails.isFrom === "viewUser"
            ? "closeIcon"
            : ""
        }
        closeClick={this.cancelHandler}
      >
        <ValidatorForm
          ref="form"
          onSubmit={
            this.state.viewUserDetails.isFrom === "editUser"
              ? this.updateuser
              : this.register
          }
        >
          {this.state.viewUserDetails.isFrom !== "viewUser" ? (
            <div className="App">
              <DropDown
                autoFocus
                ref={this.clearDropDownElement}
                changed={this.handleChange}
                value={this.state.selectedValue}
                data={userTypeDropDown}
                savedUserType={this.state.selectedValue}
                disableDropdown={this.state.disableDropdownvar}
              />
            </div>
          ) : (
            ""
          )}
          <CreateUserCard
            title={
              this.state.selectedValue === 4 ||
                this.state.userDetails.roleDesc === "External Approver"
                ? "External Approver"
                : this.state.selectedValue === 3 ||
                  this.state.userDetails.roleDesc === "Approver"
                  ? "Approver Information"
                  : this.state.selectedValue === 1 ||
                    this.state.userDetails.roleDesc === "Admin"
                    ? "Admin Information"
                    : this.state.selectedValue === 5 ||
                      this.state.userDetails.roleDesc === "Contractor"
                      ? "Contractor Information"
                      : "Employee Information"
            }
          >
            <Grid container spacing={3}>
              <Grid item xs={12} sm={7} md={4}>
                <TextValidator
                  id="standard-basic"
                  className="textwidth"
                  label={
                    <span>
                      First Name <span style={{ color: "red" }}>*</span>
                    </span>
                  }
                  name="firstName"
                  value={this.state.fName || ""}
                  onChange={this.fNameChange}
                  validators={["required"]}
                  errorMessages={["First name is required"]}
                  autoComplete="new-password"
                  inputProps={{ maxLength: 40 }}
                  styles={customStyles}
                  disabled={this.state.disableView}
                />
              </Grid>

              <Grid item xs={12} sm={7} md={4}>
                <TextValidator
                  id="standard-basic"
                  className="textwidth"
                  label={
                    <span>
                      Last Name<span style={{ color: "red" }}>*</span>
                    </span>
                  }
                  name="lastName"
                  value={this.state.lName || ""}
                  onChange={this.lNameChange}
                  validators={["required"]}
                  errorMessages={["Last name is required"]}
                  autoComplete="new-password"
                  inputProps={{ maxLength: 40 }}
                  disabled={this.state.disableView}
                />
              </Grid>
              <Grid item xs={12} sm={7} md={4}>
                <TextValidator
                  id="standard-basic"
                  className="textwidth"
                  label={
                    <span>
                      Email Address<span style={{ color: "red" }}>*</span>
                    </span>
                  }
                  name="email"
                  type="email"
                  onKeyUp={this.email_validation_fun}
                  value={this.state.fields.email || ""}
                  onChange={this.onChange}
                  validators={["required", "isEmail"]}
                  errorMessages={[
                    "Email address is required",
                    "Enter a valid email address",
                  ]}
                  autoComplete="new-password"
                  disabled={this.state.disableView}
                />
                {this.state.isEmailValid === false ? (
                  <span style={{ color: "red" }}>
                    This email address already exists
                  </span>
                ) : (
                  ""
                )}
              </Grid>
              {this.state.selectedValue === 2 ||
                this.state.selectedValue === 5 ? (
                <Grid item xs={12} sm={7} md={4}>
                  <FormControl error={dobError}>
                    <MaterialUIPickers
                      name="dob"
                      label={
                        <span>
                          DOB<span style={{ color: "red" }}>*</span>
                        </span>
                      }
                      value={this.state.empDob}
                      handleDateChange={this.handleChangeDate}
                      style={{ minWidth: 262 }}
                      readDate={this.state.disableView}
                      disableFuture={true}
                    />
                    {dobError && (
                      <FormHelperText>DOB is required</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              ) : (
                ""
              )}

              <Grid item xs={12} sm={7} md={4}>
                <TextValidator
                  id="standard-basic"
                  className="textwidth"
                  label={
                    <span>
                      Street<span style={{ color: "red" }}>*</span>
                    </span>
                  }
                  name="address1"
                  value={this.state.fields.address1 || ""}
                  onChange={this.onChange}
                  onKeyDown={this.allow_text_few_special_characters}
                  validators={["required"]}
                  errorMessages={["Street is required"]}
                  autoComplete="new-password"
                  inputProps={{ maxLength: 50 }}
                  disabled={this.state.disableView}
                />
              </Grid>
              <Grid item xs={12} sm={7} md={4}>
                <TextValidator
                  id="standard-basic"
                  className="textwidth"
                  label={
                    <span>
                      City<span style={{ color: "red" }}>*</span>
                    </span>
                  }
                  name="city"
                  value={this.state.fields.city || ""}
                  onChange={this.onChange}
                  onKeyDown={this.allow_text}
                  validators={["required"]}
                  errorMessages={["City is required"]}
                  autoComplete="new-password"
                  inputProps={{ maxLength: 25 }}
                  disabled={this.state.disableView}
                />
              </Grid>
              <Grid item xs={12} sm={7} md={4}>
                <TextValidator
                  id="standard-basic"
                  className="textwidth"
                  label={
                    <span>
                      State<span style={{ color: "red" }}>*</span>
                    </span>
                  }
                  name="state"
                  value={this.state.fields.state || ""}
                  onChange={this.onChange}
                  onKeyDown={this.allow_text}
                  validators={["required"]}
                  errorMessages={["State is required"]}
                  autoComplete="new-password"
                  inputProps={{ maxLength: 25 }}
                  disabled={this.state.disableView}
                />
              </Grid>
              <Grid item xs={12} sm={7} md={4}>
                <TextValidator
                  id="standard-basic"
                  className="textwidth"
                  label={
                    <span>
                      Country<span style={{ color: "red" }}>*</span>
                    </span>
                  }
                  name="country"
                  maxLength="6"
                  value={this.state.fields.country || ""}
                  onChange={this.onChange}
                  validators={["required"]}
                  errorMessages={["Country is required"]}
                  autoComplete="new-password"
                  onKeyDown={this.allow_text}
                  inputProps={{ maxLength: 25 }}
                  disabled={this.state.disableView}
                />
              </Grid>
              <Grid item xs={12} sm={7} md={4}>
                <TextValidator
                  id="standard-basic"
                  className="textwidth"
                  label={
                    <span>
                      Zip Code<span style={{ color: "red" }}>*</span>
                    </span>
                  }
                  name="zipcode"
                  onKeyDown={this.allow_numbers}
                  value={this.state.fields.zipcode || ""}
                  onChange={this.onChange}
                  validators={[
                    "required",
                    "minStringLength:5",
                    "maxStringLength:5",
                  ]}
                  errorMessages={[
                    "Zip code is required",
                    "Minimum 5 digits required",
                    "Maximum 5 digits required",
                  ]}
                  autoComplete="new-password"
                  inputProps={{ maxLength: 5 }}
                  disabled={this.state.disableView}
                />
              </Grid>
              {this.state.selectedValue === 2 ||
                this.state.selectedValue === 5 ? (
                <Grid item xs={12} sm={7} md={4}>
                  <FormControl error={hasError}>
                    <InputLabel htmlFor="name">
                      Category<span style={{ color: "red" }}>*</span>
                    </InputLabel>
                    <Select
                      name="userCategory"
                      value={this.state.userCategory || ""}
                      onChange={this.dropDownChange}
                      disabled={this.state.disableView}
                    >
                      <MenuItem value="R">Regular</MenuItem>
                      <MenuItem value="S">Special</MenuItem>
                      <MenuItem value="M">Monthly</MenuItem>
                      <MenuItem value="N">0822</MenuItem>
                    </Select>
                    {hasError && (
                      <FormHelperText>Category is required</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              ) : (
                ""
              )}

              {this.state.selectedValue === 2 ||
                this.state.selectedValue === 5 ? (
                <Grid item xs={12} sm={7} md={4}>
                  <TextValidator
                    id="standard-basic"
                    className="textwidth"
                    label={
                      <span>
                        Emp Basic pay ($/hr)<span style={{ color: "red" }}>*</span>
                      </span>
                    }
                    name="basicPay"
                    value={
                      this.state.fields.basicPay === 0
                        ? "0"
                        : this.state.fields.basicPay || ""
                    }
                    onChange={this.onChange}
                    onKeyDown={this.allow_numbers_dot}
                    validators={["required"]}
                    errorMessages={["Emp basic pay is required"]}
                    autoComplete="new-password"
                    inputProps={{ maxLength: 6 }}
                    disabled={this.state.disableView}
                  />
                </Grid>
              ) : (
                ""
              )}

              {this.state.selectedValue === 2 ||
                this.state.selectedValue === 5 ? (
                <Grid item xs={12} sm={7} md={4}>
                  <TextValidator
                    id="standard-basic"
                    className="textwidth"
                    label="Emp Bonus Pay"
                    name="bonusPay"
                    // onKeyDown={this.allow_numbers}
                    value={
                      this.state.fields.bonusPay === 0
                        ? "0"
                        : this.state.fields.bonusPay || ""
                    }
                    onChange={this.onChange}
                    autoComplete="new-password"
                    inputProps={{ maxLength: 6 }}
                    disabled={this.state.disableView}
                  />
                </Grid>
              ) : (
                ""
              )}

              {this.state.selectedValue === 2 ||
                this.state.selectedValue === 5 ? (
                <Grid item xs={12} sm={7} md={4}>
                  <FormControl error={approverError}>
                    <InputLabel htmlFor="name">
                      Approver Name<span style={{ color: "red" }}>*</span>
                    </InputLabel>
                    <Select
                      name="approverId"
                      value={this.state.approverId || ""}
                      onChange={this.approverdropDownChange}
                      disabled={this.state.disableView}
                    >
                      {this.state.apporoverdetails?.map((data) => (
                        <MenuItem value={data.userId}>
                          {data.firstName}-{data.lastName}
                        </MenuItem>
                      ))}
                    </Select>
                    {approverError && (
                      <FormHelperText>Approver name is required</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              ) : (
                ""
              )}
              {this.state.selectedValue === 2 ||
                this.state.selectedValue === 5 ? (
                <Grid item xs={12} sm={7} md={4}>
                  <FormControl error={joinError}>
                    <MaterialUIPickers
                      name="empJoinDate"
                      label={
                        <span>
                          Joining Date<span style={{ color: "red" }}>*</span>
                        </span>
                      }
                      //minDate={this.setMinYear()}
                      value={this.state.empJoinDate}
                      handleDateChange={this.handleChangeJoinDate}
                      style={{ minWidth: 262 }}
                      readDate={this.state.disableView}
                      disableFuture={true}
                    />
                    {joinError && (
                      <FormHelperText>Joining date is required</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              ) : (
                ""
              )}
              {this.state.selectedValue === 2 ||
                this.state.selectedValue === 5 ? (
                <Grid item xs={12} sm={7} md={4}>
                  <TextValidator
                    id="standard-basic"
                    className="textwidth"
                    label={
                      <span>
                        Employee Id<span style={{ color: "red" }}>*</span>
                      </span>
                    }
                    name="employeeId"
                    onKeyUp={this.empId_validation_fun}
                    value={this.state.fields.employeeId || ""}
                    onChange={this.onChange}
                    validators={["required"]}
                    errorMessages={["EmployeeId is required"]}
                    autoComplete="new-password"
                    inputProps={{ maxLength: 10 }}
                    disabled={this.state.disableView}
                  />
                  {this.state.isEmpIdValid === false ? (
                    <span style={{ color: "red" }}>
                      This employee id already existed
                    </span>
                  ) : (
                    ""
                  )}
                </Grid>
              ) : (
                ""
              )}

              {this.state.selectedValue === 2 ||
                this.state.selectedValue === 5 ? (
                <Grid item xs={12} sm={7} md={4}>
                  <FormControl error={attachmentError}>
                    <InputLabel htmlFor="name">
                      Attachments<span style={{ color: "red" }}>*</span>
                    </InputLabel>
                    <Select
                      name="attachements"
                      value={this.state.attachements || ""}
                      onChange={this.dropAttachments}
                      disabled={this.state.disableView}
                    >
                      <MenuItem value="1">YES</MenuItem>
                      <MenuItem value="0">NO</MenuItem>
                    </Select>
                    {attachmentError && (
                      <FormHelperText>
                        Attachment value is required
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              ) : (
                ""
              )}


            </Grid>
          </CreateUserCard>
          {this.state.selectedValue === 2 || this.state.selectedValue === 5 ? (
            <CreateUserCard title="Vendor Information">
              <Grid item xs={12} sm={7} md={4}>
                <FormControl error={vendorError}>
                  <InputLabel htmlFor="name">
                    Vendor Name<span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Select
                    name="vendorId"
                    value={this.state.vendorId || ""}
                    onChange={(e) => {
                      this.dropDownChangeVendorName(e);
                      this.setVendorEmail(e);
                    }}
                    disabled={this.state.disableView}
                    MenuProps={MenuProps}
                  >
                    <MenuItem
                      style={{ position: "sticky" }}
                      onClick={this.handleClickOpen}
                    >
                      <button className="Createuserbutton">
                        <AddCircleOutlineIcon
                          style={{
                            float: "left",
                            color: "white",
                            marginTop: 1,
                            fontSize: 22,
                          }}
                        />
                        New Vendor
                      </button>
                    </MenuItem>
                    {this.state.vendordetails?.map((data) => (
                      <MenuItem value={data.vendorId}>
                        {data.vendorName}
                      </MenuItem>
                    ))}
                  </Select>
                  {vendorError && (
                    <FormHelperText>Vendor name is required</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={7} md={4}>
                <TextValidator
                  readOnly
                  id="vendoremail"
                  label={
                    <span>
                      Vendor Email<span style={{ color: "red" }}>*</span>
                    </span>
                  }
                  className="textwidth"
                  name="vendorEmail"
                  value={this.state.vendorEmail || ""}
                  validators={["required"]}
                  errorMessages={["Vendor email is required"]}
                  autoComplete="new-password"
                  disabled={this.state.disableView}
                />
              </Grid>
              {this.state.selectedValue === 2 ||
                this.state.selectedValue === 5 ? (
                <Grid item xs={12} sm={7} md={4}>
                  <FormControl error={tmsFormatError}>
                    <InputLabel htmlFor="name">
                      Week format<span style={{ color: "red" }}>*</span>
                    </InputLabel>
                    <Select
                      className="weekFormatDropdown"
                      disabled={this.state.disableWeekFormat || (this.state.viewUserDetails.isFrom === "viewUser")}
                      name="weekFormatId"
                      value={this.state.weekFormatId || ""}
                      onChange={this.dropDownChangeWeekformat}
                    >
                      {this.state.weekFormatDropdownData.map(
                        (dropdown, index) => {
                          return (
                            <MenuItem value={dropdown.id}>
                              {dropdown.format}
                            </MenuItem>
                          );
                        }
                      )}
                    </Select>
                    {tmsFormatError && (
                      <FormHelperText>Week format is required</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              ) : (
                ""
              )}

              {this.state.isVendorDateChangeShown && this.state.viewUserDetails.isFrom === "editUser" && (
                <Grid item xs={12} sm={7} md={4}>
                  <FormControl error={newVendorDateChangeError}>
                    <MaterialUIPickers
                      name="newVendorChangeDate"
                      label={
                        <span>
                          New Vendor Change Date
                          <span style={{ color: "red" }}>*</span>
                        </span>
                      }

                      value={this.state.newVendorChangeDate}
                      handleDateChange={this.handleVendorDateChange}
                      readDate={this.state.disableView}
                      disableFuture={false}
                      minimumdate={this.state.vendorChangeDate}
                    />
                    {newVendorDateChangeError && (
                      <FormHelperText>New Vendor Change Date is required</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              )}



              {/* <Grid item xs={12} sm={7} md={4} className="weelky">
                <FormControl error={invoiceError}>
                  <InputLabel htmlFor="name">
                    Invoice Period<span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Select
                    name="invoiceFrequency"
                    value={this.state.invoiceFrequency || ""}
                    onChange={this.invoicedropDownChange}
                    // readOnly={this.state.testFieldsDropDown}
                    disabled={this.state.disableView}
                  >
                    {this.state.invoiceFrequencyDropdownData.map((invoiceFrequency,index)=>{
                      return <MenuItem key={index} value ={invoiceFrequency.value}>{invoiceFrequency.name}</MenuItem>
                    })}
                  
                  </Select>
                  {invoiceError && (
                    <FormHelperText>Invoice period is required</FormHelperText>
                  )}
                </FormControl>
              </Grid> */}
              <Grid item xs={12} sm={7} md={4}>
                <TextValidator
                  id="standard-basic"
                  className="textwidth"
                  label={
                    <span>
                      Payment Term<span style={{ color: "red" }}>*</span>
                    </span>
                  }
                  name="paymentTerms"
                  value={this.state.fields.paymentTerms || ""}
                  onChange={this.onChange}
                  validators={["required"]}
                  errorMessages={["Payment term is required"]}
                  autoComplete="new-password"
                  onKeyDown={this.allow_numbers}
                  disabled={this.state.disableView}
                  inputProps={{ maxLength: 3 }}
                />
              </Grid>
              <Grid item xs={12} sm={7} md={4}>
                <TextValidator
                  id="standard-basic"
                  className="textwidth"
                  label={
                    <span>
                      Hourly Rate ($/hr)<span style={{ color: "red" }}>*</span>
                    </span>
                  }
                  name="hrRate"
                  value={
                    this.state.fields.hrRate === 0
                      ? "0"
                      : this.state.fields.hrRate || ""
                  }
                  onChange={this.onChange}
                  validators={["required"]}
                  errorMessages={["Hr rate is required"]}
                  autoComplete="new-password"
                  // onKeyDown={this.allow_numbers}
                  disabled={this.state.disableView}
                  inputProps={{ maxLength: 5 }}
                />
              </Grid>
              <Grid item xs={12} sm={7} md={4}>
                <TextValidator
                  id="standard-basic"
                  className="textwidth"
                  label="OT (Times)"
                  name="otRate"
                  onKeyDown={this.allow_numbers_dot}
                  value={
                    this.state.fields.otRate === 0
                      ? "0"
                      : this.state.fields.otRate || ""
                  }
                  onChange={this.onChange}
                  autoComplete="new-password"
                  disabled={this.state.disableView}
                  inputProps={{ maxLength: 4 }}
                />
              </Grid>
              <Grid item xs={12} sm={7} md={4}>
                <TextValidator
                  id="standard-basic"
                  className="textwidth"
                  label="Invoice discount %"
                  name="invoiceDiscount"
                  onKeyDown={this.allow_numbers_dot}
                  value={
                    this.state.fields.invoiceDiscount === 0
                      ? "0"
                      : this.state.fields.invoiceDiscount || ""
                  }
                  onChange={this.onChange}
                  autoComplete="new-password"
                  disabled={this.state.disableView}
                  inputProps={{ maxLength: 4 }}
                />
              </Grid>
              {/* <Grid item xs={12} sm={7} md={4}>
                <TextValidator
                  id="newinvoice"
                  label="New Invoice discount %"
                  className="textwidth"
                  onKeyDown={this.allow_numbers_dot}
                  name="newInvoiceDiscount"
                  value={
                    this.state.fields.newInvoiceDiscount === 0
                      ? "0"
                      : this.state.fields.newInvoiceDiscount || ""
                  }
                  onChange={this.onChange}
                  autoComplete="new-password"
                  disabled={this.state.disableView}
                  inputProps={{ maxLength: 4 }}
                />
              </Grid> */}

              {/* <Grid item xs={12} sm={7} md={4}>
                <MaterialUIPickers
                  label="New rate effective date"
                  name="newRateEffectiveDate"
                  value={this.state.vendorDate}
                  handleDateChange={this.handleChangeVendorDate}
                  readDate={this.state.disableView}
                  disableFuture={true}
                />
              </Grid> */}
              {/* <Grid item xs={12} sm={7} md={4}>
                <TextValidator
                  id="standard-basic"
                  className="textwidth"
                  label="New Hourly Rate ($)"
                  name="newHrRate"
                  onKeyDown={this.allow_numbers_dot}
                  value={
                    this.state.fields.newHrRate === 0
                      ? "0"
                      : this.state.fields.newHrRate || ""
                  }
                  onChange={this.onChange}
                  autoComplete="new-password"
                  disabled={this.state.disableView}
                  inputProps={{ maxLength: 4 }}
                />
              </Grid> */}
              {/* <Grid item xs={12} sm={7} md={4}>
                <TextValidator
                  id="standard-basic"
                  className="textwidth"
                  label="New OT Rate %"
                  name="newOtRate"
                  onKeyDown={this.allow_numbers_dot}
                  value={
                    this.state.fields.newOtRate === 0
                      ? "0"
                      : this.state.fields.newOtRate || ""
                  }
                  onChange={this.onChange}
                  autoComplete="new-password"
                  disabled={this.state.disableView}
                  inputProps={{ maxLength: 4 }}
                />
              </Grid> */}
              <Grid item xs={12} sm={7} md={4}>
                <TextValidator
                  id="clientname"
                  label="Client Name"
                  className="textwidth"
                  name="clientName"
                  value={this.state.fields.clientName || ""}
                  onChange={this.onChange}
                  autoComplete="new-password"
                  inputProps={{ maxLength: 45 }}
                  disabled={this.state.disableView}
                  onKeyDown={this.allow_text}
                />
              </Grid>
              <Grid item xs={12} sm={7} md={4}>
                <TextValidator
                  id="standard-basic"
                  className="textwidth"
                  label="Comments"
                  name="comments"
                  value={this.state.fields.comments || ""}
                  onChange={this.onChange}
                  autoComplete="new-password"
                  disabled={this.state.disableView}
                  inputProps={{ maxLength: 255 }}
                />
              </Grid>
            </CreateUserCard>
          ) : (
            ""
          )}
          {this.state.selectedValue === 2 || this.state.selectedValue === 5 ? (
            <CreateUserCard title="Employee Status">
              <Grid item xs={12} sm={7} md={4}>
                <TextValidator
                  id="standard-basic"
                  className="textwidth"
                  label={
                    <span>
                      Emp Education<span style={{ color: "red" }}>*</span>
                    </span>
                  }
                  name="degree"
                  value={this.state.fields.degree || ""}
                  onChange={this.onChange}
                  validators={["required"]}
                  errorMessages={["Emp education is required"]}
                  autoComplete="new-password"
                  disabled={this.state.disableView}
                  inputProps={{ maxLength: 30 }}
                />
              </Grid>
              <Grid item xs={12} sm={7} md={4}>
                <FormControl error={statusError}>
                  <InputLabel htmlFor="name">
                    Current Status<span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Select
                    name="workStatus"
                    value={this.state.workStatus || ""}
                    onChange={this.statusdropDownChange}
                    disabled={this.state.disableView}
                  >
                    <MenuItem value="H">H1</MenuItem>
                    <MenuItem value="O">OPT</MenuItem>
                    <MenuItem value="G">GC</MenuItem>
                    <MenuItem value="C">Citizen</MenuItem>
                    <MenuItem value="E">EAD</MenuItem>
                  </Select>
                  {statusError && (
                    <FormHelperText>Current status is required</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={7} md={4}>
                <FormControl error={expiryError}>
                  <MaterialUIPickers
                    name="statusExpiryDate"
                    label={
                      <span>
                        Status Expiry Date
                        <span style={{ color: "red" }}>*</span>
                      </span>
                    }
                    value={this.state.statusDate}
                    handleDateChange={this.handleChangeSatusDate}
                    readDate={this.state.disableView}
                    disableFuture={false}
                  />
                  {expiryError && (
                    <FormHelperText>
                      Status expiry date is required
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={7} md={12}>
                <TextValidator
                  style={{ width: "100%" }}
                  id="standard-basic"
                  label="Google Drive Link"
                  className="textwidth"
                  name="documentsLink"
                  value={this.state.fields.documentsLink || ""}
                  onChange={this.onChange}
                  autoComplete="new-password"
                  disabled={this.state.disableView}
                />
              </Grid>
            </CreateUserCard>
          ) : (
            ""
          )}
          <Grid
            container
            alignitems="flex-start"
            justify="center"
            direction="row"
            style={{ margin: "48px 0px 29px" }}
          >
            {this.state.viewUserDetails.isFrom === "editUser" ? (
              <div style={{ display: "flex", flexDirection: "row" }}>
                <CustomButton
                  btnName="Update"
                  fucntionality="save"
                  type="submit"
                //  disabled={this.state.disable}
                // clicked={() => this.handleClick()}
                />
              </div>
            ) : this.state.viewUserDetails.isFrom === "viewUser" ? (
              ""
            ) : (
              <CustomButton
                btnName="Submit"
                fucntionality="save"
                type="submit"
                disabled={this.state.disable}
                clicked={() => this.handleClick()}
              />
            )}
          </Grid>
        </ValidatorForm>
        <div>
          <Dialog
            className="create-new-vendor"
            open={this.state.open}
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title"
          >
            {/* <DialogTitle
              id="responsive-dialog-title"
              style={{
                backgroundColor: "#53586C",
                padding: "2px 8px",
                fontSize: "26px",
                fontWeight: 300,
              }}
            >
              <p
                style={{
                  fontSize: "25px",
                  marginBottom: 0,
                  float: "left",
                  fontWeight: 300,
                  color: "white",
                }}
              >
                Add New Vendor
              </p>
            </DialogTitle> */}

            <Grid container justify="center">
              <Grid
                item
                xs={12}
              >
                <HeaderStrip
                  style={{ transform: 'translateY(-10px)' }}
                  title={"Add New Vendor"}
                  iconName="creteUser"
                  showStatus = {false}
                />
              </Grid>
            </Grid>

            <ValidatorForm ref="form" onSubmit={this.createVendorName}>
              <DialogContent style={{ width: "400px" }}>
                <TextValidator
                  id="standard-basic"
                  style={{ width: 500 }}
                  name="newVendorName"
                  label={
                    <span>
                      Vendor Name<span style={{ color: "red" }}>*</span>
                    </span>
                  }

                  type="text"
                  onChange={this.onChangeNewVendor}
                  value={this.state.newVendorForm.newVendorName}
                  validators={["required"]}
                  errorMessages={["Vendor name is required"]}
                  autoComplete="off"
                />
                <TextValidator
                  id="standard-basic"
                  style={{ width: 500 }}
                  name="newVendorEmail"
                  label={
                    <span>
                      Vendor Email Address<span style={{ color: "red" }}>*</span>
                    </span>
                  }
                  type="email"
                  onKeyUp={this.vendoremail_validation_fun}
                  onChange={this.onChangeNewVendor}
                  value={this.state.newVendorForm.newVendorEmail}
                  validators={["required", "isEmail"]}
                  errorMessages={[
                    "Vendor Email is required",
                    "Email is not valid",
                  ]}
                  autoComplete="off"
                />
                {this.state.isEmailValid === false ? (
                  <span style={{ color: "red" }}>
                    This mail id already existed
                  </span>
                ) : (
                  ""
                )}

              </DialogContent>
              <DialogActions style={{ justifyContent: "center" }}>
                <CustomButton
                  btnName="Cancel"
                  size="small"
                  fucntionality="cancel "
                  clicked={this.handleClose}
                />
                <CustomButton
                  btnName="Save"
                  fucntionality="save "
                  size="small"
                  type="submit"
                  disabled={this.state.disableVendor}
                />
              </DialogActions>
            </ValidatorForm>
          </Dialog>
        </div>
        <Loader open={this.state.loader} />
      </BackgroundCard>
    );
  }
}
export default Createuser;
