import React, { Component } from "react";
export default class NodataIcon extends Component {
    render() {
      return (
        <svg  width="369.358" height="294.245" viewBox="0 0 369.358 294.245">
        <defs>
          <filter id="cloud" x="47" y="0" width="322.358" height="235.957" filterUnits="userSpaceOnUse">
            <feOffset dy="3" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="12.5" result="blur"/>
            <feFlood flood-color="#63c9ef" flood-opacity="0.502"/>
            <feComposite operator="in" in2="blur"/>
            <feComposite in="SourceGraphic"/>
          </filter>
          <filter id="cloud-2" x="0" y="56" width="322.358" height="235.957" filterUnits="userSpaceOnUse">
            <feOffset dy="3" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="12.5" result="blur-2"/>
            <feFlood flood-opacity="0.161"/>
            <feComposite operator="in" in2="blur-2"/>
            <feComposite in="SourceGraphic"/>
          </filter>
        </defs>
        <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#cloud)">
          <path id="cloud-3" data-name="cloud" d="M246.858,107.493c0,31.891-26.46,57.765-59.084,57.765H44.643C19.98,165.257,0,145.709,0,121.6,0,97.518,19.98,77.97,44.643,77.97a49.586,49.586,0,0,1,5.292.332,62.913,62.913,0,0,1-.7-9.334C49.233,33.528,78.624,4.8,114.9,4.8c29.708,0,54.787,19.278,62.9,45.754a61.523,61.523,0,0,1,9.982-.818c32.608,0,59.076,25.835,59.076,57.749Z" transform="translate(84.75 29.95)" fill="#63c9ef" stroke="#fff" stroke-width="0.5"/>
        </g>
        <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#cloud-2)">
          <path id="cloud-4" data-name="cloud" d="M246.858,107.493c0,31.891-26.46,57.765-59.084,57.765H44.643C19.98,165.257,0,145.709,0,121.6,0,97.518,19.98,77.97,44.643,77.97a49.586,49.586,0,0,1,5.292.332,62.913,62.913,0,0,1-.7-9.334C49.233,33.528,78.624,4.8,114.9,4.8c29.708,0,54.787,19.278,62.9,45.754a61.523,61.523,0,0,1,9.982-.818c32.608,0,59.076,25.835,59.076,57.749Z" transform="translate(37.75 85.95)" fill="#89d8f6" stroke="#fff" stroke-width="0.5"/>
        </g>
        <g id="database" transform="translate(211.512 164.912)">
          <path id="Path_990" data-name="Path 990" d="M64.667,0C28.951,0,0,9.049,0,20.208V36.375C0,47.534,28.951,56.584,64.667,56.584s64.667-9.049,64.667-20.208V20.208C129.334,9.049,100.383,0,64.667,0Z" fill="#3f9afc"/>
          <path id="Path_991" data-name="Path 991" d="M64.667,32.208C28.951,32.208,0,23.159,0,12V36.25C0,47.409,28.951,56.459,64.667,56.459s64.667-9.049,64.667-20.208V12C129.334,23.159,100.383,32.208,64.667,32.208Z" transform="translate(0 36.5)" fill="#3f9afc"/>
          <path id="Path_992" data-name="Path 992" d="M64.667,41.208C28.951,41.208,0,32.159,0,21V45.25C0,56.409,28.951,65.459,64.667,65.459s64.667-9.049,64.667-20.208V21C129.334,32.159,100.383,41.208,64.667,41.208Z" transform="translate(0 63.875)" fill="#3f9afc"/>
        </g>
        <path id="circle-with-cross" d="M26.779,2.56A24.219,24.219,0,1,0,51,26.779,24.221,24.221,0,0,0,26.779,2.56ZM40.586,35.605,35.6,40.59l-8.823-8.826-8.826,8.823L12.968,35.6,21.8,26.779,12.97,17.953l4.984-4.983,8.823,8.823L35.6,12.967l4.984,4.984L31.76,26.777,40.586,35.6Z" transform="translate(294.848 243.247)" fill="#7e0000"/>
      </svg>
      
      );
    }
  }

  NodataIcon.defaultProps = {
    color: "black",
    size: "24px",
  };