import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "react-select";
import Button from "@material-ui/core/Button";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import Spinner from "./Spinner";
import exportToExcel from "../../constant/exportToExcel.js";
import TimeSheetServices from "../../services/TimeSheetServices";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { Typography } from "@material-ui/core";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EventIcon from '@mui/icons-material/Event';
import { toastOptions } from "../../ToastOptions";
import AlertDialog from "./AlertDialog";
import GlobalVariables from "../../GlobalVariables";

import { csvBlob } from "../../constant/exportToExcel.js";

import { saveAs } from 'file-saver';


function createData(
  invoiceNo,
  userId,
  firstName,
  lastName,
  vendorName,
  fromDate,
  toDate,
  joiningDate,
  comments,
  reqularHours,
  otHours,
  totalHours,
  hrRate,
  otRate,
  discount,
  // invoiceFrequency,
  netDay,
  newHrRate,
  newOtRate,
  vendorEmail,
  status,
  vendorId,
  index,
  employeeId,
  totalRegularAmount,
  totalOverTimeAmount,
  DiscountAmount
) {
  return {
    invoiceNo,
    userId,
    firstName,
    lastName,
    vendorName,
    fromDate,
    toDate,
    joiningDate,
    comments,
    reqularHours,
    otHours,
    totalHours,
    hrRate,
    otRate,
    discount,
    // invoiceFrequency,
    netDay,
    newHrRate,
    newOtRate,
    employeeId,
    vendorEmail,
    status,
    vendorId,
    index,
    totalRegularAmount,
    totalOverTimeAmount,
    DiscountAmount,
  };
}
function descendingComparator(a, b, orderBy) {
  if (orderBy === 'firstName' || orderBy === 'lastName') {
    if ((a[orderBy]).toLowerCase() > (b[orderBy]).toLowerCase()) {
      return -1;
    }
    if ((a[orderBy]).toLowerCase() < (b[orderBy]).toLowerCase()) {
      return 1;
    }
    return 0;
  }

  if (orderBy !== 'firstName' || orderBy !== 'lastName') {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
const headCells = [
  { id: "status", numeric: true, disablePadding: true, label: "Status" },
  { id: "firstName", numeric: false, disablePadding: true, label: "First Name" },
  { id: "lastName", numeric: false, disablePadding: true, label: "Last Name" },
  { id: "vendorName", numeric: true, disablePadding: true, label: "Vendor Name" },
  { id: "fromDate", numeric: true, disablePadding: true, label: "From Date" },
  { id: "toDate", numeric: true, disablePadding: true, label: "To Date" },
  { id: "joiningDate", numeric: true, disablePadding: true, label: "Joining Date" },
  { id: "comments", numeric: true, disablePadding: true, label: "Notes" },
  { id: "reqularHours", numeric: true, disablePadding: true, label: "Regular Hours" },
  { id: "otHours", numeric: true, disablePadding: true, label: "OT Hours" },
  { id: "totalHours", numeric: true, disablePadding: true, label: "Total Hours" },
  { id: "hrRate", numeric: true, disablePadding: true, label: "Reg Rate" },
  { id: "otRate", numeric: true, disablePadding: true, label: "OT Rate " },
  { id: "discount", numeric: true, disablePadding: true, label: "Discount %" },
  // { id: "invoiceFrequency", numeric: true, disablePadding: true, label: "Invoice Frequency" },
  { id: "netDay", numeric: true, disablePadding: true, label: "Net days" },
  { id: "newHrRate", numeric: true, disablePadding: true, label: "New Reg Rate" },
  { id: "newOtRate", numeric: true, disablePadding: true, label: "New OT Rate %" },
  { id: "vendorEmail", numeric: true, disablePadding: true, label: "Vendor AP Email Id" },
  { id: "employeeId", numeric: false, disablePadding: true, label: "Emp Id" },
];
const useHeaderStyles = makeStyles((theme) => ({
  tableHeaderLabel: {
    fontSize: 14,
    paddingTop: 5,
  },
  tableHeader: {
    background: "#F7F7F7",
    paddingLeft: 10,
    height: 44,
  },
  tableHeaderCell: {
    color: "#000",
    fontSize: 14,
    fontWeight: 500,
    paddingTop: 2,
    minWidth: 100,
    padding: 0,
  },
  tableHeaderCell1: {
    color: "#000",
    fontSize: 15,
  },
  MuiTableCellRoot: {
    padding: 0,
  },
}));
function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const classes = useHeaderStyles();
  return (
    <TableHead>
      <TableRow className={classes.tableHeader}>
        <TableCell className={classes.tableHeaderCell1}>
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            color="primary"
          />
        </TableCell>
        {headCells.map((headCell) => {
          const x =
            headCell.label === "First Name"
              ? {
                position: "sticky",
                left: 0,
                zIndex: 2,
              }
              : { zIndex: 0 };
          return (
            <TableCell
              key={headCell.id}
              className={classes.tableHeaderCell}
              style={{
                ...x,
                textAlign:
                  headCell.label === "First Name" ||
                    headCell.label === "Last Name" ||
                    headCell.label === "Vendor Name" ||
                    headCell.label === "From Date" ||
                    headCell.label === "To Date" ||
                    headCell.label === "Notes"
                    ? "left"
                    : "center",
              }}
            >
              {/* <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
              </TableSortLabel> */}
              <Typography noWrap style={{ color: "black" }}>
                {headCell.label}
              </Typography>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}
EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: 0,
    paddingRight: theme.spacing(1),
    marginBottom: 0,
  },
  highlight:
    theme.palette.type === "light"
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: "1 1 100%",
  },
  Select: {
    width: 150,
    marginLeft: 20,
  },
  searchName: {
    width: 215,
    height: 30,
    paddingLeft: 30,
    border: "1px solid #DCDCDD",
    borderRadius: 4,
    fontSize: 15,
  },
  searchIcon: {
    width: 16,
    height: 20,
    position: "absolute",
    left: 7,
  },
  calendarIcon: {
    width: 16,
    height: 20,
    position: "absolute",
    left: 243,
  },
  resetBtn: {
    marginLeft: 20,
    background: "#54586D",
    height: 30,
  },
}));
const customStyles = {
  control: (base) => ({
    ...base,
    height: 30,
    minHeight: 30,
  }),
  placeholder: (state) => ({
    position: "absolute",
    color: "grey",
    top: 4,
  }),
  indicatorsContainer: (state) => ({
    position: "absolute",
    bottom: -4,
    right: 0,
  }),
  singleValue: (state) => ({
    position: "absolute",
    top: 4,
  }),
};
const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();

  const [headerData, setHeaderData] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  // const [selectedFrequency, setSelectedFrequency] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState({
    id: "status",
    label: "Open",
    value: "Open",
  });
  const [defaultValue, setDefaultValue] = useState("");
  const [vendorData, setVendorData] = useState([]);
  const [clientData, setClientData] = useState([]);
  //const [frequencyData, setFrequencyData] = useState([]);
  const [statusData, setStatusData] = useState([]);
  const [nameVal, setNameVal] = useState("");
  const isMounted = useRef(false);
  const includeDateCombin = props.includeDateCombin;


  let excelData;
  localStorage.setItem('invoiceStatus', selectedStatus?.value)

  useEffect(() => {
    if (isMounted.current) {
      props.sortFilter(
        nameVal,
        selectedVendor,
        //  selectedFrequency,
        selectedStatus
      );
    } else {
      isMounted.current = true;
    }
  }, [nameVal, selectedVendor, selectedStatus, includeDateCombin]);

  const handleChange = async (selectedOption) => {
    if (selectedOption.id === "vendor") {
      await setSelectedVendor(selectedOption);
    } else if (selectedOption.id === "client") {
      await setSelectedClient(selectedOption);
    }
    // else if (selectedOption.id === "frequency") {
    //   await setSelectedFrequency(selectedOption);
    // }
    else if (selectedOption.id === "status") {
      await setSelectedStatus(selectedOption);
    }
  };
  const getName = (e) => {
    setNameVal(e.target.value.replace(/[^a-zA-Z]/g, ''));
  };
  const resetOptions = () => {
    setSelectedVendor(null);
    // setSelectedClient(null);
    // setSelectedFrequency(null);
    setSelectedStatus(null);
    setNameVal("");
    setDefaultValue("");
    document.getElementById("clearDateRanges").value = "";
    //setDefaultValue("")
    props.reset();
  };
  const getDatePicker = (event, picker) => {
    // picker.element.val(
    //   picker.startDate.format("MM-DD-YYYY") +
    //   " - " +
    //   picker.endDate.format("MM-DD-YYYY")
    // );
    let startDate = picker.startDate.format("YYYY-MM-DD"); //sending date in this format to backend for api call
    let endDate = picker.endDate.format("YYYY-MM-DD");
    setDefaultValue(`${picker.startDate.format("MM-DD-YYYY")} - ${picker.endDate.format("MM-DD-YYYY")}`);
    // console.log(props);
    return props.filterByDate(startDate, endDate);
  };
  const handleCancel = (event, picker) => {
    picker.setStartDate(new Date());
    picker.setEndDate(new Date());
    setDefaultValue("");
    return resetOptions();
  };
  useEffect(() => {
    let logi_data = JSON.parse(localStorage.getItem("login_responce_data"));
    let orgId = logi_data.userDetails.orgId;
    let userId = logi_data.userDetails.userId;
    //let freqDisable = "Y";
    let reqData = [{ "userId": 182, "startDate": "2022-03-26", "endDate": "2022-04-14", "vendorId": 20, "invoiceNo": "F-PRI-ve-rame-0322-4", "invoiceStatus": "I" }, { "userId": 182, "startDate": "2022-04-15", "endDate": "2022-04-26", "vendorId": 4, "invoiceNo": "F-LNT-ve-rame-0422-3", "invoiceStatus": "I" }]

    TimeSheetServices.invoice_reports(orgId, userId).then((res) => {
      let vendorDetails = [{ label: "All", value: "All", id: "vendor" }],
        clientDetails = [{ label: "All", value: "All", id: "client" }],
        // frequencyDetails = [{ label: "All", value: "All", id: "frequency" }],
        frequencyDetails = [],
        //   statusDetails = [{ label: "All", value: "All", id: "status" }];
        statusDetails = [];
      let dataDetails = res.data.invoiceDetails;
      setHeaderData(res.data.invoiceDetails);
      dataDetails.map((data) => {
        let obj = {},
          // clientObj = {},
          reqObj = {},
          freqObj = {};
        if (data.vendorName && data.vendorName !== "") {
          obj["label"] = data.vendorName;
          obj["value"] = data.vendorName;
          obj["id"] = "vendor";
          vendorDetails.push(obj);
        }
        // if (data.clientName && data.clientName !== "") {
        //   clientObj["label"] = data.clientName;
        //   clientObj["value"] = data.clientName;
        //   clientObj["id"] = "client";
        //   clientDetails.push(clientObj);
        // }

        // if (data.invoiceFrequency && data.invoiceFrequency !== "") {
        //   freqObj["label"] = data.invoiceFrequency;
        //   freqObj["value"] = data.invoiceFrequency;
        //   freqObj["id"] = "frequency";
        //   frequencyDetails.push(freqObj);
        // }
        if (data.status && data.status !== "") {
          reqObj["label"] =
            data.status === "Invoiced" ? "Invoiced" : data.status;
          reqObj["value"] = data.status;
          reqObj["id"] = "status";
          statusDetails.push(reqObj);
        }
      });
      setVendorData(
        [...new Set(vendorDetails.map(({ label }) => label))].map((e) =>
          vendorDetails.find(({ label }) => label === e)
        )
      );
      // setClientData(
      //   [...new Set(clientDetails.map(({ label }) => label))].map((e) =>
      //     clientDetails.find(({ label }) => label === e)
      //   )
      // );
      // setFrequencyData(
      //   [...new Set(frequencyDetails.map(({ label }) => label))].map((e) =>
      //     frequencyDetails.find(({ label }) => label === e)
      //   )
      // );
      // setFrequencyData([
      //   { label: "All", value: "All", id: "frequency" },
      //   { label: "Weekly", value: "Weekly", id: "frequency" },
      //   { label: "Bi-Weekly", value: "Biweekly", id: "frequency" },
      //   { label: "Monthly", value: "Monthly", id: "frequency" },
      //   { label: "Semi-Monthly", value: "Semi-monthly", id: "frequency" },
      //  ]);
      // setStatusData(
      //   [...new Set(statusDetails.map(({ label }) => label))].map((e) =>
      //     statusDetails.find(({ label }) => label === e)
      //   )
      // );
      setStatusData([
        { label: "All", value: "All", id: "status" },
        { label: "Open", value: "Open", id: "status" },
        { label: "Invoiced", value: "Invoiced", id: "status" },
      ])
    })
      .catch((err) => { });
    const request = [{ "userId": 182, "startDate": "2022-03-26", "endDate": "2022-04-14", "vendorId": 20, "invoiceNo": "F-PRI-ve-rame-0322-4", "invoiceStatus": "I" }, { "userId": 182, "startDate": "2022-04-15", "endDate": "2022-04-26", "vendorId": 4, "invoiceNo": "F-LNT-ve-rame-0422-3", "invoiceStatus": "I" }]




  }, []);



  return (
    <Toolbar className={clsx(classes.root)}>
      <input
        type="text"
        placeholder="First Name/Last Name"
        value={nameVal}
        onChange={getName}
        className={classes.searchName}
      />
      <img
        src="/assets/icons/search.svg"
        alt="Search"
        className={classes.searchIcon}
      />
      <DateRangePicker
        initialSettings={{
          autoUpdateInput: false,
          locale: {
            cancelLabel: "Clear",
            firstDay: 6
          },
        }}

        onApply={getDatePicker}
        onCancel={handleCancel}
      >
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", position: "relative", width: "max-content" }}>
          <EventIcon style={{
            position: "absolute",
            left: "26px",
            bottom: "6px",
            fontSize: "17px",
            color: "#797876"
          }} />
          <input
            id="clearDateRanges"
            type="text"
            placeholder="Start Date - End Date"
            //  defaultValue={defaultValue}
            style={{ width: "198px", marginLeft: 20 }}
            className={classes.searchName}
            readOnly={true}
            value={defaultValue}
          />
        </div>
      </DateRangePicker>

      <Select
        value={selectedVendor}
        onChange={handleChange}
        options={vendorData}
        placeholder="Vendor Name"
        className={classes.Select}
        styles={customStyles}
      />
      {/* <Select
        value={selectedFrequency}
        onChange={handleChange}
        options={frequencyData}
        placeholder="Frequency"
        className={classes.Select}
        styles={customStyles}
      /> */}
      <Select
        value={selectedStatus}
        onChange={handleChange}
        options={statusData}
        placeholder="Status"
        className={classes.Select}
        styles={customStyles}
      />
      {!nameVal && !selectedVendor ? "" :
        <Button
          variant="contained"
          color="primary"
          onClick={resetOptions}
          className={classes.resetBtn}
        >
          Reset
        </Button>
      }
    </Toolbar>
  );
};
EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
const useStyles = makeStyles((theme) => ({
  customTooltip: {
    backgroundColor: "#FFFFFF",
  },
  rootPaper: {
    padding: 30,
    background: "#FDFDFF",
    boxShadow: "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
  },
  root: {
    width: "100%",
    padding: 0,
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 2000,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  tableHeaderCls: {
    border: "1px solid #e3e3e3",
    margin: 10,
  },
  tableCellCls: {
    border: "none",
    //  textAlign: "center",
    padding: 2,
    height: 52,
    paddingTop: 6,
    textAlign: "left"
  },
  tableCellClss: {
    border: "none",
    textAlign: "right",
    padding: 3,
    height: 52,
    paddingTop: 6,
    // align:"right"
  },
  tablePaginationCls: {
    marginTop: 20,
  },
  statusBtn: {
    width: 80,
    height: 24,
    border: "none",
    borderRadius: 20,
    padding: "4",
    color: "#fff",
    fontSize: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  avatarCls: {
    position: "absolute",
    width: 20,
    height: 20,
    marginLeft: 10,
  },
  labelAvatar: {
    fontSize: 14,
    paddingTop: 5,
  },
  filterSection: {
    marginBottom: 0,
    marginTop: 22,
  },
  exportBtnCls: {
    textAlign: "center",
    marginTop: 20,
  },
  exportBtn: {
    margin: 11,
    fontSize: 16,
    borderRadius: 30,
    textTransform: "capitalize",
    backgroundColor: "#00B863",
    color: theme.palette.common.white,
    width: 112,
    "&:focus": {
      outline: "none",
      WebkitBoxShadow: "-12px 2px 26px -11px rgba(52,191,54,1)",
      MozBoxShadow: "-12px 2px 26px -11px rgba(52,191,54,1)",
      boxShadow: "-12px 2px 26px -11px rgba(52,191,54,1)",
    },
    "&:hover": {
      backgroundColor: "#00B863",
      WebkitBoxShadow: "-12px 2px 26px -11px rgba(52,191,54,1)",
      MozBoxShadow: "-12px 2px 26px -11px rgba(52,191,54,1)",
      boxShadow: "-12px 2px 26px -11px rgba(52,191,54,1)",
    },
  },
  exportBtn1: {
    margin: 11,
    fontSize: 16,
    borderRadius: 30,
    textTransform: "capitalize",
    backgroundColor: "#00B863",
    color: theme.palette.common.white,
    width: 112,
    "&:focus": {
      outline: "none",
      WebkitBoxShadow: "-12px 2px 26px -11px rgba(52,191,54,1)",
      MozBoxShadow: "-12px 2px 26px -11px rgba(52,191,54,1)",
      boxShadow: "-12px 2px 26px -11px rgba(52,191,54,1)",
    },
    "&:hover": {
      backgroundColor: "#00B863",
      WebkitBoxShadow: "-12px 2px 26px -11px rgba(52,191,54,1)",
      MozBoxShadow: "-12px 2px 26px -11px rgba(52,191,54,1)",
      boxShadow: "-12px 2px 26px -11px rgba(52,191,54,1)",
    },
  },

}));

export default function EnhancedInvoiceTable() {
  const FILE_URL = GlobalVariables.FILE_API_BASE_URL_DEV;

  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = React.useState([]);
  const [defaultData, setDefaultData] = React.useState([]);
  const [currentData, setCurrentData] = React.useState([]);
  const [open, setOpen] = useState(false);
  const [selectedUsers, setSelectedUsers] = React.useState([]);
  const [toggleNull, setToggleNull] = React.useState(false);
  const [noInvoiceExcelData, setNoInvoiceExcelData] = React.useState([]);
  const [loginData] = React.useState(JSON.parse(localStorage.getItem("login_responce_data")));
  const invoiceStatus = localStorage.getItem('invoiceStatus');
  const [includeDateCombin, setIncludeDateCombin] = React.useState(false);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const getData = async () => {
    let orgId = loginData.userDetails.orgId;
    let userId = loginData.userDetails.userId;
    //let freqDisable = "Y";
    TimeSheetServices.invoice_reports(orgId, userId).then(async (res) => {
      const getCreateData = res.data.invoiceDetails.map((data, id) => {
        return createData(
          data.invoiceNo,
          data.userId,
          data.firstName,
          data.lastName,
          data.vendorName,
          data.fromDate,
          data.toDate,
          data.joiningDate,
          data.comments,
          data.reqularHours,
          data.otHours,
          data.totalHours,
          data.hrRate,
          data.otRate,
          data.discount,
          // data.invoiceFrequency,
          data.netDay,
          data.newHrRate,
          data.newOtRate,
          data.vendorEmail,
          data.status,
          data.vendorId,
          data.index,
          data.employeeId,
          data.totalRegularAmount,
          data.totalOverTimeAmount,
          data.DiscountAmount,
        );
      });
      setDefaultData(getCreateData);
      setCurrentData(getCreateData);
      const tem = getCreateData.filter((item) => item.status === "Open");
      tem.length || tem.length === 0 ? setToggleNull(true) : setToggleNull(false);
      setRows(tem)
    }).catch((err) => { });
  };
  useEffect(() => {
    getData();
  }, []);
  const handleSelectAllClick = async (event) => {
    if (event.target.checked) {
      const newSelecteds = await rows.map((n) => n.invoiceNo);
      await setSelected(newSelecteds);
      invoiceSelected(rows, newSelecteds);
      return;
    }
    await setSelected([]);
    setSelectedUsers([]);
  };
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
    invoiceSelected(rows, newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const invoiceSelected = async (rows, selected) => {
    let getNew = await rows.filter((data) => {
      return selected.includes(data.invoiceNo) && data.status === "Open";
    });
    await setSelectedUsers(getNew);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const getFilterByName = async (name) => {
    setPage(0);
    if (name.length > 0) {
      setRows([]);
      const getSortByName = await currentData.filter((item) => {
        return (
          item.firstName
            .toUpperCase()
            .startsWith(name.toString().toUpperCase()) ||
          item.lastName.toUpperCase().startsWith(name.toString().toUpperCase())
        );
      });
      getSortByName.length ? setToggleNull(false) : setToggleNull(true);
      await setRows(getSortByName);
    } else if (name.length === 0) {
      await setRows(currentData);
    }
  };

  const sortByDate = async (start, end) => {
    setPage(0);
    setRows([]);
    setToggleNull(false);
    let orgId = loginData.userDetails.orgId;
    let userId = loginData.userDetails.userId;
    let freqDisable = "W";
    TimeSheetServices.getInvoice_by_date(orgId, userId, start, end, freqDisable).then((res) => {
      res.data.invoiceDetails.length
        ? setToggleNull(false)
        : setToggleNull(true);
      // let filteredData = res.data.invoiceDetails.map(el => {
      //   el.fromDate = moment(start).format("MM-DD-YYYY");
      //   el.toDate = moment(end).format("MM-DD-YYYY");
      //   return el
      // }) 
      setCurrentData(res.data.invoiceDetails);
      setIncludeDateCombin((prevState) => !prevState);
    }).catch((err) => { });
  };

  const getFilter = (
    nameVal,
    selectedVendor,
    // selectedFrequency,
    selectedStatus
  ) => {
    setPage(0);
    setRows([]);
    newGetFilter(nameVal, selectedVendor, selectedStatus);
  };
  const newGetFilter = (
    nameVal,
    selectedVendor,
    // selectedFrequency,
    selectedStatus
  ) => {
    const helpFilter = [];
    let sa = selectedVendor ? helpFilter.push(selectedVendor) : null;
    // sa = selectedFrequency ? helpFilter.push(selectedFrequency) : null;
    sa = selectedStatus ? helpFilter.push(selectedStatus) : null;
    const sortedResult = currentData.filter((item) => {
      const nameRes =
        item.firstName
          .toUpperCase()
          .startsWith(nameVal.toString().toUpperCase()) ||
        item.lastName
          .toUpperCase()
          .startsWith(nameVal.toString().toUpperCase());
      const storeFilterRes = helpFilter.map((dropDown) => {
        switch (dropDown.id) {
          case "vendor":
            if (dropDown.label === "All") {
              return true;
            } else {
              return dropDown.value === item.vendorName;
            }
          case "frequency":
            if (dropDown.label === "All") {
              return true;
            }
            else {
              return dropDown.value === item.invoiceFrequency;
            }
          case "status":
            if (dropDown.label === "All") {
              return true;
            } else {
              return dropDown.value === item.status;
            }
          default:
            break;
        }
      });
      const filterResult = storeFilterRes.every((filter) => {
        return filter === true;
      });
      if (nameVal && helpFilter.length) {
        return nameRes && filterResult;
      } else if (!nameVal && helpFilter.length) {
        return filterResult;
      } else {
        return nameRes;
      }
    });
    if (sortedResult.length === 0) {
      setToggleNull(true);
    } else {
      setRows(sortedResult);
    }
  };
  const setReset = () => {
    setPage(0);
    setRows([]);
    setToggleNull(false);
    setRows(defaultData);
    setCurrentData(defaultData);
  };
  const isSelected = (id) => {
    return selected.indexOf(id) !== -1;
  };

  const invoiceClick = () => {
    if (!selectedUsers.length) {
      toast.error("Please select at least one record!", toastOptions);
      return;
    }
    let setArray = [];
    selectedUsers.forEach(({ userId, fromDate, toDate, vendorId, invoiceNo }) => {
      const obj = {};
      obj["userId"] = userId;
      obj["startDate"] = fromDate;
      obj["endDate"] = toDate;
      obj["vendorId"] = vendorId;
      obj["invoiceNo"] = invoiceNo;
      obj["invoiceStatus"] = "I";
      setArray.push(obj);
      return;
    });
    setToggleNull(true)
    TimeSheetServices.save_invoice(setArray).then((res) => {
      setToggleNull(false)
      setRows([]);
      getData();
      setOpen(false)
    });
  };
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  //Export records in excel format
  const exportOnlyOnScreenData = async () => {
    let orgId = loginData.userDetails.orgId;
    const today = new Date();
    let invoiceNum = Number(orgId + "0000");
    // const notInvoiced = await rows.filter((each) => {
    //   return each.status !== "Invoiced";
    // });
    if (!selectedUsers.length) {
      toast.error("Please select at least one record!", toastOptions);
      return;
    }

    const notInvoiced = selectedUsers;

    setNoInvoiceExcelData(notInvoiced);
    const sortedExcelData = [];
    debugger;
    await notInvoiced.map(
      async ({
        invoiceNo,
        userId,
        firstName,
        lastName,
        vendorName,
        fromDate,
        toDate,
        joiningDate,
        comments,
        reqularHours,
        otHours,
        totalHours,
        hrRate,
        otRate,
        discount,
        // invoiceFrequency,
        netDay,
        newHrRate,
        totalRegularAmount,
        totalOverTimeAmount,
        newOtRate,
        vendorEmail,
        status,
        DiscountAmount,
        vendorId,
      }) => {
        let second;
        let third;
        const first = {
          // InvoiceNo: ++invoiceNum,
          InvoiceNo: invoiceNo,
          Customer: vendorName,
          InvoiceDate: moment(today).format("MM-DD-YYYY"),
          DueDate: moment(today).add(netDay, "day").format("MM-DD-YYYY"),
          Terms: `Net ${netDay}`,
          "Place Of Supply": null,
          Memo: "Services",
          "Item(Product/Service)": firstName + " " + lastName,
          ItemDescription: moment(fromDate).format("MM-DD-YYYY") + " - " + moment(toDate).format("MM-DD-YYYY"),
          ItemQuantity: reqularHours,
          ItemRate: hrRate,
          ItemAmount: totalRegularAmount,
          Taxable: "N"
          //ItemTaxCode: "Exempt GST",
          // ServiceDate: moment(fromDate).format("MM-DD-YYYY"),
        };
        if (otHours) {
          second = {
            InvoiceNo: invoiceNo,
            Customer: null,
            InvoiceDate: null,
            DueDate: null,
            Terms: null,
            "Place Of Supply": null,
            Memo: null,
            "Item(Product/Service)": firstName + " " + lastName,
            ItemDescription: "OT Hours",
            ItemQuantity: Number(otHours),
            ItemRate: otRate,
            ItemAmount: totalOverTimeAmount,
            Taxable: "N"
            // ItemTaxCode: "Exempt GST",
            //  ServiceDate: null,
          };
        }
        if (discount) {
          third = {
            InvoiceNo: invoiceNo,
            Customer: null,
            InvoiceDate: null,
            DueDate: null,
            Terms: null,
            "Place Of Supply": null,
            Memo: null,
            "Item(Product/Service)": firstName + " " + lastName,
            ItemDescription: "Discount",
            ItemQuantity: null,
            ItemRate: discount,
            ItemAmount: -DiscountAmount,
            Taxable: "N"
            //   ItemTaxCode: "Exempt GST",
            //   ServiceDate: null,
          };
        }
        sortedExcelData.push(first);
        if (otHours) sortedExcelData.push(second);
        if (discount) sortedExcelData.push(third);
        return;
      }
    );


    const exportedUserData = selectedUsers.map(user => {
      return {
        userId: user.userId,
        startDate: user.fromDate,
        endDate: user.toDate,
        vendorId: user.vendorId,
        invoiceNo: user.invoiceNo,
      }
    })

    function convertToCSV(arr) {
      const array = [Object.keys(arr[0])].concat(arr)

      return array.map(it => {
        return Object.values(it).toString()
      }).join('\n')
    }

    TimeSheetServices.get_screenshot(exportedUserData).then(res => {

      let excelData = convertToCSV(sortedExcelData)
      let blob = new Blob([excelData], { type: 'text/csv;charset=utf-8;' });
      let d = new Date();
      let currentDate = (d.getMonth() + 1) + "-" + d.getDate() + "-" + d.getFullYear();

      const zip = require('jszip')();
      zip.file(`Invoice ${" " + currentDate}.csv`, blob)

      res.data.userAttachments.forEach(attachment => {
        let folder = zip.folder(attachment.invoiceNo)

        attachment.attachments.forEach(image => {
          folder.file(image.file_name, image.file_data, {
            createFolders: false,
            base64: true
          })
        })
        attachment.screen_attachments.forEach(image => {
          folder.file(image.file_name, image.file_data, {
            createFolders: false,
            base64: true
          })
        })
      })

      zip.generateAsync({ type: "blob" })
        .then(function (content) {
          saveAs(content, "exported_data.zip");
        });
    })

    // exportToExcel(sortedExcelData, "invoice");

  };

  const handleAlertOpen = () => {
    if (!selectedUsers.length) {
      toast.error("Please select at least one record!", toastOptions);
      return;
    }
    setOpen(true);
  }

  return (
    <div style={{ maxWidth: "81vw" }}>
      <div>
        <EnhancedTableToolbar
          numSelected={selected.length}
          sortFilter={getFilter}
          sortName={getFilterByName}
          filterByDate={sortByDate}
          reset={setReset}
          includeDateCombin={includeDateCombin}
        />
        {rows.length > 0 ? (
          <div>
            <TableContainer style={{ width: "100%", maxHeight: 440 }}>
              <Table
                stickyHeader
                className={classes.table}
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, id) => {
                      const isItemSelected = isSelected(row.invoiceNo);
                      const labelId = `enhanced-table-checkbox-${id}`;
                      return (
                        <TableRow
                          onClick={(event) => handleClick(event, row.invoiceNo)}
                          key={id}
                          className={classes.tableHeaderCls}
                        >
                          <TableCell
                            padding="checkbox"
                            width="10%"
                            style={{
                              textAlign: "center",
                              paddingTop: 6,
                              paddingLeft: 0,
                            }}
                          >
                            {row.status === "Invoiced" ? (
                              <Checkbox
                                inputProps={{ "aria-labelledby": labelId }}
                                disabled
                                checked
                              />
                            ) : (
                              <Checkbox
                                checked={isItemSelected}
                                value={row.invoiceNo}
                                inputProps={{ "aria-labelledby": labelId }}
                                color="primary"
                              />
                            )}
                          </TableCell>
                          <TableCell
                            className={classes.tableCellCls}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            align="center"
                          >
                            {row.status === "Open" || row.status === "open" ? (
                              <div
                                className={classes.statusBtn}
                                style={{ background: "#808080", padding: 4 }}
                              >
                                OPEN
                              </div>
                            ) : (
                              <div
                                className={classes.statusBtn}
                                style={{ background: "#469924", padding: 4 }}
                              >
                                INVOICED
                              </div>
                            )}
                          </TableCell>
                          <TableCell
                            className={classes.tableCellCls}
                            style={{
                              textAlign: "left",
                              position: "sticky",
                              left: 0,
                              zIndex: 1,
                              backgroundColor: "#ffffff",
                            }}
                            id={labelId}
                            scope="row"
                          >
                            {/* <Avatar className={classes.avatarCls}>
                              <label className={classes.labelAvatar}>
                                {row.firstName.charAt(0)}
                              </label>
                            </Avatar> */}
                            <Typography
                              noWrap
                              style={{ color: "black", textTransform: 'capitalize' }}
                              align="left"
                            >
                              {row.firstName ? row.firstName : "--"}
                            </Typography>
                          </TableCell>
                          <TableCell
                            className={classes.tableCellCls}
                            style={{ textTransform: 'capitalize' }}

                          >
                            {row.lastName ? row.lastName : "--"}
                          </TableCell>
                          <TableCell
                            className={classes.tableCellCls}

                          >
                            {row.vendorName ? row.vendorName : "--"}
                          </TableCell>
                          <TableCell
                            className={classes.tableCellCls}

                          >
                            {row.fromDate
                              ? moment(row.fromDate).format("MM-DD-YYYY")
                              : "--"}
                          </TableCell>
                          <TableCell
                            className={classes.tableCellCls}

                          >
                            {row.toDate
                              ? moment(row.toDate).format("MM-DD-YYYY")
                              : "--"}
                          </TableCell>
                          <TableCell
                            className={classes.tableCellCls}


                          >
                            {row.joiningDate
                              ? moment(row.joiningDate).format("MM-DD-YYYY")
                              : "--"}
                          </TableCell>
                          <TableCell
                            className={classes.tableCellCls}

                          >
                            <Tooltip
                              classes={{
                                tooltip: classes.customTooltip,
                              }}
                              id="tooltip-top"
                              title={
                                <span
                                  style={{ fontSize: "15px", color: "#202034" }}
                                >
                                  {row.comments}
                                </span>
                              }
                              placement="top-end"
                            >
                              <Typography
                                noWrap
                                style={{ color: "black", width: 100 }}>
                                {row.comments ? row.comments : "--"}
                              </Typography>
                            </Tooltip>
                          </TableCell>
                          <TableCell
                            className={classes.tableCellClss}
                            // style={{ textAlign: "left" }}
                            align="right"
                          >
                            {row.reqularHours ? row.reqularHours : "--"}
                          </TableCell>
                          <TableCell
                            align="right"
                            className={classes.tableCellClss}
                          >
                            {row.otHours ? row.otHours : "--"}
                          </TableCell>
                          <TableCell
                            align="right"
                            className={classes.tableCellClss}

                          >
                            {row.otHours || row.reqularHours ? Number(row.otHours + row.reqularHours).toFixed(2) : "--"}
                          </TableCell>
                          <TableCell
                            className={classes.tableCellClss}

                          >
                            {row.hrRate ? row.hrRate : "--"}
                          </TableCell>
                          <TableCell
                            className={classes.tableCellClss}

                          >
                            {row.otRate ? row.otRate : "--"}
                          </TableCell>
                          <TableCell
                            className={classes.tableCellClss}

                          >
                            {row.discount ? row.discount : "--"}
                          </TableCell>
                          {/* <TableCell
                            className={classes.tableCellCls}

                          >
                            {row.invoiceFrequency ? row.invoiceFrequency : "--"}
                          </TableCell> */}
                          <TableCell
                            className={classes.tableCellClss}

                          >
                            {row.netDay ? row.netDay : "--"}
                          </TableCell>
                          <TableCell
                            className={classes.tableCellClss}

                          >
                            {row.newHrRate ? row.newHrRate : "--"}
                          </TableCell>
                          <TableCell
                            className={classes.tableCellClss}

                          >
                            {row.newOtRate ? row.newOtRate : "--"}
                          </TableCell>

                          <TableCell
                            className={classes.tableCellCls}
                            align="center"
                          >
                            {row.vendorEmail ? row.vendorEmail : "--"}
                          </TableCell>
                          <TableCell
                            className={classes.tableCellCls}
                            id={labelId}
                            scope="row"
                          >
                            {row.employeeId ? row.employeeId : "--"}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              className={classes.tablePaginationCls}
            />
          </div>
        ) : (
          <div style={{ textAlign: "center" }}>
            {toggleNull ? (
              <div>
                <TableContainer style={{ width: "100%", maxHeight: 440 }}>
                  <Table
                    stickyHeader
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size={dense ? "small" : "medium"}
                    aria-label="enhanced table"
                  >
                    <EnhancedTableHead
                      classes={classes}
                      numSelected={selected.length}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={handleSelectAllClick}
                      onRequestSort={handleRequestSort}
                      rowCount={rows.length}
                    />
                  </Table>
                  <p >No data to display</p>
                </TableContainer>
              </div>
            ) : (
              <Spinner />
            )}
          </div>
        )}
      </div>
      <div className={classes.exportBtnCls}>

        <Button
          variant="contained"
          onClick={handleAlertOpen}
          className={classes.exportBtn1}
          disabled={invoiceStatus === 'Invoiced'}
        >
          <img
            src="/assets/icons/file.svg"
            alt="Export"
            width="18px"
            style={{ marginRight: 4 }}
          />
          Invoice
        </Button>
        {/* <Button
          style={{ width: '170px' }}
          variant="contained"
          onClick={() => { getAttachmentAndScreenshots() }}
          className={classes.exportBtn1}
        >
          <img
            src="/assets/icons/file.svg"
            alt="Export"
            width="18px"
            style={{ marginRight: 4 }}
          />
          Screenshots
        </Button> */}
        <Button
          variant="contained"
          onClick={exportOnlyOnScreenData}
          className={classes.exportBtn}
          disabled={invoiceStatus === 'Invoiced'}
        >
          <img
            src="/assets/icons/newfile.svg"
            alt="Export"
            width="30px"
            height="30px"
            style={{ marginLeft: 0 }}
          />
          Export
        </Button>
      </div>
      <AlertDialog
        alertTitle="Warning"
        alerContent="Are you sure you want to change Invoice Status?"
        saveButtonText="Yes"
        cancelButtonText="No"
        open={open}
        onSubmit={invoiceClick}
        onCancel={() => { setOpen(false) }} />
    </div>
  );
}