import Login1 from "../../assets/icons/Login1.svg";

const LoginPageStyles = (theme) => ({
  rootDiv: {
    height: "100vh",
    backgroundImage: `url(${Login1})`,
    backgroundPosition: "center",
    overflow: "hidden",
    backgroundRepeat: "no-repeat",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#F1F1F1", 
  },
  root: {
    height: "100%",
  },
  card: {
    width: 1047,
    height: 650,
    transform: "translate(-1474.362 9.551)",
    maxHeight: "80vh",
    maxWidth: "88vw",
    borderRadius: "12px",
    backgroundColor: "#F9FBFC", 
},
  cardContainer: {
    fontSize: 30,
    margin: "12px 2px 3px 6px",
  },

  filedscontainer: {
    marginTop: 5,
    padding: "6px 16px",
  },
  smallCard: {
    borderRadius: "12px",
    width: "100%",
    padding: "9px 0px 9px 0px",
  },
  textInput: {
    padding: "0px 0 0 5px",
  },
  iText: {
    width: "23vw",
    alignItems: "center",
    justifyContent: "center",
    justifyItems: "center",
    alignSelf: "center",
    textAlign: "center",
    margin: "150px auto",
    marginLeft: "-72px",
    paddingBottom: "inherit",
    paddingTop: "inherit",
  },
  Logo: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  mailIcon: {
    fontSize: "30px",
    paddingRight: "2px",
  },
  lpImage: {
  },
  link: {
    fontSize: "12px",
    paddingBottom: "15px",
    paddingTop: "15px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  button: {
    color: "#ffffff",
    borderRadius: "30px",
    textTransform: "none",
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
    width: 112,
    "&:focus": {
      outline: "none",
      WebkitBoxShadow: "-12px 2px 26px -11px rgba(52,191,54,1)",
      MozBoxShadow: "-12px 2px 26px -11px rgba(52,191,54,1)",
      boxShadow: "-12px 2px 26px -11px rgba(52,191,54,1)",
    },
    "&:hover": {
      backgroundColor: theme.palette.success.main,
      WebkitBoxShadow: "-12px 2px 26px -11px rgba(52,191,54,1)",
      MozBoxShadow: "-12px 2px 26px -11px rgba(52,191,54,1)",
      boxShadow: "-12px 2px 26px -11px rgba(52,191,54,1)",
    },
  },
});

export default LoginPageStyles;
