import Login1 from "../../assets/icons/Login1.svg";

const LoginPageStyles = (theme) => ({
  rootDiv: {
    height: "100vh",
    backgroundImage: `url(${Login1})`,
    backgroundPosition: "center",
    overflow: "hidden",
    backgroundRepeat: "no-repeat",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#F1F1F1", //#F9FBFC
  },
  root: {
    height: "100%",
  },
  card: {
    width: 1047,
    height: 650,
    transform: "translate(-1474.362 9.551)",
    maxHeight: "80vh",
    maxWidth: "88vw",
    borderRadius: "12px",
    backgroundColor: "#F9FBFC", //
  },

  smallCard: {
    borderRadius: "12px",
    width: "100%",
    padding: "9px 0px 9px 0px",
  },
  iText: {
    width: "23vw",
    alignItems: "center",
    justifyContent: "center",
    justifyItems: "center",
    alignSelf: "center",
    textAlign: "center",
    margin: "150px auto",
    marginLeft: "-72px",
    paddingBottom: "inherit",
    paddingTop: "inherit",
  },
  mailIcon: {
    fontSize: "30px",
    paddingRight: "2px",
  },
  textInput: {
    padding: "0px 0 0 5px",
  },
  inputfiledContainer: {
    marginTop: 5,
    padding: "6px 16px",
  },
  lpImage: {
    width: "40vw",
    height: "83vh",
    marginLeft: "20px",
  },
  link: {
    fontSize: "12px",
    paddingBottom: "15px",
    paddingTop: "15px",
  },
  button: {
    fontSize: "13px",
    borderRadius: "30px",
    textTransform: "none",
    alignItems: "center",
    marginLeft: 7,
    marginRight: 8,
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
    width: 112,
    "&:focus": {
      outline: "none",
      WebkitBoxShadow: "-12px 2px 26px -11px rgba(52,191,54,1)",
      MozBoxShadow: "-12px 2px 26px -11px rgba(52,191,54,1)",
      boxShadow: "-12px 2px 26px -11px rgba(52,191,54,1)",
    },
    "&:hover": {
      backgroundColor: theme.palette.success.main,
      WebkitBoxShadow: "-12px 2px 26px -11px rgba(52,191,54,1)",
      MozBoxShadow: "-12px 2px 26px -11px rgba(52,191,54,1)",
      boxShadow: "-12px 2px 26px -11px rgba(52,191,54,1)",
    },
  },
  buttonCancel: {
    color: "#ffffff",
    backgroundColor: "#90103B",
    fontSize: "13px",
    borderRadius: "30px",
    textTransform: "none",
    alignItems: "center",
    marginLeft: 7,
    marginRight: 8,
   
    color: theme.palette.common.white,
    width: 112,
    "&:focus": {
      outline: "none",
      WebkitBoxShadow: "-12px 2px 26px -11px rgba(52,191,54,1)",
      MozBoxShadow: "-12px 2px 26px -11px rgba(52,191,54,1)",
      boxShadow: "-12px 2px 26px -11px rgba(52,191,54,1)",
    },
    "&:hover": {
      backgroundColor:"#90103B",
      WebkitBoxShadow: "-12px 2px 26px -11px rgba(52,191,54,1)",
      MozBoxShadow: "-12px 2px 26px -11px rgba(52,191,54,1)",
      boxShadow: "-12px 2px 26px -11px rgba(52,191,54,1)",
    },
  },
  c_p_button: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    marginTop: 24,
  },
});

export default LoginPageStyles;
