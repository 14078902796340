import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import HeaderStrip from "./HeaderStrip";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: "20px 4%",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  cardRoot: {
    boxShadow: "0px 9px 25px -12px rgba(0,0,0,0.75)",
    backgroundColor: "#ffffff",
    height: "auto",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    marginTop: "-26px",
    overflow: "visible"
  },
  headerStrip: {
    position: "relative",
    margin: "13px 0px 0px 0px",
    zIndex: "1",
  },
  content: {
    alignItems: "center",
    margin: "20px 50px 0px 50px",
  },
}));

const BackgroundCard = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container justify="center" alignItems="center">
        <Grid container justify="center">
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            xl={12}
            lg={12}
            className={classes.headerStrip}
          >
            <HeaderStrip
              title={props.title}
              status={props?.status}
              icon={props.icon}
              statusReason={props?.statusReason}
              iconName={props.iconName}
              closeIcon={props.closeIcon}
              closeClick={props.closeClick}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
          <Card className={classes.cardRoot}>
            <CardContent className={classes.content}>
              {props.children}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};
export default BackgroundCard;