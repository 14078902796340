import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TablePagination from "@material-ui/core/TablePagination";
import Box from "@material-ui/core/Box";
import NodataIcon from "../../assets/icons/NodataIcon";
import styles from "../../assets/jss/tasksStyle";
import moment from "moment";
import { TableContainer, Typography } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
const taskStyles = makeStyles(styles);
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      padding: "10px",
    },
    "& :MuiTableCell": {
      padding: "10px",
    },
  },
  tableHead: {
    backgroundColor: "#e9e9e9",
  },
  customTooltip: {
    backgroundColor: "#FFFFFF",
  },
  saveBtn: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
  },
  cancelBtn: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.common.white,
  },
  ActiveStatusButton: {
    width: 77,
    textAlign: "center",
    justifyContent: "center",
    backgroundColor: "Green",
    borderRadius: "20px",
    color: "white",
  },
  deActiveStatusButton: {
    width: 77,
    textAlign: "center",
    justifyContent: "center",
    backgroundColor: "red",
    borderRadius: "20px",
    color: "white",
  },
  openStatusButton: {
    width: 77,
    textAlign: "center",
    justifyContent: "center",
    backgroundColor: "#4179f8",
    borderRadius: "20px",
    color: "white",
  },
  statusButtonText: {
    fontSize: 12,
    marginTop: 0,
    padding: 5,
    marginBottom: 0,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  tableHeadeCell: {
    padding: "14px 5px 14px 13px",
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
const MainTable = (props) => {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const taskStyleclasses = taskStyles();
  const { tableHead, tableData } = props;
  const classes = useStyles();
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  function EnhancedTableHead(props) {
    const {
      order,
      orderBy,
      onRequestSort,
    } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow style={{ backgroundColor: "#fafafa" }}>
          {tableHead.map((headCell) => (
            <TableCell
              key={headCell.id}
              align="center"
              style={{ padding: "14px 5px 14px 13px", fontSize: " 14px" }}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    classes: PropTypes.object,
    numSelected: PropTypes.number,
    onRequestSort: PropTypes.func,
    onSelectAllClick: PropTypes.func,
    order: PropTypes.oneOf(["asc", "desc"]),
    orderBy: PropTypes.string.isRequired,
  };
  return (
    <div >
      <TableContainer style={{ maxHeight: 300 }}>
        {tableData ? (
          <Table className={classes.table} stickyHeader aria-label="customized table">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {stableSort(tableData, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((prop, index, key) => {
                  return (
                    <TableRow
                      key={index}
                      className={classes.tableRow}
                      hover >
                      <TableCell className={taskStyleclasses.tableCell}>
                        {moment(prop.date).format("MM-DD-YYYY")}
                      </TableCell>
                      <TableCell className={taskStyleclasses.tableCell}>
                        {prop.regularHours}
                      </TableCell>
                      <TableCell className={taskStyleclasses.tableCell}>
                        {prop.otHours}
                      </TableCell>
                      <TableCell className={taskStyleclasses.tableCell}>
                        {prop.totalHours}
                      </TableCell>
                      <TableCell className={taskStyleclasses.tableCell}>
                        <Tooltip
                          classes={{
                            tooltip: classes.customTooltip,
                          }}
                          id="tooltip-top"
                          title={
                            <span
                              style={{ fontSize: "15px", color: "#202034" }}
                            >
                              {prop.description}
                            </span>
                          }
                          placement="top-end"
                        >
                          <Typography
                            noWrap
                            style={{ color: "black", width: 100 }}
                            align="left"
                          >
                            {prop.description}
                          </Typography>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        ) : null}
      </TableContainer>
      {tableData.length !== 0 ? (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={tableData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      ) : (
        <Grid container direction="row" justify="center" alignItems="center">
          <Box>
            <NodataIcon />
          </Box>
        </Grid>
      )}
    </div>
  );
};
export default MainTable;
