import React from "react";
import Grid from "@material-ui/core/Grid";
import { ReactComponent as Timesheet } from "./timeSheet.svg";
import { ReactComponent as Payroll } from "./Payroll.svg";
import { ReactComponent as Settings } from "./settings.svg";
import { ReactComponent as ForgotPassword } from "./forgotPassword.svg";
import { ReactComponent as CreateTimeSheet } from "./createTimesheet.svg";
import { ReactComponent as CreateUser } from "./createUser.svg";
import { ReactComponent as EditUser } from "./editUserOne.svg";
import { ReactComponent as ViewUsers } from "./viewUsers.svg";
import { ReactComponent as LoginPageImage } from "./LoginImage.svg";
import { ReactComponent as ReminderEmail } from "./mail.svg";
import { ReactComponent as CloseIcon } from "./closeIcon.svg";
import { CalendarToday } from "@material-ui/icons";
import VisibilityIcon from '@mui/icons-material/Visibility';
import {ReactComponent as Users} from "../icons/users.svg";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import { ReactComponent as ViewTimeSheet } from "../icons/timeSheet.svg";
import {ReactComponent as PayrollIcon} from '../icons/payroll-icon.svg'
import EventIcon from '@material-ui/icons/Event';

import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles({
  icon: { 
    fill: "#fff",
    overflow: "visible",
  },
  svg: {
    height: 35,
    width: 35,
    margin: '11px',
    color : '#fff'
  },
  closeIcon: {
    width: "35px",
    height: "35px",
    marginLeft: "1.5px",
    position: "absolute",
    right: "-9px",
    top: "-11px",
    cursor: "pointer"
  },
  viewUsers:{
    height: 45,
    width: 35,
    margin: '6px',
    color : '#fff'
  }
});

const IconList = (props) => {
  const classes = useStyles();
  const { iconName } = props;
  const ICONS = {
    creteUser: <PersonAddIcon className={classes.svg}/>,
    editUser: <EditUser className={classes.svg} />,
    viewUser: <Users className={classes.viewUsers} />,
    viewTimeSheet: <VisibilityIcon className={classes.viewUsers} style={{marginTop: '1rem'}} />,
    createTimeSheet: <CreateTimeSheet className={classes.svg} />,
    email: <ReminderEmail className={classes.svg} />,
    payroll: <Payroll className={classes.svg} />,
    settings: <Settings className={classes.svg} />,
    forgotPassword: <ForgotPassword className={classes.svg} />,
    loginPageImage: <LoginPageImage className={classes.svg} />,
    closeIcon:<CloseIcon className={classes.closeIcon}/>,
    calendarIcon : <CalendarToday className={classes.svg} />,
    invoiceReport : <ViewTimeSheet  className={classes.svg} />,
    payrollReport : <PayrollIcon className={classes.svg} />,
    holidayIcon : <EventIcon className={classes.svg} />
  };

  return (
    <Grid direction="row" justify="center" alignItems="center" onClick={props.closeClick}>      
          {ICONS[iconName]}       
    </Grid>
  );
};
//   const icon = props => {
//     const { iconType } = props;
//     return <Icon size={'mg'}>{ICONS[iconType] || <DefaultIcon />}</Icon>;
//   };


export default IconList;
