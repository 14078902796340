import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TablePagination from "@material-ui/core/TablePagination";
import moment from "moment";
import Edit from "@material-ui/icons/Edit";
import { useHistory } from "react-router-dom";
import styles from "../../assets/jss/tasksStyle";
import { TableContainer, Typography } from "@material-ui/core";
// import Avatar from '@material-ui/core/Avatar';
const taskStyles = makeStyles(styles);
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      padding: "10px",
    },
    "& :MuiTableCell": {
      padding: "10px",
    },
  },
  customTooltip: {
    backgroundColor: "#FFFFFF",
  },
  tableHead: {
    backgroundColor: "#e9e9e9",
  },
  saveBtn: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
  },
  cancelBtn: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.common.white,
  },
  ActiveStatusButton: {
    width: 77,
    textAlign: "center",
    justifyContent: "center",
    backgroundColor: "#009A23",
    borderRadius: "20px",
    color: "#ffffff",
  },
  deActiveStatusButton: {
    width: 77,
    textAlign: "center",
    justifyContent: "center",
    backgroundColor: "#9B0000",
    borderRadius: "20px",
    color: "#ffffff",
  },
  openStatusButton: {
    width: 77,
    textAlign: "center",
    justifyContent: "center",
    backgroundColor: "#4179f8",
    borderRadius: "20px",
    color: "white",
  },
  submitStatusButton: {
    width: 77,
    textAlign: "center",
    justifyContent: "center",
    backgroundColor: "#53586C",
    borderRadius: "20px",
    color: "white",
  },
  statusButtonText: {
    fontSize: 12,
    marginTop: 0,
    padding: 5,
    marginBottom: 0,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  tableHeadeCell: {
    padding: "14px 5px 14px 13px",
  },
  enhancedTableHeadStyle: {
    fontSize: "12px",
    paddingTop: "5px",
    paddingBottom: "5px",
    [theme.breakpoints.up("lg")]: {
      fontSize: "14px",
      padding: "14px 5px 14px 13px",
    },
  },
  table: {
    fontSize: "11px",
    [theme.breakpoints.up("md")]: { fontSize: "12px" },
    [theme.breakpoints.up("lg")]: { fontSize: "14px" },

  },
  tableContainerMain: {
    maxWidth: "80vw",
  },
  tableContainer: {
    width: "100%", maxHeight: 440
  },
  noData: {
    display: "flex",
    justifyContent: "center",
  },
  iconscss: {
    paddingLeft: 5,
    fontSize: 24,
    color: "grey",
    outline: "none",
    "&:focus": {
      outline: "none",
    },
  },
  iconscsss: {
    paddingLeft: 5,
    fontSize: 24,
    color: "white",
    outline: "none",
    "&:focus": {
      outline: "none",
    },
  },
  tooltipText: {
    fontSize: "11px",
    color: "white",
  },
  tableHeader: {
    background: "#F7F7F7",
    paddingLeft: 10,
    height: 44,
  },
  adminApproveBtn: {
    width: "75px",
    marginLeft: 14
  },
  approveBtn: {
    width: "75px",

  },
  tableRow: {
    cursor: "pointer"
  }
}));

function descendingComparator(a, b, orderBy) {
  if (orderBy === 'firsName' || orderBy === 'lastName') {
    if ((a[orderBy]).toLowerCase() > (b[orderBy]).toLowerCase()) {
      return -1;
    }
    if ((a[orderBy]).toLowerCase() < (b[orderBy]).toLowerCase()) {
      return 1;
    }
    return 0;
  }

  if (orderBy !== 'firsName' || orderBy !== 'lastName') {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }



}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, orderBy, order, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  if (stabilizedThis !== undefined) {
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
  }

  return stabilizedThis?.map((el) => el[0]);
}

const MainTable = (props) => {
  let history = useHistory();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [loginData] = React.useState(JSON.parse(localStorage.getItem("login_responce_data")));
  const taskStyleclasses = taskStyles();
  const { tableHead, tableData } = props;
  const [actionWidth, setActionWidth] = useState(0);
  const [firstNameWidth, setFirstNameWidth] = useState(0);

  const classes = useStyles();

  useEffect(() => {
    setPage(0);
  }, [props.tableData]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const review_timesheet = (index, obj) => {
    obj.isFrom = "ApproverTableRows";
    localStorage.setItem("table_data", JSON.stringify(obj));
    history.push("/Timesheeets/approvertimesheet");
  };
  const admin_review_timesheet = (index, obj) => {
    obj.isFrom = "AdminTableRows";
    localStorage.setItem("table_data", JSON.stringify(obj));
    history.push("/Timesheeets/approvertimesheet");
  };
  const view_timesheet = (index, obj) => {
    obj.isFrom = "ViewSheet";
    localStorage.setItem("table_data", JSON.stringify(obj));
    history.push("/Timesheeets/approvertimesheet");
  }
  const viewClick = (index, obj) => {
    obj.isFrom = "TableRows";
    localStorage.setItem("table_data", JSON.stringify(obj));
    history.push("/Timesheeets/viewtimesheet");
  };
  const edit_Click = (index, obj) => {
    obj.isFrom = "EditData";
    localStorage.setItem("table_data", JSON.stringify(obj));
    history.push("/Timesheeets/viewtimesheet");
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const tableHeaderSet = (firstDim, secondDim) => {
    return firstDim + secondDim

  }
  function EnhancedTableHead(props) {
    const actionRef = React.useRef(null);
    const firstNameRef = React.useRef(null);
    const classes = useStyles(props);
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    useEffect(() => {
      setActionWidth(actionRef.current?.offsetWidth)
    }, [actionRef.current]);

    useEffect(() => {
      setFirstNameWidth(firstNameRef.current?.offsetWidth)

    }, [firstNameRef.current]);


    const attachRefFunc = (label) => {

      if (label === 'Actions') {
        return actionRef;

      } else if (label === 'First Name') {
        return firstNameRef;
      } else {
        return null;
      }

    }

    return (
      <TableHead>
        <TableRow className={classes.tableHeader}>

          {tableHead.map((headCell) => {
            const x =
              headCell.label === "Actions"
                ? {
                  position: "sticky",
                  left: 0,
                  textAlign: 'center',
                  zIndex: 2,
                }
                : headCell.label === "First Name" ? {
                  position: "sticky",
                  left: tableHeaderSet(actionWidth, 0),
                  zIndex: 2,
                } : headCell.label === "Last Name" ? {
                  position: "sticky",
                  left: tableHeaderSet(actionWidth, firstNameWidth),
                  zIndex: 2,
                }
                  : { zIndex: 0 };

            return (

              <TableCell
                key={headCell.id}
                component='div'
                variant='head'
                ref={attachRefFunc(headCell.label)}
                align="left"
                className={classes.enhancedTableHeadStyle}
                style={{ ...x }}
                sortDirection={orderBy === headCell.id ? order : false} >
                {/* <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)} >
                </TableSortLabel> */}
                <Typography noWrap
                  style={{ color: '#000000' }} align="center">
                  {headCell.label}
                </Typography>
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  return (
    <div className={classes.tableContainerMain}>
      {tableData?.length !== 0 ? (
        <TableContainer
          className={classes.tableContainer}
        >
          <Table
            stickyHeader
            className={classes.table}
            aria-label="customized table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {stableSort(tableData, orderBy, order, getComparator(order, orderBy))
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((prop, index, key) => {
                  return (
                    <TableRow
                      key={index}
                      className={classes.tableRow}
                      onDoubleClick={() => ((loginData.userDetails.userType === 2 || loginData.userDetails.userType === 5) ? viewClick(index, prop) : view_timesheet(index, prop))}
                    >
                      {(loginData.userDetails.userType === 2 || loginData.userDetails.userType === 5) ? (
                        <TableCell
                          className={taskStyleclasses.tableActions}
                          style={{
                            textAlign: "left",
                            position: "sticky",
                            left: 0,
                            zIndex: 1,
                            backgroundColor: "#ffffff",
                          }}

                        >
                          {prop.reviewerStatus === "O" ||
                            prop.reviewerStatus === "R" ? (
                            <div>
                              <Tooltip
                                id="tooltip-top"
                                title={
                                  <span className={classes.tooltipText} >
                                    Edit Timesheet
                                  </span>
                                }
                                placement="right" >
                                <Edit
                                  onClick={() => edit_Click(index, prop)}
                                  className={classes.iconscss} />
                              </Tooltip>
                            </div>
                          ) : (<OpenInNewIcon className={classes.iconscsss} />)}
                        </TableCell>
                      ) : loginData.userDetails.userType === 3 ? (
                        <TableCell
                          className={taskStyleclasses.tableActions}
                          style={{
                            textAlign: "left",
                            position: "sticky",
                            left: 0,
                            zIndex: 1,
                            backgroundColor: "#ffffff",
                          }}
                        >
                          {prop.reviewerStatus === "A" ? (
                            <button
                              disabled="true"
                              className={classes.approveBtn} >
                              Approved
                            </button>
                          ) : prop.reviewerStatus === "R" ? (
                            <button
                              disabled="true"
                              className={classes.approveBtn}  >
                              Rejected
                            </button>) : (
                            <button
                              onClick={() => review_timesheet(index, prop)}
                              className={classes.approveBtn} >
                              Approve
                            </button>)}
                        </TableCell>
                      ) : loginData.userDetails.userType === 1 ? (
                        <TableCell
                          className={taskStyleclasses.tableActions}
                          style={{
                            textAlign: "left",
                            position: "sticky",
                            left: 0,
                            zIndex: 1,
                            backgroundColor: "#ffffff",
                          }}
                        >
                          <Tooltip
                            id="tooltip-top"
                            title={
                              <span className={classes.tooltipText} >
                                Edit Timesheet
                              </span>
                            }
                            placement="left" >
                            <Edit
                              onClick={() =>
                                admin_review_timesheet(index, prop)
                              }
                              className={classes.iconscss}
                            />
                          </Tooltip>
                          {prop.reviewerStatus === "A" ? (
                            <button
                              disabled="true"
                              className={classes.adminApproveBtn} >
                              Approved
                            </button>
                          ) : prop.reviewerStatus === "R" ? (
                            <button
                              disabled="true"
                              className={classes.adminApproveBtn}  >
                              Rejected
                            </button>) : (
                            <button
                              onClick={() => review_timesheet(index, prop)}
                              className={classes.adminApproveBtn} >
                              Approve
                            </button>)}
                        </TableCell>
                      ) : null}
                      {loginData.userDetails.userType === 3 ||
                        loginData.userDetails.userType === 1 ? (
                        <TableCell
                          className={classes.tableCell}
                          style={{
                            textAlign: "left",
                            position: "sticky",
                            left: tableHeaderSet(actionWidth, 0),
                            //left:loginData.userDetails.userType === 1?137:84,
                            zIndex: 1,
                            backgroundColor: "#ffffff",
                          }}
                          align="left"
                        >
                          <Typography
                            noWrap
                            style={{ color: "black", textTransform: 'capitalize' }}
                            align="left"
                          >
                            {prop.firsName}
                          </Typography>
                        </TableCell>
                      ) : (
                        ""
                      )}
                      {loginData.userDetails.userType === 3 ||
                        loginData.userDetails.userType === 1 ? (
                        <TableCell className={classes.tableCell} align="left"
                          style={{
                            textAlign: "left",
                            position: "sticky",
                            // left: loginData.userDetails.userType === 1?232:195.25,
                            left: tableHeaderSet(actionWidth, firstNameWidth),
                            zIndex: 1,
                            backgroundColor: "#ffffff",
                          }}
                        >
                          <Typography
                            noWrap
                            style={{ color: "black", textTransform: 'capitalize' }}
                            align="left"
                          >
                            {prop.lastName}
                          </Typography>
                        </TableCell>
                      ) : (
                        ""
                      )}
                      {/* <TableCell className={classes.tableCell} align="left">
                      <div className={classes.roots}>
                         <Avatar alt="Remy Sharp" src="/assets/images/avatar.jpg" className={classes.large} />
                       </div>
                      </TableCell> */}
                      <TableCell className={classes.tableCell} align="left">
                        <Typography
                          noWrap
                          style={{ color: "black" }}
                          align="left"
                        >
                          {moment(prop.startDate).format("MM-DD-YYYY")}
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.tableCell} align="left">
                        <Typography noWrap style={{ color: "black" }}>
                          {moment(prop.endDate).format("MM-DD-YYYY")}
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.tableCell} align="right">
                        <Typography
                          noWrap
                          style={{ color: "black" }}
                          align="right"
                        >
                          {prop.regularHours === null ? "0" : prop.regularHours}
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.tableCell} align="right">
                        <Typography
                          noWrap
                          style={{
                            color: "black",
                          }}
                          align="right"
                        >
                          {prop.otHours === null ? "0" : prop.otHours}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography
                          noWrap
                          style={{ color: "black" }}
                          align="right"
                        >
                          {prop.totalWeekhours === null ? "0" : prop.totalWeekhours}
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.tableCell} align="left">
                        <Tooltip
                          classes={{
                            tooltip: classes.customTooltip,
                          }}
                          id="tooltip-top"
                          title={
                            <span
                              style={{ fontSize: "15px", color: "#202034" }}
                            >
                              {prop.remark}
                            </span>
                          }
                          placement="top-end" >
                          <Typography
                            noWrap
                            style={{ color: "black", width: 100 }}
                            align="left" >
                            {prop.remark}
                          </Typography>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="left">
                        <div
                          className={
                            prop.reviewerStatus === "A"
                              ? classes.ActiveStatusButton
                              : prop.reviewerStatus === "R"
                                ? classes.deActiveStatusButton
                                : prop.reviewerStatus === "O"
                                  ? classes.openStatusButton
                                  : classes.submitStatusButton
                          }
                        >
                          <p className={classes.statusButtonText}>
                            {prop.reviewerStatus === "A"
                              ? "Approved"
                              : prop.reviewerStatus === "R"
                                ? "Rejected"
                                : prop.reviewerStatus === "O"
                                  ? "Open"
                                  : "Submitted"}
                          </p>
                        </div>
                      </TableCell>
                      <TableCell align="left">
                        {prop.reviewerStatus === "O" ? "" : moment(prop.createdDate).format(
                          "MM-DD-YYYY"
                        )}
                      </TableCell>
                      {prop.approvedDate === null ? <TableCell align="left">
                        <div>---</div>
                      </TableCell> : <TableCell align="left">
                        {moment(prop.approvedDate).format(
                          "MM-DD-YYYY"
                        )}
                      </TableCell>}
                      <TableCell className={classes.tableCell} align="left">
                        <Typography
                          noWrap
                          style={{ color: "black" }}
                          align="left" >
                          {prop.vendorName}
                        </Typography>
                      </TableCell>
                      {loginData.userDetails.userType === 3 ||
                        loginData.userDetails.userType === 1 ? (
                        <TableCell className={classes.tableCell} align="left">
                          <Typography
                            noWrap
                            style={{ color: "black" }}
                            align="left">
                            {prop.employeeId ? prop.employeeId : "--"}
                          </Typography>
                        </TableCell>
                      ) : (
                        ""
                      )}

                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : tableData.length === 0 || tableData.length === undefined || tableData.length === null ? <div>
        <TableContainer>
          <Table
            stickyHeader
            className={classes.table}
            aria-label="customized table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
          </Table>
          <p className={classes.noData}>No data to display</p>
        </TableContainer>
      </div> : null}
      {tableData?.length !== 0 ? (
        <TablePagination
          rowsPerPageOptions={[5, 10, 100]}
          component="div"
          count={tableData?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      ) : (
        null
      )}
    </div>
  );
};
export default MainTable;
