import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import ChangePassword from "../components/Timesheet/ChangePassword";
import LoginPage from "../components/Timesheet/LoginPage"
import SidebarNav from "../components/SidebarNav"
import ForgotPassword from "../components/Timesheet/ForgotPassword";

class Routes extends Component {
  render() {
    return (
      <div>
        <Switch>
          <Route exact path="/" component={LoginPage} />
          <Route exact path="/ForgotPassword" component={ForgotPassword} />
          <Route path="/Timesheeets" component={SidebarNav} />
          <Route path="/changepassword" component={ChangePassword} />
        </Switch>
      </div>
    );
  }


}

export default Routes;
