import React, { } from "react";
import Grid from "@material-ui/core/Grid";
import BackgroundCard from "../uiComponents/BackgroundCard";
import "../../assets/css/changePassword.css";
import TimeSheetServices from "../../services/TimeSheetServices";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../uiComponents/Loader";
import DateRangePicker from "react-bootstrap-daterangepicker";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import EventIcon from '@mui/icons-material/Event';
import {toastOptions} from '../../ToastOptions';
import "bootstrap-daterangepicker/daterangepicker.css";
import { makeStyles } from "@material-ui/core/styles";
import CustomButton from "../uiComponents/Button";
const useStyles = makeStyles((theme) => ({
  searchName: {
    width: 215,
    height: 30,
    paddingLeft: 30,
    border: "1px solid #DCDCDD",
    borderRadius: 4,
    fontSize: 15,
  },
  calendarIcon: {
    width: 20,
    height: 31,
    position: "absolute",
  },
  center: {
    margin: 'auto',
    width: "35%",
    padding: 10,
  },
  button: {
    marginTop: 132,
    marginLeft: 47,

  },

}));
function ReminderEmail(props) {
  const classes = useStyles();
  const [loginData] = React.useState(JSON.parse(localStorage.getItem("login_responce_data")));
  const [fromDate, setFromDate] = React.useState("");
  const [toDate, setToDate] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const [defaultValue, setDefaultValue] = React.useState("");
  const handleCancel = (event, picker) => {
    picker.setStartDate(new Date());
    picker.setEndDate(new Date());
    setFromDate("");
    setToDate("");
    setDefaultValue("");
  };
  const getDatePicker = (event, picker) => {
    // picker.element.val(
    //   picker.startDate.format("MM-DD-YYYY") +
    //   " - " +
    //   picker.endDate.format("MM-DD-YYYY")
    // );
    let startDate = picker.startDate.format("YYYY-MM-DD"); //sending date in this format to backend for api call
    let endDate = picker.endDate.format("YYYY-MM-DD");
    setDefaultValue(`${picker.startDate.format("MM-DD-YYYY")} - ${picker.endDate.format("MM-DD-YYYY")}`);
    setFromDate(startDate);
    setToDate(endDate);
  };

  const reminder_emails = () => {
    let orgId = loginData.userDetails.orgId;
    if (fromDate !== "" && toDate !== "") {
      setLoader(true)
      TimeSheetServices.reminder_emails_api(fromDate, toDate, orgId).then((responce) => {
        if (responce.data.responseCode === 200) {
          setLoader(false)
          setFromDate("");
          setToDate("");
          toast.success(responce.data.details,toastOptions);
          document.getElementById("reminderDateRange").value = "";
          setLoader(false);
        } else {
          setFromDate("");
          setToDate("");
          setLoader(false);
          toast.error("Failed",toastOptions)
        }
      }).catch((error) => {
        setFromDate("");
        setToDate("");
        setLoader(false)
        toast.error("Failed" + error,toastOptions)

      });
    } else {
      setLoader(false);
      toast.error("Please select a date range",toastOptions)
    }
  }

  return (
    <div>
      <Grid container xs={12}>
        <BackgroundCard title="Timesheet Reminder" iconName="email">
          <div className={classes.center}>
            <DateRangePicker
              initialSettings={{
                autoUpdateInput: false,
                locale: {
                  cancelLabel: "Clear",
                  firstDay: 6
                },
              }}
              onApply={getDatePicker}
              onCancel={handleCancel}
            >
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", position: "relative", width: "max-content" }}>
                <EventIcon style={{
                  position: "absolute",
                  left: "5px",
                  bottom: "7px",
                  fontSize: "17px",
                  color: "#797876"

                }} />
                {/* <img
              src="/assets/icons/calendar.svg"
              alt="Calendar"
              className={classes.calendarIcon} */}
                <input
                  id="reminderDateRange"
                  type="text"
                  placeholder="Start Date - End Date"
                  value={defaultValue}
                  readOnly={true}
                  className={classes.searchName} /></div>
            </DateRangePicker>
            <CustomButton
              btnName="Submit"
              fucntionality="Submit "
              type="submit"
              clicked={reminder_emails}

            />
          </div>

        </BackgroundCard>
        <Loader open={loader} />
      </Grid>
    </div>
  );
}
export default ReminderEmail;
