import { dangerColor, grayColor } from "../jss/material-dashboard-react";
import tooltipStyle from "../jss/tooltipStyle.js";

const tasksStyle = {
  ...tooltipStyle,

  table: {
    marginBottom: "0",
    overflow: "visible",
    color:"block"
  },
  container:{
    maxHeight: 440
  },
  tableRow: {
    position: "relative",
    borderBottom: "1px solid " + grayColor[5],
  },
  tableActions: {
    display: "flex",
    border: "none",
    padding: "12px 12px !important",
    verticalAlign: "middle",
  },
  tableCell: {
    verticalAlign: "middle",
    border: "none",
    lineHeight: "1.42857143",
    fontSize: "14px",
    padding: "12px 32px !important",
    fontFamily: "Lato, sans-serif",
  },
  tableCellRTL: {},
  tableActionButton: {
    width: "27px",
    height: "27px",
    padding: "0",
    outline: "none",
    "&:focus": {
      outline: "none",
    },
  },
  tableActionButtonIcon: {
    width: "17px",
    height: "17px",
  },
  edit: {
    backgroundColor: "transparent",
    color: "black",
    boxShadow: "none",
  },
  close: {
    backgroundColor: "transparent",
    color: dangerColor[0],
    boxShadow: "none",
  },
};
export default tasksStyle;
