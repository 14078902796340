const ViewTimeSheetDropDown = [
  {
    label: "status",
    options: {
      All: "All",
      Approved: "A",
      Rejected: "R",
      Open: "O",
      Submitted: "S",
    },
  },
];

const NewViewTimeSheetDropDown = [
  { id: "Status", label: "All", value: "All" },
  { id: "Status", label: "Approved", value: "A" },
  { id: "Status", label: "Rejected", value: "R" },
  { id: "Status", label: "Open", value: "O" },
  { id: "Status", label: "Submitted", value: "S" },
];

const ViewTimeSheetDropDown_Approver = [
  {
    label: "Status",
    options: {
      All: "All",
      Approved: "A",
      Rejected: "R",
      Submitted: "S",
    },
  },
];

const NewViewTimeSheetDropDown_Approver = [
  { id: "Status", label: "All", value: "All" },
  { id: "Status", label: "Approved", value: "A" },
  { id: "Status", label: "Rejected", value: "R" },
  { id: "Status", label: "Submitted", value: "S" },
];

const ViewUsersDropDown = [
  {
    label: "User",
    options: {
      All: "All",
      Admin: "X",
      Employee: "A",
      Approver: "R",
      "External Approver": "ER",
    },
  },
];

const NewViewUsersDropDown = [
  { id: "User", label: "All", value: "All" },
  { id: "User", label: "Admin", value: "X" },
  { id: "User", label: "Approver", value: "R" },
  { id: "User", label: "Employee", value: "A" },
  { id: "User", label: "Contractor", value: "C" },
  { id: "User", label: "External Approver", value: "ER" },
];

const userTypeDropDown = [
  {
    label: "UserType",
    options: {
      Admin: 1,
      Employee: 2,
      Approver: 3,
      Contractor: 5,
      "External Approver": 4,
    
    },
  },
];
const CreateUsersDropDown = [
  {
    label: "Invoice Period",

    options: [
      { label: "Weekly", value: "W" },
      { label: "Bi-Weekly", value: "BW" },
      { label: "Semi Monthly", value: "SM" },
      { label: "Monthly", value: "M" },
    ],
  },
];

const CreateUsercategoryDropDown = [
  {
    label: "Category",
    options: [
      { label: "Regular", value: "R" },
      { label: "Special", value: "S" },
    ],
  },
];
const createstatus = [
  {
    label: "Current Status",
    options: [
      { label: "H1", value: "H" },
      { label: "OPT", value: "O" },
      { label: "GC", value: "G" },
      { label: "Citizen", value: "C" },
      { label: "EAD", value: "E" },
    ],
  },
];
const CreateUserUserType = [
  {
    label: "Type",
    options: ["Employee", "Approver"],
  },
];
export {
  ViewTimeSheetDropDown,
  ViewUsersDropDown,
  CreateUsersDropDown,
  CreateUsercategoryDropDown,
  CreateUserUserType,
  createstatus,
  ViewTimeSheetDropDown_Approver,
  userTypeDropDown,
  NewViewUsersDropDown,
  NewViewTimeSheetDropDown,
  NewViewTimeSheetDropDown_Approver,
};
