import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import styles from "../../assets/jss/createTableStyles";
const useStyles = makeStyles(styles);
const CustomButton = (props) => {
  const classes = useStyles();
  return (
    <div>
      <Button
        variant="contained"
        style={props.style}
        className={
          props.fucntionality === ("save" || "Submit")
            ? classes.saveBtn : props.fucntionality === "Submit "
            ? classes.remBtn
            : props.fucntionality === "save "
              ? classes.saveBtnDlg
              : props.fucntionality === "cancel "
                ? classes.cancelBtnDlg
                : props.fucntionality === "pwdReset"
                  ? classes.pwdReset 
                  : classes.cancelBtn
        }
        onClick={props.clicked}
        disabled={props.disabled}
        type={props.type === "submit" ? "submit" : "button"}
      >
        {props.btnName}
      </Button>
    </div>
  );
};
export default CustomButton;