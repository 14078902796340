const viewTimesheetRowHaddings = [
    // { id: "avtar", numeric: true,  disablePadding: false,  label: "Avatar",},  
    { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
    { id: "startDate", numeric: true,  disablePadding: false,  label: "From Date",},
    { id: "endDate", numeric: true, disablePadding: false, label: "To Date" },
    {id: "regularHours",numeric: true, disablePadding: false,  label: "Regular Hours", },
    { id: "otHours", numeric: true, disablePadding: false, label: "OT Hours" },
    { id: "totalWeekhours", numeric: true,  disablePadding: false,  label: "Total Hours",},
    { id: "remark", numeric: true,disablePadding: false, label: "Emp Remarks",},
    {
      id: "reviewerStatus",
      numeric: true,
      disablePadding: false,
      label: "Status",
    },
    
    {
      id: "createdDate",
      numeric: true,
      disablePadding: false,
      label: "Submitted Date",
    },
    {
      id: "approvedDate",
      numeric: true,
      disablePadding: false,
      label: "Approved Date",
    },
  
    { id: "vendorName",numeric: true, disablePadding: true, label: "Vendor Name"},
    
  ];

  const approverViewTimesheetRowHaddings = [
    { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
    {
      id: "firsName",
      numeric: true,
      disablePadding: false,
      label: "First Name",
    },
    { id: "lastName", numeric: true, disablePadding: false, label: "Last Name" },
    {
      id: "startDate",
      numeric: true,
      disablePadding: false,
      label: "From Date",
    },
    { id: "endDate", numeric: true, disablePadding: false, label: "To Date" },
    {
      id: "regularHours",
      numeric: true,
      disablePadding: false,
      label: "Regular Hours",
    },
    { id: "otHours", numeric: true, disablePadding: false, label: "OT Hours" },
    {
      id: "totalWeekhours",
      numeric: true,
      disablePadding: false,
      label: "Total Hours",
    },
    {
      id: "remark",
      numeric: true,
      disablePadding: false,
      label: "Emp Remarks",
    },
    {
      id: "reviewerStatus",
      numeric: true,
      disablePadding: false,
      label: "Status",
    },

    {
      id: "createdDate",
      numeric: true,
      disablePadding: false,
      label: "Submitted Date",
    },
    {
      id: "approvedDate",
      numeric: true,
      disablePadding: false,
      label: "Approved Date",
    },
 
    {
      id: "vendorName",
      numeric: true,
      disablePadding: true,
      label: "Vendor Name",
    },
    {
      id: "userId",
      numeric: true,
      disablePadding: true,
      label: "Emp Id",
    },
   
  ];

  const viewUsersHeading = [
    {
      id: "firstName",
      numeric: true,
      disablePadding: true,
      label: "First Name",
    },
    {
      id: "lastName",
      numeric: true,
      disablePadding: false,
      label: "Last Name",
    },
    {
      id: "vendorName",
      numeric: true,
      disablePadding: false,
      label: "Vendor Name",
    },
    {
      id: "roleDesc",
      numeric: true,
      disablePadding: false,
      label: "User Type",
    },  
	{
      id: "action",
      numeric: true,
      disablePadding: false,
      label: "Actions",
    },  
    
];

const viewPopupHeading = [
  {
    id: "date",
    numeric: true,
    disablePadding: true,
    label: "Date",
  },
  {
    id: "regularHours",
    numeric: true,
    disablePadding: false,
    label: "Reg Hours",
  },
  {
    id: "otHours",
    numeric: true,
    disablePadding: false,
    label: "OT Hours",
  },
  {
    id: "totalHours",
    numeric: true,
    disablePadding: false,
    label: "Total Hours",
  },
  {
    id: "description",
    numeric: true,
    disablePadding: false,
    label: "Description",
  },    
  
];

  export {viewTimesheetRowHaddings,viewUsersHeading,viewPopupHeading,approverViewTimesheetRowHaddings}