import SidebarImage from "../img/SidebarImage.png";
const drawerWidth = 210;
const sideBarStyles = (theme) => ({
  root: {
    display: "flex",
    "& .MuiDrawer-paper": {
      backgroundImage: "url(" + SidebarImage + ")",
    },
    "& .MuiTypography-body1": {
      color: "#ffffff",
    },
    "& .MuiAppBar-colorPrimary": {
      backgroundColor: "#ffffff",
      color: "#000000",
    },

    "& .MuiTab-root": {
      minWidth: "111px",
      padding: "6px 12px 6px 0px",
      textTransform: "capitalize",
    },
    "& .MuiAvatar-root": {
      width: 30,
      height: 30,
      margin: "auto",
    },
  },
  customHoverFocus: {
    "&:focus": {
      outline: "none",
      
    },
    marginLeft: -17
  },

  tabContainer: {
    marginLeft: "auto",
  },
  tab: {
    marginLeft: "5px",
  },

  iconColor: {
    color: "#ffffff",
	height:16,
	width:16,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
    "& focus": {
      outline: "none",
    },
  },
  button: {
    "& focus": {
      outline: "none",
    },
  },

  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    backgroundColor: theme.palette.common.sidebarColor,
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),

    ...theme.mixins.toolbar,
  },
  arrowColor: {
    color: "#fff",
    "& focus": {
      outline: "none",
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(0),
  },
  background: {
    position: "absolute",
    zIndex: "1",
    height: "100%",
    width: "100%",
    display: "block",
    top: "0",
    left: "0",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    "&:after": {
      position: "absolute",
      zIndex: "3",
      width: "100%",
      height: "100%",
      content: '""',
      display: "block",

      opacity: ".8",
    },
  },
  customTooltip: {
    // I used the rgba color for the standard "secondary" color
    backgroundColor: '#53586C',
  },
  
  tooltipTtl:{
    fontSize: "11px", color: "white"
  },
  logotBtn:{
    color: "#000000", marginTop: 15
  }
});

export default sideBarStyles;
