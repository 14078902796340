import React, { useState } from "react";
import { Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import "../../assets/css/LoginPage.css";
import styles from "../../assets/jss/ForgotPasswordStyles.js";
import { ReactComponent as LoginPageImage } from "../../assets/icons/LoginFullImage.svg";
import { useHistory, BrowserRouter } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TimeSheetServices from "../../services/TimeSheetServices";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { VscMail } from "react-icons/vsc";
import { toastOptions } from "../../ToastOptions";
import Loader from "../uiComponents/Loader";
const useStyles = makeStyles(styles);
function ForgotPassword() {
  let history = useHistory();
  const [email, setEmail] = useState("");
  const [loader, setLoader] = React.useState(false);
  const login_submit = () => {
    setLoader(!loader);
    TimeSheetServices.forget_password(email)
      .then((responce) => {
        if (responce.data.responseCode === 200) {
          setLoader(false);
          toast.success(responce.data.details,toastOptions);
          history.push("/");
        } else {
          setLoader(false);
          toast.error(responce.data.details,toastOptions);
        }
      })
      .catch((error) => {
        setLoader(false);
        toast.error(error,toastOptions);
      });
  };
    const goto_login = () => {
    history.push("/");
  };
  const classes = useStyles();
  return (
    <BrowserRouter>
      <ValidatorForm onSubmit={login_submit}>
        <div className={classes.rootDiv}>
          <Container
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Card className={classes.card}>
              <Grid
                container
                justify="center"
                alignItems="center"
                className={classes.root}
              >
                <Grid
                  item
                  justify="center"
                  alignItems="center"
                  xs={0}
                  sm={7}
                  md={7}
                  lg={7}
                  xl={7}
                  className={classes.root}
                >
                  <LoginPageImage
                      style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "contain",
                    }}
                  />
                </Grid>
                <Grid
                  item
                  container
                  direction="column"
                  xs={12}
                  sm={4}
                  md={4}
                  lg={4}
                  xl={4}
                  className={classes.root}
                  justify="center"
                  alignItems="center"
                >
                  <Card className={classes.smallCard}>
                    <Grid container className={classes.inputfiledContainer}>
                      <Grid item xs={0} sm={1} className={classes.mailIcon}>
                        <VscMail />
                      </Grid>
                      <Grid
                        item
                        item
                        xs={12}
                        sm={11}
                        className={classes.textInput}
                      >
                        <TextValidator
                          id="email"
                          name="email"
                          label={
                            <span>
                              Email Address
                              <span style={{ color: "red" }}>*</span>
                            </span>
                          }
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          validators={["required", "isEmail"]}
                          errorMessages={[
                            "Email address is required",
                            "Email address is not valid",
                          ]}
                          autoComplete="off"
                          style={{ width: "100%", paddingLeft: 3 }}
                        />
                      </Grid>
                    </Grid>
                    <Grid item className={classes.c_p_button}>
                      <Button
                        className={classes.buttonCancel}
                        type="button"
                        onClick={goto_login}
                      >
                        Cancel
                      </Button>
                      <Button className={classes.button} type="submit">
                        Send Password
                      </Button>
                    </Grid>
                  </Card>
                </Grid>
              </Grid>
            </Card>
          </Container>
        </div>
      </ValidatorForm>
      <Loader open={loader} />
    </BrowserRouter>
  );
}
export default ForgotPassword;
