import React, { useState, useEffect } from "react";
import TimeSheetServices from "../../services/TimeSheetServices";
import FormControl from "@material-ui/core/FormControl";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import { Input, TextField } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import BackgroundCard from "../uiComponents/BackgroundCard";
import CustomButton from "../uiComponents/Button";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import MaterialUIPickers from "../uiComponents/DatePickerfiled";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { toastOptions } from "../../ToastOptions";
import { DataGrid } from "@mui/x-data-grid";

import { DatePicker } from "@material-ui/pickers";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import EventIcon from '@mui/icons-material/Event';
import "../../assets/css/Holidays.css";
import { toast } from "react-toastify";
import { GridActionsCellItem } from "@mui/x-data-grid";

const Holidays = () => {
  const loginData = JSON.parse(localStorage.getItem("login_responce_data"));

  const holidaysInitialInputs = {
    orgId: loginData.userDetails.orgId,
    description: "",
    userId: loginData.userDetails.userId,
  };

  const { orgId, userId } = holidaysInitialInputs;

  const [holidaysInputs, setHolidaysInputs] = useState(holidaysInitialInputs);
  const [selectedDate, handleDateChange] = useState(null);
  const [holidaysList, setHolidaysList] = useState(null);
  const [isEditSelected, setIsEditSelected] = useState(false);
  const [editHolidayId, setEditHolidayId] = useState(null);
  const [error, setError] = useState({
    date: "Holiday date is required",
    description: "Description is required",
  })
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [isUpdateClicked, setIsUpdateClicked] = useState(false);



  const { description } = holidaysInputs;

  const columns = [
    { field: "date", headerName: "Date", width: 120 },
    { field: "description", headerName: "Description", width: 250 },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 120,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          onClick={() => {
            getSelectedHolidaysData(params);
          }}
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={() => {
            deleteHoliday(params);
          }}
        />,
      ],
    },
  ];

  useEffect(() => {
    getHolidaysList();

  }, []);


  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === 'description') {
      if (value.length <= 25) {
        setHolidaysInputs({ ...holidaysInputs, [name]: value.replace(/[^a-z A-Z]/g, '') });
      }
      else {
        return
      }
    }
    else {
      setHolidaysInputs({ ...holidaysInputs, [name]: value });
    }

  };

  const addNewHoliday = (data) => {
    TimeSheetServices.create_holiday(orgId, userId, data)
      .then((res) => {
        if (res.data.responseCode === 200) {
          // this.setState({ loader: false });
          toast.success(res.data.message, toastOptions);
          getHolidaysList();
        } else if (res.data.responseCode === 302) {
          // this.setState({ loader: false });
          toast.error(res.data.details, toastOptions);
        } else {
          toast.error("Unable to add holiday", toastOptions);
        }
      })
      .catch((error) => {
        // this.setState({ loader: false });
        toast.error(error, toastOptions);
      });
  };

  const getHolidaysList = () => {
    TimeSheetServices.viewHolidays(orgId, userId)
      .then((response) => {
        if (response.data.responseCode === 404) {
          setHolidaysList(null)
        }

        else if (response.status === 200 && response.data.responseCode === undefined) {
          let filteredData = response.data.filter((el) => {
            return el.date && el.description;
          })
          filteredData = filteredData.sort((a, b) => new Date(b.date) - new Date(a.date))
          setHolidaysList(filteredData);
        }
      })
      .catch((err) => {
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitClicked(true)
    const momentObj = moment(selectedDate, "MM-DD-YYYY");
    holidaysInputs.date = momentObj.format("YYYY-MM-DD");


    if (description && holidaysInputs.date) {
      addNewHoliday(JSON.stringify(holidaysInputs));
      setHolidaysInputs(holidaysInitialInputs);
      handleDateChange(null);
      setIsSubmitClicked(false)

    }
  };

  const deleteHoliday = (params) => {

    TimeSheetServices.delete_holiday(params.row.id, orgId, userId)
      .then((res) => {
        if (res.data.responseCode === 200) {
          // this.setState({ loader: false });
          toast.success("Holiday Deleted", toastOptions);
          getHolidaysList();
          setIsEditSelected(false)
          setHolidaysInputs(holidaysInitialInputs);
          handleDateChange(null);
        } else if (res.data.responseCode === 302) {
          // this.setState({ loader: false });
          toast.error(res.data.details, toastOptions);
        } else {
          toast.error("Unable to delete holiday", toastOptions);
        }
      })
      .catch((error) => {
        // this.setState({ loader: false });
        toast.error(error, toastOptions);
      });
  };

  const getSelectedHolidaysData = (params) => {
    const { date, description, id } = params.row;
    setEditHolidayId(id);
    setIsEditSelected(true);
    handleDateChange(moment(date).toDate());
    const currentHolidayInputs = {
      orgId: loginData.userDetails.orgId,
      description: description,
      userId: loginData.userDetails.userId,
    };
    setHolidaysInputs(currentHolidayInputs);
  };

  const handleHolidaysUpdate = (e) => {
    e.preventDefault();
    setIsUpdateClicked(true)
    const momentObj = moment(selectedDate, "MM-DD-YYYY");
    holidaysInputs.date = momentObj.format("YYYY-MM-DD");

    const updatedHolidayData = {
      orgId: orgId,
      date: holidaysInputs.date,
      description: description,
    };

    if (orgId && userId && description && updatedHolidayData) {
      TimeSheetServices.edit_holiday(
        editHolidayId,
        orgId,
        userId,
        updatedHolidayData
      )
        .then((res) => {
          toast.success("Holiday Edit Successfull", toastOptions);
          setIsEditSelected(false);
          getHolidaysList();
          setHolidaysInputs(holidaysInitialInputs);
          handleDateChange(null);
          setIsUpdateClicked(false)

          // if (res.data.responseCode === 200) {
          //   // this.setState({ loader: false });

          // } else if (res.data.responseCode === 302) {
          //   // this.setState({ loader: false });
          //   toast.error(res.data.details),toastOptions;
          // } else {
          //   toast.error("Unable to edit holiday"),toastOptions;
          // }
        })
        .catch((error) => {
          // this.setState({ loader: false });
          toast.error(error, toastOptions);
        });
    }
  };
  const availableDates = [];
  if (holidaysList !== null) {
    holidaysList.map((holiday) => {
      return availableDates.push(holiday.date);
    });
  }


  function disableDays(date) {
    return availableDates.includes(moment(date).format("YYYY-MM-DD"));
  }

  return (
    <div className="holidays">
      <BackgroundCard title="Holidays" iconName="holidayIcon">
        <div className="holidays-content">
          <div className="holidays-form-container">
            {isEditSelected ? (
              <form action="" onSubmit={handleHolidaysUpdate}>
                <FormControl>
                  <MaterialUIPickers
                    disablePast={true}
                    name="date"
                    label={
                      <span>
                        Holiday Date <span style={{ color: "red" }}>*</span>
                      </span>
                    }
                    value={selectedDate}
                    handleDateChange={handleDateChange}
                    shouldDisableDate={disableDays}
                  />
                  {isUpdateClicked && selectedDate === null && <p className="error">{error.date} *</p>}

                </FormControl>
                <FormControl>
                  <TextField
                    id="description"
                    name="description"
                    value={description}
                    label={
                      <span>
                        Description <span style={{ color: "red" }}>*</span>
                      </span>
                    }
                    variant="standard"
                    onChange={handleChange}

                    placeholder="Update Holiday Description"
                  />
                  {isUpdateClicked && description === "" && <p className="error">{error.description} *</p>}

                </FormControl>


                <Button
                  variant="contained"
                  type="submit"
                  style={{ marginTop: "1rem", marginLeft: 0 }}
                >
                  Update Holiday
                </Button>
              </form>
            ) : (
              <form action="" onSubmit={handleSubmit}>
                <FormControl style={{ position: "relative" }}>
                  <MaterialUIPickers
                    name="date"
                    label={
                      <span>
                        Holiday Date <span style={{ color: "red" }}>*</span>
                      </span>
                    }
                    disablePast={true}
                    value={selectedDate}
                    handleDateChange={handleDateChange}
                    shouldDisableDate={disableDays}
                  />
                  {isSubmitClicked && selectedDate === null && <p className="error">{error.date} *</p>}

                </FormControl>
                <FormControl>

                  <TextField
                    id="description"
                    label={
                      <span>
                        Description <span style={{ color: "red" }}>*</span>
                      </span>
                    }
                    name="description"
                    value={description}
                    onChange={handleChange}
                    placeholder="Enter Holiday Description"
                  />
                  {isSubmitClicked && description === "" && <p className="error">{error.description} *</p>}
                </FormControl>
                <CustomButton
                  btnName="Add Holiday"
                  fucntionality="save"
                  type="submit"
                  style={{ marginTop: "1rem", marginLeft: 0 }}
                ></CustomButton>
              </form>
            )}
          </div>

          <div className="holidays-list">
            <h2 className="holidays-list-title">View Holidays</h2>
            <div style={{ height: 400, width: "100%" }}>
              {holidaysList !== null ? (
                <DataGrid
                  rows={holidaysList}
                  columns={[...columns, { field: 'description', sortable: false }, { field: 'date', sortable: false }]}
                  pageSize={5}
                  initialState={{
                    sorting: {
                      sortModel: [{ field: 'date', sort: 'desc' }],
                    },
                  }}
                  disableColumnMenu={true}
                  rowsPerPageOptions={[5]}
                />
              ) : (
                <DataGrid
                  rows={[]}
                  columns={columns}
                  pageSize={5}
                  disableColumnMenu={true}
                  rowsPerPageOptions={[5]}
                />
              )}
            </div>
          </div>
        </div>
      </BackgroundCard>
    </div>
  );
};

export default Holidays;
