import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import { BrowserRouter} from "react-router-dom";
import "./index.css";
import App from "./App";
import theme from './assets/jss/theme';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from 'axios';
import {toastOptions} from './ToastOptions'

axios.interceptors.request.use((request) =>{
  let logindata=JSON.parse(localStorage.getItem("login_responce_data"))
 if(logindata !== null){
  
  if(request.url.toString().includes("user/user/refresh")){

  }else{
    request.headers.authorization = `Bearer ${logindata.accessToken}`;
  }
 }

return request;
});

axios.interceptors.response.use( (response)=> {
 return response;

},  error=> {
  if (401 === error.response.data.responseCode) {
      // handle error: inform user, go to login, etc
      toast.error(error.response.data.detailsMessage,toastOptions)
     localStorage.clear(); // <-- add your var
     window.location = "/";   // <-- add your path
  } else {
      return Promise.reject(error);
  }
});
ReactDOM.render(
    <ThemeProvider theme={theme}>
      <BrowserRouter>    
      <App />
      </BrowserRouter>
    </ThemeProvider>,
  document.getElementById("root")
);

