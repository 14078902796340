import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme, withStyles } from "@material-ui/core/styles";
import { ReactComponent as WarningIcon } from "../../assets/icons/warning.svg";
const SaveButton = withStyles({
  root: {
    backgroundColor: "#00B863",
    color: "white",
    "&:focus": {
      backgroundColor: "rgba(52,191,54,1)",
      outline: "none",
    },
    "&:hover": {
      backgroundColor: "#00B863",
      outline: "none",
    },
  },
  label: {},
})(Button);
const CancelButton = withStyles({
  root: {
    backgroundColor: "#820101",
    color: "white",
    "&:focus": {
      backgroundColor: "rgb(237 3 3)",
      outline: "none",
    },
    "&:hover": {
      backgroundColor: "#820101",
      outline: "none",
    },
  },
  label: {},
})(Button);
export default function AlertDialog(props) {
  const [loginData] = React.useState(
    JSON.parse(localStorage.getItem("login_responce_data"))
  );
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  console.log(props.width);
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={props.open}
        onClose={props.onCancel}
        aria-labelledby="responsive-dialog-title"
        style={{ marginTop: "-180px"}} >
        <DialogTitle
          id="responsive-dialog-title"
          style={{
            backgroundColor: loginData.userDetails.userType === 1 ? "#00B863" : "#F7A000",
            padding: "2px 8px",
            fontSize: "26px",
            fontWeight: 300,
          }}
        >
          {loginData.userDetails.userType !== 1 ? <WarningIcon style={{ float: "left", marginTop: "4" }} /> : ""}
           <p style={{ fontSize: "26px", marginBottom: 0, float: "left", fontWeight: 300, color: "white" }}>
            {props.alertTitle}
          </p>
            </DialogTitle>
              
        <DialogContent style={{width : props.width !==undefined ? props.width : 'auto'}}>
          <DialogContentText
            style={{
              fontSize: "20px", textAlign: props.textAlign !== undefined ? props.textAlign:'center', color: 'black',
              padding: '11px 0px 0px 0px',
            }}
          >
            {props.alerContent}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <CancelButton onClick={props.onCancel}  >
            {props.cancelButtonText}
          </CancelButton>
          <SaveButton onClick={props.onSubmit} >
            {props.saveButtonText}
          </SaveButton>
        </DialogActions>
      </Dialog>
    </div>
 );
}

