const createTableSsyles = (theme) => ({
  table: {
    width: "100%",
    height: "auto",

    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
  //aprover top styles

  divBoxs:{
    margin: "0px",
    flexGrow: 0.7,
     height: "110px"
  },
  divBox:{
    display: "flex",
    flexDirection: "row",
    borderLeft: "1px solid grey",
    borderBottom: "1px solid grey",
    padding: 10,
  },
  spanStyle:{
    width: "30%"
  },
  spanStyleRemark:{
    width: "100%"
  },
  tableHeadCells:{
    padding: "14px 5px 14px 13px" 
  },
 
  tableAttachments:{
    display: "flex",
    flexDirection: "row",
    marginBottom: 10,
    width: 135,
    height: 30,
    fontWeight: "bold",
    marginTop: 20,
    border: "1px solid black",
  },
  attachbackgroundColor:{
    backgroundColor: "green"
  },
  attachedIcon:{
    width: 20, height: 20, color: "white"
  },
  attachedText:{
    marginLeft: 10
  },
  attachmentFileCss:{
    display: "flex",
    overflowX: "clip",
    justifyContent: "center",
    alignItems: "center",
  },


  totalHourText:{
    textAlign: "end" 
  },
  tableHeadDayCell:{
    padding: "14px 5px 14px 13px",
    textAlign: "left",
  },
  lrgrid:{
    height: "42px",
  },
  

  cancelBtn: {
    color: "#fff",
    margin: '1rem',
    fontSize: 16,
    borderRadius: 30,
    textTransform: "capitalize",
    backgroundColor: "#90103B",
    width: 112,
    "&:focus": {
      outline: "none",
      WebkitBoxShadow: "-12px 2px 26px -11px rgba(181,0,0,1)",
      MozBoxShadow: "-12px 2px 26px -11px rgba(181,0,0,1)",
      boxShadow: "-12px 2px 26px -11px rgba(181,0,0,1)",
    },
    "&:hover": {
      backgroundColor: "#90103B",
      WebkitBoxShadow: "-12px 2px 26px -11px rgba(181,0,0,1)",
      MozBoxShadow: "-12px 2px 26px -11px rgba(181,0,0,1)",
      boxShadow: "-12px 2px 26px -11px rgba(181,0,0,1)",
    },
  },

  icon: {
    color: "#000000",
    fontSize: "24px",
    float: "left",
  },
  filter: {
    fontSize: "18px",
  },
  DatePicker: {
    float: "left",
    margin: "7px",
  },
  inputWidth: {
    width: "80px",
    height: "40px",
  },
  descWidth: {
    width: "400px",
    height: "43px",
  },
  attachGd:{
    margin: "7px 0px"
  },
  attachText:{
    marginLeft: 10 
  },
  startClr:{
    color: "red"
  },
  closeIcn:{
    fontSize: 20
  },
  saveBtn: {
    margin: '1rem',
    fontSize: 16,
    borderRadius: 30,
    textTransform: "capitalize",
    backgroundColor: "#00B863",
    color: theme.palette.common.white,
    width: 122,
    "&:focus": {
      outline: "none",
      WebkitBoxShadow: "-12px 2px 26px -11px rgba(52,191,54,1)",
      MozBoxShadow: "-12px 2px 26px -11px rgba(52,191,54,1)",
      boxShadow: "-12px 2px 26px -11px rgba(52,191,54,1)",
    },
    "&:hover": {
      backgroundColor: "#00B863",
      WebkitBoxShadow: "-12px 2px 26px -11px rgba(52,191,54,1)",
      MozBoxShadow: "-12px 2px 26px -11px rgba(52,191,54,1)",
      boxShadow: "-12px 2px 26px -11px rgba(52,191,54,1)",
    },
  },
  remBtn: {
    margin: 55,
    fontSize: 16,
    marginTop:100,
    borderRadius: 30,
    textTransform: "capitalize",
    backgroundColor: "#00B863",
    color: theme.palette.common.white,
    width: 112,
    "&:focus": {
      outline: "none",
      WebkitBoxShadow: "-12px 2px 26px -11px rgba(52,191,54,1)",
      MozBoxShadow: "-12px 2px 26px -11px rgba(52,191,54,1)",
      boxShadow: "-12px 2px 26px -11px rgba(52,191,54,1)",
    },
    "&:hover": {
      backgroundColor: "#00B863",
      WebkitBoxShadow: "-12px 2px 26px -11px rgba(52,191,54,1)",
      MozBoxShadow: "-12px 2px 26px -11px rgba(52,191,54,1)",
      boxShadow: "-12px 2px 26px -11px rgba(52,191,54,1)",
    },
  },

  pwdReset: {
    fontSize: 16,
    color: "#ffffff",
    backgroundColor: "#36A3F7",
    borderRadius: 30,
    textTransform: "none",
    alignItems: "center",
    margin: 11,
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
    width: "auto",
    "&:focus": {
      outline: "none",
      WebkitBoxShadow: "-12px 2px 26px -11px rgba(52,191,54,1)",
      MozBoxShadow: "-12px 2px 26px -11px rgba(52,191,54,1)",
      boxShadow: "-12px 2px 26px -11px rgba(52,191,54,1)",
    },
    "&:hover": {
      backgroundColor: theme.palette.success.main,
      WebkitBoxShadow: "-12px 2px 26px -11px rgba(52,191,54,1)",
      MozBoxShadow: "-12px 2px 26px -11px rgba(52,191,54,1)",
      boxShadow: "-12px 2px 26px -11px rgba(52,191,54,1)",
    },
  },
 
  clOne:{
    textAlign: "left"
  },
  weekDay: {
    margin: 0,
    fontSize: 16,
  },
  timesheetDate: {
    margin: 0,
    fontSize: 12,
  },


  float: {
    float: "right",
    padding: "0px 21px 0px 0px",
    marginRight: 41,
    fontSize: 19,
  },
  submit: {
    float: "right",
    padding: "0px 21px 0px 0px",
    fontSize: 19,
  },
  dates: {
    float: "left",
    padding: "0px",
    fontSize: 19,
  },
  dateKey: {
    fontSize: 16,
    marginRight: 30,
  },
  dateValues: {
    fontSize: 18,
    marginLeft: 8,
  },
  status: {
    fontSize: 18,
    marginLeft: 8,
    color: "#27568a",
  },
  attach: {
    marginTop: "30px",
  },
  errorMsg: {
    color: "#cc0000",
  },
  cardroot: {
    width: "91%",
    margin: "0% 4%",
    boxShadow: "0px 9px 25px -12px rgba(0,0,0,0.75)",
  },
  cardContent: {
    margin: "44px 65px",
  },
  headerStyles: {
    width: "83%",
    margin: "1% 7.5% 0%",
  },
  filesPreview: {
    marginTop: 0,
  },
  textareaStyles: {
    width: 346,
    height: 72,
    marginTop: 29,
    marginLeft: -63,
    overflow: "auto",
  },
  approvertextareaStyles: {
    width: 346,
    maxHeight: 100,
    overflow: "auto",
  },
  imageContainer: {
    margin: "24px auto",
    border: "1px solid #f3f3f3",
    boxShadow: "-webkit-box-shadow: 10px 10px 52px -11px rgba(0,0,0,0.75)",
    MozBoxShadow: "10px 10px 52px -11px rgba(0,0,0,0.75)",
    boxShadow: "5px 5px 23px -15px rgba(0,0,0,0.75)",
  },
  frontBtn:{
    float: "left",
    height: 40,
    width: 39,
    backgroundColor: "white",
    border: "1px solid #dbdbdb",
  },
  backBtn:{
    float: "left",
    height: 40,
    width: 39,
    backgroundColor: "white",
    border: "1px solid #dbdbdb",
  },
  dateparagraph:{
    float: "left",
    fontSize: "16px",
    height: 40,
    padding: "9px 2px",
    borderTop: "1px solid #dddd",
    borderBottom: "1px solid #dddd",
  },
  spanSpc:{
    padding: "0px 3px" 
  },
  vndrdtGd:{
    display: "inline-flex",
    justifyContent: "flex-end",
  },

  datescontainer: {
    border: "1px solid black",
    height: 43,
    width: 277,
  },

  alerHeading: {
    color: "white",
    backgroundColor: "red",
  },
  alerHeadingText: {
    fontSize: 26,
    margin: "8px 17px",
  },
  alerBox: {
    minWidth: "600px",
    width: "600px",
  },
  ten: {
    display: "flex",
    flexDirection: "row",
    borderLeft: "1px solid grey",
    padding: 10,
  },
  nine: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    borderLeft: "1px solid grey",
    borderBottom: "1px solid grey",
    padding: 10,
  },
  five: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    border: "1px solid grey",
    paddingBottom: 21,
  },
  six: {
    display: "flex",
    flexDirection: "row",
    margin: "0px",
    flexGrow: 0.3,
    justifyContent: "center",
    alignItems: "center",
  },
  seven: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  eight: {
    width: "90px",
    borderRadius: "50px",
    padding: 10,
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    flexWrap: "nowrap",
  },
  cancelBtnDlg: {
    color: "#fff",
    margin: 11,
    fontSize: 16,
    borderRadius: 30,
    textTransform: "capitalize",
    backgroundColor: "#90103B",
    width: 79,
    height: 34,
    "&:focus": {
      outline: "none",
      WebkitBoxShadow: "-12px 2px 26px -11px rgba(181,0,0,1)",
      MozBoxShadow: "-12px 2px 26px -11px rgba(181,0,0,1)",
      boxShadow: "-12px 2px 26px -11px rgba(181,0,0,1)",
    },
    "&:hover": {
      backgroundColor: "#90103B",
      WebkitBoxShadow: "-12px 2px 26px -11px rgba(181,0,0,1)",
      MozBoxShadow: "-12px 2px 26px -11px rgba(181,0,0,1)",
      boxShadow: "-12px 2px 26px -11px rgba(181,0,0,1)",
    },
  },
  saveBtnDlg: {
    margin: 11,
    fontSize: 16,
    borderRadius: 30,
    textTransform: "capitalize",
    backgroundColor: "#00B863",
    color: theme.palette.common.white,
    width: 79,
    height: 34,
    "&:focus": {
      outline: "none",
      WebkitBoxShadow: "-12px 2px 26px -11px rgba(52,191,54,1)",
      MozBoxShadow: "-12px 2px 26px -11px rgba(52,191,54,1)",
      boxShadow: "-12px 2px 26px -11px rgba(52,191,54,1)",
    },
    "&:hover": {
      backgroundColor: "#00B863",
      WebkitBoxShadow: "-12px 2px 26px -11px rgba(52,191,54,1)",
      MozBoxShadow: "-12px 2px 26px -11px rgba(52,191,54,1)",
      boxShadow: "-12px 2px 26px -11px rgba(52,191,54,1)",
    },
  },
});

export default createTableSsyles;
